import { LineData } from '@revelio/replots';

import { CompensationMetricValue } from '../../../data-fetch';

export const getOvertimeData = ({
  data,
  companyLookup,
}: {
  data: CompensationMetricValue[];
  companyLookup: { [key: string | number]: string } | undefined;
}): LineData[] => {
  try {
    return data
      .map((entity): LineData | null => {
        if (!entity || !entity.metadata || !entity.value) return null;

        const { value: values, metadata } = entity;
        const { id, longName, years } = metadata;
        if (!id || !longName || !years) return null;

        if (years.length !== values.length) {
          throw new Error('Years and values length mismatch');
        }

        return {
          label:
            companyLookup?.[id] || entity.metadata.longName || id.toString(),
          values: entity.value?.map((v, i) => ({
            date: years[i],
            value: typeof v === 'number' ? v : null,
          })),
        };
      })
      .filter((line) => line !== null);
  } catch (error) {
    console.error('Error:', error);
    return [];
  }
};
