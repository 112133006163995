import { KdeData } from '@revelio/replots';

import { CompensationMetricValue } from '../../../data-fetch';

export const getKdeData = ({
  data,
  companyLookup,
}: {
  data: CompensationMetricValue[];
  companyLookup: { [key: string | number]: string } | undefined;
}): KdeData[] => {
  try {
    return data
      .map((entity): KdeData | null => {
        if (!entity || !entity.metadata) return null;

        const { metadata } = entity;
        const { id, longName } = metadata;
        if (!id || !longName) return null;

        const label = companyLookup?.[id] || longName;

        const values = entity.value;
        const cdfs = metadata.cdf;
        const salaries = metadata.salaries;

        if (!values || !cdfs || !salaries) return null;
        if (
          cdfs.length !== values.length ||
          salaries.length !== values.length
        ) {
          throw new Error('Length mismatch');
        }

        return {
          label,
          values: values
            .map((v, i) => {
              const cdf = cdfs[i] / 100;
              const value = salaries[i];
              const kde = v;

              if (typeof kde !== 'number') return null;

              return { cdf, kde, value };
            })
            .filter((v) => v !== null),
        };
      })
      .filter((v) => v !== null);
  } catch (error) {
    console.error('Error:', error);
    return [];
  }
};
