import { Text, useDisclosure } from '@chakra-ui/react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { SentimentSubFilterNames } from '@revelio/filtering';
import { LineData } from '@revelio/replots';

import { PlotCard } from '../../../../shared/components';
import { Topic } from '../topics-subfilter/topics';
import { TopicsSubFilter } from '../topics-subfilter/topics-subfilter';
import { SentimentBarDatum } from '../types';
import styles from './sentiment-effects-card.module.css';
import { EffectPlot } from './sentiment-effects-plot';

type PlotGridBase = {
  topic: Topic;
};

type PlotGridBar = {
  chartType: 'bar';
  data: SentimentBarDatum[];
} & PlotGridBase;

type PlotGridLine = {
  chartType: 'line';
  data: LineData[];
} & PlotGridBase;

export type PlotGrid = PlotGridBar | PlotGridLine;

type TopicPlotCardProps = {
  plotGrid: PlotGrid;
  selectedTopic: Topic;
  updateTopicAtIndex: (topic: Topic) => void;
  minValue: number;
  maxValue: number;
  colors?: string[];
  index: number;
};

export const TopicPlotCard = ({
  plotGrid,
  selectedTopic,
  updateTopicAtIndex,
  minValue,
  maxValue,
  colors,
  index,
}: TopicPlotCardProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = (topic: Topic) => {
    updateTopicAtIndex(topic);
    const newParams = new URLSearchParams(searchParams);

    // Retrieve the current sub_topics value
    const currentTopics =
      newParams.get(SentimentSubFilterNames.SUB_TOPICS) || '';

    const topicsArray = currentTopics.split(',');

    // Update the topic at the specified index
    topicsArray[index] = topic.id.toString();

    // Set the updated topics back to the search params
    newParams.set(SentimentSubFilterNames.SUB_TOPICS, topicsArray.join(','));

    setSearchParams(newParams);
  };

  return (
    <PlotCard
      className={classNames(styles.plotCard, {
        [styles.plotCardOpen]: isOpen,
      })}
      title={plotGrid.topic.label}
      info={
        <Text fontSize="xs" color="text.secondary">
          The average score given by workers on the topic of{' '}
          {plotGrid.topic.label}
        </Text>
      }
      menu={['expand']}
      topRight={
        <TopicsSubFilter
          placeholderText="Topics"
          selectedTopic={selectedTopic}
          onSubmit={onSubmit}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      }
    >
      <EffectPlot
        colors={colors}
        minDataValue={minValue}
        maxDataValue={maxValue}
        {...plotGrid}
      />
    </PlotCard>
  );
};
