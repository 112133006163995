import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  UseToastOptions,
  VStack,
} from '@chakra-ui/react';
import {
  isRoleRevelioAdmin,
  isValidEmail,
  useGetLoggedInUser,
} from '@revelio/auth';
import {
  Client,
  MetadataKey,
  Role,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
} from '@revelio/data-access';
import { GroupBase, Select } from 'chakra-react-select';
import ReactDatePicker from 'react-datepicker';
import {
  Controller,
  FormProvider,
  RegisterOptions,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { AnyVariables, OperationContext, OperationResult } from 'urql';
import { AdminRoleTaxonomyOverrideSelect } from './admin-user-edit/admin-role-taxonomy-override-select';
import { AdminUserClientGroupSelect } from './admin-user-edit/admin-user-client-group-select';
import './user-form.css';
import {
  generatePassword,
  getMetadataValue,
  OptionValues,
  roleOptions,
  USER_ROLE_OPTION,
} from './utils/helpers';
import { FeatureFlag } from '@revelio/core';
import { useUnleashFlag } from '../../hooks/unleash/useUnleashFlag';

export type UserFormValues = {
  active: boolean; // this is mutually exclusive from the client active
  trialExpirationDate: string;
  name: string;
  email: string;
  password?: string;
  role: {
    label: string;
    value: Role;
  };
  client_group: {
    label: string;
    value: string;
  };
  custom_role_taxonomy?: {
    label: string;
    value: string;
  };
  sourcewhale_key?: string;
};

type Props = {
  useFormMethods: UseFormReturn<UserFormValues>;
  userToEdit?: User | null;
  setPassword: (newPassword: string) => void;
  setTrialExpirationDate?: (newExpiration: Date) => void;
  passwordRules?: RegisterOptions;
  defaultClient?: Client;
  hideChangeClientGroup?: boolean;
};
const UserForm = ({
  useFormMethods,
  userToEdit,
  setPassword,
  setTrialExpirationDate,
  passwordRules = {
    required: 'Password is required.',
    minLength: {
      value: 4,
      message: 'Minimum length should be 4',
    },
  },
  defaultClient,
  hideChangeClientGroup,
}: Props) => {
  const { loggedInUser } = useGetLoggedInUser();
  const isRevelioAdmin = isRoleRevelioAdmin(loggedInUser.role);
  const roleSelectOptions = roleOptions(loggedInUser.role);

  const isAddingNewUser = userToEdit === null;
  const isEditingSelf = loggedInUser.id === userToEdit?.id;
  const trialExpirationDate = useWatch({
    control: useFormMethods.control,
    name: 'trialExpirationDate',
  }); // initially watch returns undefined when set by the defaultValue of userToEdit.client_name
  const savedTrialExpirationValue = getMetadataValue(
    userToEdit?.metadata,
    MetadataKey.ExpirationDate
  );
  const savedTrialExpirationDate = savedTrialExpirationValue
    ? new Date(savedTrialExpirationValue)
    : undefined;

  const savedSourcewhaleKey = getMetadataValue(
    userToEdit?.metadata,
    MetadataKey.SourcewhaleKey
  );

  const sourceWhaleIntegrationFeatureFlag = useUnleashFlag(
    FeatureFlag.TalentSourceWhaleIntegration
  );

  return (
    <VStack spacing="1">
      {isRevelioAdmin && !isAddingNewUser && (
        <FormControl id="active">
          <FormLabel fontSize="sm" fontWeight="semibold">
            Active
          </FormLabel>
          <Flex>
            <FormLabel fontSize="sm">Off</FormLabel>
            <Switch
              id="active"
              isDisabled={!isRevelioAdmin}
              colorScheme="green"
              defaultChecked={userToEdit?.active as boolean}
              {...useFormMethods.register('active')}
            />
            <FormLabel fontSize="sm" ml={3}>
              On
            </FormLabel>
          </Flex>
        </FormControl>
      )}

      {isRevelioAdmin &&
        !isAddingNewUser &&
        userToEdit?.role !== Role.RevelioAdmin && ( // it isn't supported to edit trial date on admin's personal user edit screen
          <FormControl id="trialExpirationDate">
            <FormLabel fontSize="sm" fontWeight="semibold">
              Trial end date
            </FormLabel>
            <ReactDatePicker
              selected={
                trialExpirationDate
                  ? new Date(trialExpirationDate)
                  : savedTrialExpirationDate
              }
              onChange={(newDate) => {
                setTrialExpirationDate &&
                  setTrialExpirationDate(newDate as Date);
              }}
              dateFormat={'MMMM d, yyyy h:mm aa'}
              showTimeSelect={true}
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              showYearDropdown={false}
              dropdownMode="select"
              customInput={<Input size="sm" />}
              onBlur={(e) => {
                e.preventDefault();
              }}
            />
          </FormControl>
        )}

      <FormControl id="name" isInvalid={!!useFormMethods.formState.errors.name}>
        <FormLabel fontSize="sm" fontWeight="semibold">
          Name
        </FormLabel>
        <Input
          defaultValue={userToEdit?.name || ''}
          {...useFormMethods.register('name', {
            required: 'Name is required.',
            minLength: {
              value: 1,
              message: 'Minimum length should be 1',
            },
          })}
        />
        <FormErrorMessage>
          {useFormMethods.formState.errors.name &&
            useFormMethods.formState.errors.name.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        id="email"
        isInvalid={!!useFormMethods.formState.errors.email}
      >
        <FormLabel fontSize="sm" fontWeight="semibold">
          Email
        </FormLabel>
        <Input
          defaultValue={userToEdit?.email?.trim() || ''}
          {...useFormMethods.register('email', {
            required: 'Email is required.',
            validate: {
              emailSyntax: (email: string) =>
                isValidEmail(email) || 'Valid email is required',
            },
            onChange: (e) => {
              e.target.value = e.target.value.trim();
            },
          })}
        />
        <FormErrorMessage>
          {useFormMethods.formState.errors.email &&
            useFormMethods.formState.errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        id="password"
        isInvalid={!!useFormMethods.formState.errors.password}
      >
        <FormLabel fontSize="sm" fontWeight="semibold">
          Password
        </FormLabel>
        <InputGroup size="md">
          <Input {...useFormMethods.register('password', passwordRules)} />
          <InputRightElement width="4.5rem" right={1}>
            <Button
              h="1.75rem"
              size="sm"
              onClick={() =>
                generatePassword((newPassword) => setPassword(newPassword))
              }
              variant="ghost"
              colorScheme="navyBlue"
            >
              Generate
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          {useFormMethods.formState.errors.password &&
            useFormMethods.formState.errors.password.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl id="role" hidden={!isRevelioAdmin}>
        <FormLabel fontSize="sm" fontWeight="semibold">
          Role
        </FormLabel>
        <Controller
          name="role"
          control={useFormMethods.control}
          defaultValue={
            userToEdit
              ? roleSelectOptions.find((r) => r.value === userToEdit.role)
              : USER_ROLE_OPTION
          }
          render={({ field }) => (
            <Select<OptionValues, true, GroupBase<OptionValues>>
              {...field}
              name="role"
              options={roleSelectOptions}
              placeholder="Select role..."
              closeMenuOnSelect={false}
              noOptionsMessage={() => null}
              defaultValue={roleSelectOptions.filter((i) => i.default === true)}
              isClearable={false}
            />
          )}
        />
      </FormControl>

      {isRevelioAdmin && !hideChangeClientGroup && (
        <AdminUserClientGroupSelect
          control={useFormMethods.control}
          defaultClientName={
            userToEdit?.client_name || defaultClient?.client_name
          }
        />
      )}

      {isEditingSelf && (
        <FormProvider {...useFormMethods}>
          <AdminRoleTaxonomyOverrideSelect />
        </FormProvider>
      )}

      {sourceWhaleIntegrationFeatureFlag && (
        <FormControl
          id="sourcewhale_key"
          isInvalid={!!useFormMethods.formState.errors.sourcewhale_key}
        >
          <FormLabel fontSize="sm" fontWeight="semibold">
            SourceWhale API Key
          </FormLabel>
          <Input
            defaultValue={savedSourcewhaleKey || ''}
            {...useFormMethods.register('sourcewhale_key', {
              pattern: {
                value: /^[A-Za-z0-9_-]+$/,
                message: 'Invalid SourceWhale API key format',
              },
            })}
          />
          <FormErrorMessage>
            {useFormMethods.formState.errors.sourcewhale_key &&
              useFormMethods.formState.errors.sourcewhale_key.message}
          </FormErrorMessage>
        </FormControl>
      )}
    </VStack>
  );
};

type SubmitEditUserArgs = {
  updateUser: (
    variables: UpdateUserMutationVariables,
    context?: Partial<OperationContext> | undefined
  ) => Promise<OperationResult<UpdateUserMutation, AnyVariables>>;
  userId: string;
  toast: (options: UseToastOptions) => void;
  onSuccess?: () => void;
  currentUser: User;
  loggedInUser: User;
};
export const submitEditUser =
  ({
    updateUser,
    userId,
    toast,
    onSuccess,
    currentUser,
    loggedInUser,
  }: SubmitEditUserArgs) =>
  (formData: UserFormValues) => {
    const formatData: Omit<UpdateUserMutationVariables, 'id'> = {
      active: formData.active,
      name: formData.name,
      email: formData.email.trim(),
    };

    const metadataUpdates = [];

    if (formData.trialExpirationDate) {
      metadataUpdates.push({
        key: MetadataKey.ExpirationDate,
        value: formData.trialExpirationDate,
      });
    }

    if (
      formData.sourcewhale_key ||
      getMetadataValue(currentUser.metadata, MetadataKey.SourcewhaleKey)
    ) {
      metadataUpdates.push({
        key: MetadataKey.SourcewhaleKey,
        value: formData.sourcewhale_key || '',
      });
    }

    if (metadataUpdates.length > 0) {
      if (currentUser.metadata && currentUser.metadata.length) {
        const updatedKeys = new Set(metadataUpdates.map((item) => item.key));
        const unchangedUserMetadata = currentUser.metadata.filter(
          (item) => item?.key && !updatedKeys.has(item.key)
        );
        formatData.metadata = [...unchangedUserMetadata, ...metadataUpdates];
      } else {
        formatData.metadata = metadataUpdates;
      }

      // Only Revelio admins can update the trial expiration date
      if (!isRoleRevelioAdmin(loggedInUser.role)) {
        formatData.metadata = formatData.metadata.filter(
          (item) => item?.key !== MetadataKey.ExpirationDate
        );
      }
    }

    if (formData.client_group) {
      formatData.client_name = formData.client_group.value;
    }

    if (formData.role) {
      formatData.role = formData.role.value;
    }

    if (formData.password && formData.password.length !== 0) {
      formatData.password = formData.password;
    }

    if (formData.custom_role_taxonomy) {
      console.log('custom_role_taxonomy', formData.custom_role_taxonomy);
    }

    return updateUser({
      id: userId,
      ...formatData,
    }).then((result) => {
      if (result.error) {
        if (result.error.message.includes('seat limit')) {
          toast({
            title: 'User not added',
            description:
              'The seat limit has been reached for this client group.',
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'top-right',
          });
        } else {
          return;
        }
      } else {
        toast({
          title: 'Account updated',
          description: 'The account has now been updated',
          status: 'success',
          duration: 4000,
          position: 'top-right',
        });

        onSuccess && onSuccess();
      }
    });
  };

export default UserForm;
