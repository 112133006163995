import { PrimaryView } from '@revelio/core';
import {
  doesFilterHaveState,
  FilterBase,
  FilterItem,
  FilterList,
  FilterName,
  LocalSelectionCategories,
  POSTING_GET_DATA,
  SelectFilter,
  SelectionCategories,
  useActiveFiltersState,
  useRoleTaxonomySetting,
  useSelectFilterById,
} from '@revelio/filtering';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';
import { LineData, MainPostingData } from '@revelio/d3';
import {
  MetricKey,
  getLineChartData,
  getMainPostingsData,
  sanitiseMainPostingData,
} from '../utils';
import { transformFiltersToVariables } from '../../../utils';
import { useIsQueryReady } from './use-is-query-ready';
import { PostingsDataQuery } from '@revelio/data-access';

type UsePostingsDataFetchProps = {
  view: PrimaryView;
  primaryFilters: FilterName[];
};

/** Keep this for Salary, Time to Fill, & Posting plot. Top Roles/Company plot in a separate hook */
export const usePostingsDataFetch = ({
  view,
  primaryFilters,
}: UsePostingsDataFetchProps) => {
  /** ======== Get Filter State ======== */
  const activeFilters = useActiveFiltersState();
  const providerFilter = useSelectFilterById(LocalSelectionCategories.PROVIDER);
  const metricModeFilter = useSelectFilterById(
    LocalSelectionCategories.METRIC_MODE
  );
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  /** ======== Transform Filters ======== */
  const queryFilters = useMemo(
    () =>
      transformFiltersToVariables({
        view,
        filters: [
          ...activeFilters,
          ...(providerFilter ? [providerFilter] : []),
          ...(metricModeFilter ? [metricModeFilter] : []),
        ],
        isCustomRoleTaxonomyEnabled,
      }),
    [
      activeFilters,
      providerFilter,
      metricModeFilter,
      view,
      isCustomRoleTaxonomyEnabled,
    ]
  );

  /** ======== Fetch ======== */
  const isQueryReady = useIsQueryReady({ activeFilters, primaryFilters, view });
  const [{ data, fetching, error }] = useQuery({
    query: POSTING_GET_DATA,
    variables: queryFilters,
    pause: !isQueryReady,
  });

  const loading = useMemo(() => {
    const hasFetchBeenCalled = fetching || !!data || !!error;
    return fetching || !hasFetchBeenCalled;
  }, [data, error, fetching]);

  const parsedData: PostingsDataQuery = useMemo(() => {
    const parsedPosting =
      data?.posting?.map((p) => sanitiseMainPostingData(p)) || [];

    const hasSufficientData = (parsedPosting?.[0]?.category?.length || 0) > 1;

    return {
      posting: hasSufficientData ? parsedPosting : data?.posting,
    };
  }, [data]);

  /** ======== Salary & Time to Fill ======== */
  /** Transform Postings data for Salary and Time to Fill charts */
  const [salaryData, setSalaryData] = useState<LineData[]>([]);
  const [timeToFillData, setTimeToFillData] = useState<LineData[]>([]);
  useEffect(() => {
    setSalaryData(
      getLineChartData({ entities: parsedData?.posting, metricKey: 'salary' })
    );
    setTimeToFillData(
      getLineChartData({
        entities: parsedData?.posting,
        metricKey: 'time_to_fill',
      })
    );
  }, [parsedData]);

  /** ======== Main Postings ======== */
  /** Current filter selection of Main Posting chart when in multi primary dimension mode */
  const postingMetricFilterValue = useSelectFilterById(
    SelectionCategories.POSTING_METRIC
  );

  const metricModeFilterValue = useSelectFilterById(
    LocalSelectionCategories.METRIC_MODE
  )?.value;

  const isExpectedHiresPerPostingMode =
    doesFilterHaveState(SelectionCategories.METRIC_MODE) &&
    (metricModeFilterValue as FilterBase)?.id ===
      ('expected_hires_per_posting' as FilterName);

  const mainPostingsView =
    ((postingMetricFilterValue as SelectFilter<FilterItem>)?.value
      ?.value as MetricKey) ?? 'active';

  /** Check number of selected primary filters */
  const numberOfSelectedPrimaryFilters = useMemo(
    () =>
      activeFilters
        .filter((f) => primaryFilters.includes(f.id))
        .flatMap((f) => f.value as FilterList).length,
    [activeFilters, primaryFilters]
  );

  /** Transform Postings data for Main Postings chart */
  const [mainPostingData, setMainPostingData] = useState<
    MainPostingData[] | LineData[]
  >([]);
  useEffect(() => {
    if (isExpectedHiresPerPostingMode || numberOfSelectedPrimaryFilters > 1) {
      setMainPostingData(
        getLineChartData({
          entities: parsedData?.posting,
          metricKey: isExpectedHiresPerPostingMode
            ? 'expected_hires'
            : mainPostingsView,
        })
      );
    } else {
      setMainPostingData(
        getMainPostingsData({ entities: parsedData?.posting })
      );
    }
  }, [
    parsedData,
    numberOfSelectedPrimaryFilters,
    mainPostingsView,
    metricModeFilterValue,
    isExpectedHiresPerPostingMode,
  ]);

  return { salaryData, timeToFillData, mainPostingData, loading, error };
};
