import { Props, Select, SingleValue } from 'chakra-react-select';
import { TalentDiscoveryFilterOption } from './types';

export interface FilterSelectDropdownProps {
  filterOptions: TalentDiscoveryFilterOption[];
  selectedFilter: TalentDiscoveryFilterOption;
  onChangeSelectedFilter: (
    selected: SingleValue<TalentDiscoveryFilterOption>
  ) => void;
  formatOptionLabel?: Props<TalentDiscoveryFilterOption>['formatOptionLabel'];
  isDisabled?: boolean;
}

const flattenFilterOptions = (
  options: TalentDiscoveryFilterOption[]
): TalentDiscoveryFilterOption[] => {
  return options.reduce<TalentDiscoveryFilterOption[]>((acc, option) => {
    if (option.children) {
      return [...acc, ...flattenFilterOptions(option.children)];
    }

    return [...acc, option];
  }, []);
};

export const FilterSelectDropdown = ({
  filterOptions,
  selectedFilter,
  onChangeSelectedFilter,
  formatOptionLabel,
  isDisabled,
}: FilterSelectDropdownProps) => {
  const flatFilterOptions = flattenFilterOptions(filterOptions);

  return (
    <Select
      isDisabled={isDisabled}
      options={flatFilterOptions}
      value={selectedFilter}
      onChange={onChangeSelectedFilter}
      size="sm"
      formatOptionLabel={formatOptionLabel}
      selectedOptionColorScheme="green"
      chakraStyles={{
        control: (provider) => ({
          ...provider,
          height: '30px',
          fontWeight: 400,
        }),
        option: (provider, state) => ({
          ...provider,
          fontWeight: 400,
          ...(state.isSelected && {
            '> p': { color: 'white' },
          }),
        }),
      }}
    />
  );
};
