import { Link, Text } from '@chakra-ui/layout';

import { FormatType, LineChart, LineData } from '@revelio/replots';

import { PlotCard, convertLineToSheet } from '../../../shared/components';

interface OvertimeLineChartCardProps {
  data: LineData[];
  minOvertimeValue: number | undefined;
  maxOvertimeValue: number | undefined;
  compViewLabel: string;
  title: string;
  loading?: boolean;
  colorMap: Record<string, string>;
}

export const OvertimeLineChartCard = ({
  data,
  minOvertimeValue,
  maxOvertimeValue,
  compViewLabel,
  title,
  loading,
  colorMap,
}: OvertimeLineChartCardProps) => {
  const dataWithColors = data.map((line) => ({
    ...line,
    color: colorMap[line.label],
  }));

  const yAxis =
    typeof minOvertimeValue !== 'number' || typeof maxOvertimeValue !== 'number'
      ? undefined
      : {
          min: minOvertimeValue,
          max: maxOvertimeValue,
        };

  return (
    <PlotCard
      title={title}
      info={
        <Text variant="tooltip">
          The average predicted {compViewLabel} over time. More information on
          the compensation model can be found{' '}
          <Link
            href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
            isExternal
            variant="tooltip"
          >
            here
          </Link>
          .
        </Text>
      }
      infoPlacement="top-end"
      menu={['expand', 'download-png', 'download-data']}
      exportData={convertLineToSheet({ data })}
    >
      <LineChart
        data={dataWithColors}
        showGridLines={true}
        format={FormatType.CURRENCY}
        loading={loading}
        yAxis={yAxis}
      />
    </PlotCard>
  );
};
