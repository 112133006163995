import { ReactNode } from 'react';

import { BoxGroup, BoxPlot } from '@revelio/replots';

import { PlotCard } from '../../../shared/components';
import { convertBoxPlotToPivotSheet } from '../../../shared/components/plot-card/convert-to-sheet';
import { downloadData } from '../../../shared/components/plot-card/download-plot-data';

interface TopBoxPlotCardProps {
  title: string;
  data: BoxGroup[];
  colorMap: Record<string, string>;
  loading?: boolean;
  info?: ReactNode;
  primaryDimension: string;
  secondaryDimension: string;
}

export const TopBoxPlotCard = ({
  title,
  data,
  colorMap,
  loading,
  info,
  primaryDimension,
  secondaryDimension,
}: TopBoxPlotCardProps) => {
  return (
    <PlotCard
      title={title}
      info={info}
      menu={['expand', 'download-png', 'download-data']}
      onExport={() => {
        const exportData = convertBoxPlotToPivotSheet({
          data,
          columnHeader: primaryDimension,
          rowHeader: secondaryDimension,
        });

        downloadData(exportData, title, {
          skipHeader: true,
          mergeHeaderCells: true,
        });
      }}
    >
      <BoxPlot data={data} colorMap={colorMap} loading={loading} />
    </PlotCard>
  );
};
