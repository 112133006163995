import { truncateText } from '../../utilities';
import { wrapText } from '../../utilities/wrap-text';
import styles from './axis-label.module.css';

type AxisLabelProps = {
  y: number;
  width: number;
  label: string;
  fontSize?: number;
  wrap?: boolean;
  height?: number;
  testId?: string;
};

export const AxisLabel = ({
  y,
  width,
  label,
  fontSize = 11,
  wrap = false,
  height,
  testId,
}: AxisLabelProps) => {
  const truncatedLabel = (() => {
    if (wrap) {
      return wrapText({
        text: label,
        maxWidth: width,
        fontSize,
        maxHeight: height,
      });
    }
    return truncateText({ text: label, maxWidth: width, fontSize });
  })();

  return (
    <text
      y={y}
      x={width}
      textAnchor="end"
      dy="0.25em"
      fontSize={`${fontSize}px`}
      className={styles.yAxisLabel}
      data-testid={testId}
    >
      {Array.isArray(truncatedLabel) ? (
        <MultiLineLabel x={width} label={truncatedLabel} labelTitle={label} />
      ) : (
        <>
          <title>{label}</title>
          {truncatedLabel}
        </>
      )}
    </text>
  );
};

const lineHeightEm = 1.3;
const MultiLineLabel = ({
  label,
  labelTitle,
  x,
}: {
  label: string[];
  labelTitle: string;
  x: number;
}) => {
  const firstDY = (-(label.length - 1) * lineHeightEm) / 2;

  return (
    <>
      <title>{labelTitle}</title>
      {label.map((line, i) => (
        <tspan
          key={i}
          x={x}
          dy={i === 0 ? `${firstDY}em` : '1.3em'}
          textAnchor="end"
          dominantBaseline="central"
        >
          {line}
        </tspan>
      ))}
    </>
  );
};
