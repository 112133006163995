import { Flex } from '@chakra-ui/layout';
import { useMemo } from 'react';
import {
  TalentDiscoveryFilterOption,
  isCompanySearchFilter,
  isSchoolSearchFilter,
  isFreeTextFilter,
  isRangeFilter,
  isTreeFilter,
  FreeTextFilters,
  AI_SEARCH_FILTER_OPTION_VALUE,
} from '../types';
import { TreeFilter } from './tree-filter';
import { CompanySearchFilter } from './company-search-filter';
import { SchoolSearchFilter } from './school-search-filter';
import { RangeFilter } from './range-filter';
import { GraduationYearFilter } from './graduation-year-filter';
import {
  SelectionCategories,
  SavedSetsSelectOptionsProvider,
  useOnClickOutsidePopover,
} from '@revelio/filtering';
import { SavedSetsNestedSelectionList } from '@revelio/filtering';
import { SingleValue } from 'react-select';
import { Option } from '@revelio/filtering';
import { useNodeModalState } from '@revelio/filtering';
import { TDKeywordsFilter } from './td-keywords-filter';
import { TDNameFilter } from './td-name-filter/td-name-filter';
import { AiFilterSearch } from './ai-filter-search/ai-filter-search';

export const TalentDiscoveryFilter = ({
  selectedFilter,
  closeMenu,
  filterId,
  onSubmitSavedFilterSet,
}: {
  selectedFilter: TalentDiscoveryFilterOption;
  closeMenu: () => void;
  filterId?: string;
  onSubmitSavedFilterSet?: (value: SingleValue<Option> | undefined) => void;
}) => {
  const isATreeFilter = isTreeFilter(selectedFilter?.value);

  const isCompanySearch = isCompanySearchFilter(selectedFilter?.value);
  const isSchoolSearch = isSchoolSearchFilter(selectedFilter?.value);
  const isFreeTextSearchFilter = isFreeTextFilter(selectedFilter?.value);
  const isARangeFilter = isRangeFilter(selectedFilter?.value);

  const renderFilterComponent = useMemo(() => {
    if (isCompanySearch) {
      return (
        <CompanySearchFilter
          treeHeight={220}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isATreeFilter) {
      return (
        <TreeFilter
          selectionLists={selectedFilter.filterGranularities || []}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
          filterId={filterId}
        />
      );
    }

    if (isSchoolSearch) {
      return (
        <SchoolSearchFilter
          treeHeight={220}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isFreeTextSearchFilter) {
      const filterName = selectedFilter.value as FreeTextFilters;

      if (filterName === SelectionCategories.NAME) {
        return (
          <TDNameFilter
            closeMenu={closeMenu}
            selectedFilter={selectedFilter}
            filterId={filterId}
          />
        );
      }

      if (
        [SelectionCategories.KEYWORD, SelectionCategories.TITLES].includes(
          filterName
        )
      ) {
        return (
          <TDKeywordsFilter
            closeMenu={closeMenu}
            selectedFilter={selectedFilter}
            filterId={filterId}
          />
        );
      }

      console.error('Not a supported free text filter');
      return null;
    }

    if (isARangeFilter) {
      if (selectedFilter.value === SelectionCategories.GRADUATION_YEAR) {
        return <GraduationYearFilter closeMenu={closeMenu} />;
      }
      return (
        <RangeFilter closeMenu={closeMenu} selectedFilter={selectedFilter} />
      );
    }

    if (selectedFilter.value === SelectionCategories.SAVED_FILTER_SET) {
      return (
        <SavedSetsSelectOptionsProvider>
          <SavedSetsNestedSelectionList
            close={closeMenu}
            onSubmitSavedFilterSet={onSubmitSavedFilterSet}
          />
        </SavedSetsSelectOptionsProvider>
      );
    }

    if (selectedFilter.value === AI_SEARCH_FILTER_OPTION_VALUE) {
      return <AiFilterSearch closeMenu={closeMenu} />;
    }

    return null;
  }, [
    isARangeFilter,
    isATreeFilter,
    isCompanySearch,
    isFreeTextSearchFilter,
    isSchoolSearch,
    closeMenu,
    onSubmitSavedFilterSet,
    filterId,
    selectedFilter,
  ]);

  const { nodeModalOpen } = useNodeModalState();
  const componentRef = useOnClickOutsidePopover(
    !nodeModalOpen ? closeMenu : () => null
  );

  return (
    <Flex direction="column" gap="4" ref={componentRef}>
      {renderFilterComponent}
    </Flex>
  );
};
