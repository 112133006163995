import { Text } from '@chakra-ui/layout';
import classNames from 'classnames';
import { isNumber } from 'lodash';

import {
  BarChart,
  FormatType,
  LineChart,
  LineData,
  getFormatter,
} from '@revelio/replots';

import { SentimentBarDatum } from '../types';
import styles from './sentiment-effects-plot.module.css';
import { useState } from 'react';

type EffectChartBarProps = {
  chartType: 'bar';
  data: SentimentBarDatum[];
};

type EffectChartLineProps = {
  chartType: 'line';
  data: LineData[];
};

type EffectChartProps = EffectChartBarProps | EffectChartLineProps;

const formatNumber = getFormatter(FormatType.PERCENTAGE);

export const EffectPlot = ({
  minDataValue,
  maxDataValue,
  colors,
  ...props
}: EffectChartProps & {
  minDataValue?: number;
  maxDataValue?: number;
  colors?: string[];
}) => {
  const [hoveredBar, setHoveredBar] = useState<SentimentBarDatum | null>(null);

  if (props.chartType === 'bar') {
    const { data } = props;

    const barData = data.map((d, i) => ({
      ...d,
      color: colors?.[i % colors.length],
      className: classNames(styles.baseBar, {
        [styles.dimmedBar]: hoveredBar && hoveredBar.label !== d.label,
      }),
    }));

    return (
      <BarChart
        data={barData}
        {...(typeof minDataValue === 'number' &&
        typeof maxDataValue === 'number'
          ? { xAxis: { min: minDataValue, max: maxDataValue } }
          : {})}
        showBarLabels={true}
        format={FormatType.PERCENTAGE}
        onBarHover={setHoveredBar}
        tooltipContent={(data) => (
          <div>
            <Text fontWeight="semibold" color="#fff" fontSize="xs">
              {`${data.label}: ${isNumber(data.value) ? formatNumber(data.value) : '-'}`}
            </Text>
            <Text color="#fff" fontSize="xs">
              {`(Sample Size: ${data.count})`}
            </Text>
          </div>
        )}
      />
    );
  }

  if (props.chartType === 'line') {
    const { data } = props;
    return (
      <LineChart data={data} format={FormatType.PERCENTAGE} colors={colors} />
    );
  }
  return null;
};
