import { Text } from '@chakra-ui/react';
import {
  LocalSelectionCategories,
  SelectionCategories,
  SelectionListIdNames,
} from '@revelio/filtering';
import { GptSentimentReview } from './gpt-sentiment-review';
import { PlotCard, StyledPlotSelect } from '../../../shared/components';
import { PrimaryView } from '@revelio/core';
import { useReviewPlotsSelection } from './hooks/use-review-plots-selection';
import { ReviewType } from '@revelio/data-access';

export type SelectedEntity = {
  id: string;
  label: string;
  rcid: string;
  selectionListId: SelectionCategories;
};

export const SentimentReviewsSummary = ({
  primaryFilters,
  primaryView,
  allFilters,
  reviewType,
  viewType,
}: {
  primaryFilters: SelectionListIdNames[];
  primaryView: PrimaryView;
  allFilters: (SelectionCategories | LocalSelectionCategories)[];
  reviewType: ReviewType;
  viewType: string;
}) => {
  const { selectedEntity, handleChange, plotSelectOptions } =
    useReviewPlotsSelection({ primaryFilters, reviewType });

  const getReviewTypeTitle = (type: ReviewType, viewType: string) =>
    `${viewType === 'overtime' ? 'Trending' : ''} ${
      type === 'pos' ? 'Positive' : 'Negative'
    } Reviews`;

  return (
    <PlotCard
      title={getReviewTypeTitle(reviewType, viewType)}
      info={
        <Text color="white">{`An AI-generated summary of all ${reviewType} reviews.`}</Text>
      }
      topRight={
        <StyledPlotSelect
          value={selectedEntity}
          options={plotSelectOptions}
          width={125}
          onChange={handleChange}
        />
      }
    >
      <GptSentimentReview
        primaryView={primaryView}
        reviewType={reviewType}
        filters={allFilters}
        isSnapshotDate={viewType === 'snapshot'}
        selectedEntity={selectedEntity}
      />
    </PlotCard>
  );
};
