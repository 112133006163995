import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Spinner,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useCombobox } from 'downshift';

/* eslint-disable-next-line */
export interface AutocompleteProps<T> {
  inputId: string;
  placeholder?: string;
  formLabel?: string;
  inputValue: string;
  setInputValue: React.Dispatch<string>;
  suggestions: T[];
  setSuggestions: React.Dispatch<T[]>;
  dropdownHeader?: string;
  initialFocusRef?: any;
  showRightAddon?: boolean;
  showLoader?: boolean;
  isLoading?: boolean;
  isTypeaheadDisabled?: boolean;
  comboBoxProps?: any;
}

export function Autocomplete<T>({
  inputId,
  placeholder,
  formLabel,
  inputValue,
  setInputValue,
  suggestions,
  setSuggestions,
  dropdownHeader,
  initialFocusRef,
  showRightAddon,
  showLoader,
  isLoading,
  isTypeaheadDisabled,
  comboBoxProps,
}: AutocompleteProps<T>) {
  const {
    isOpen,
    getLabelProps,
    getComboboxProps,
    getInputProps,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox(
    comboBoxProps || {
      items: suggestions,
      inputValue,
      onInputValueChange: ({ inputValue: newValue, type }) => {
        // Manual typing - use the event to preserve cursor position
        if (type === useCombobox.stateChangeTypes.InputChange) {
          return;
        }

        // Selection from dropdown - update directly
        setInputValue(newValue || '');
      },
      itemToString: (item: any) => item?.label || item?.name || '',
    }
  );

  const chakraStyles = useMultiStyleConfig('AutoComplete', {
    isOpen,
    numSuggestions: suggestions.length,
  });

  const inputProps = getInputProps({
    onKeyDown: (e) => {
      const isSuggestionHighlighted = highlightedIndex > -1;
      if (isSuggestionHighlighted) {
        e.stopPropagation();
      }
    },
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    refKey: 'ref',
    ref: initialFocusRef,
  });

  return (
    <Box>
      <FormControl isRequired>
        {formLabel && (
          <FormLabel {...getLabelProps()} fontSize="sm">
            {formLabel}
          </FormLabel>
        )}
        <Box {...getComboboxProps()}>
          <InputGroup size="sm">
            <Input
              id={inputId}
              placeholder={placeholder}
              type="text"
              width="100%"
              {...inputProps}
            />
            {!isTypeaheadDisabled && showLoader && isLoading && (
              <InputRightElement>
                <Spinner color="green.500" size="xs" />
              </InputRightElement>
            )}
            {!isTypeaheadDisabled && showRightAddon && (
              <InputRightAddon
                {...getToggleButtonProps()}
                _hover={{ cursor: 'pointer' }}
              >
                {isOpen ? (
                  <ChevronDownIcon w="1rem" h="1rem" />
                ) : (
                  <ChevronUpIcon w="1rem" h="1rem" />
                )}
              </InputRightAddon>
            )}
          </InputGroup>
        </Box>
      </FormControl>

      {!isTypeaheadDisabled && (
        <Flex {...getMenuProps()} __css={chakraStyles.menu}>
          {isOpen && (
            <Box __css={chakraStyles.scrollBox}>
              {suggestions.length > 0 && (
                <Flex
                  h="2rem"
                  direction="column"
                  justifyContent="center"
                  w="100%"
                >
                  <Text fontSize="xs" fontWeight="600">
                    {dropdownHeader}
                  </Text>
                </Flex>
              )}

              {suggestions.map((item: any, index: number) => (
                <Flex
                  key={index}
                  {...getItemProps({ item, index })}
                  bg={highlightedIndex === index ? 'gray.100' : 'transparent'}
                  __css={chakraStyles.item}
                >
                  <Text fontSize="xs" fontWeight="semi-bold">
                    {item.label || item.name}
                  </Text>
                </Flex>
              ))}
            </Box>
          )}
        </Flex>
      )}
    </Box>
  );
}

export default Autocomplete;
