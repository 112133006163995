import { Client, GraphQLRequest, OperationResult } from 'urql';
import {
  CompositionDataQuery,
  PostingsDataQuery,
  PostingsRolesDataQuery,
  SentimentOvertimeDataQuery,
  SentimentReviewsNegativeQuery,
  SentimentReviewsPositiveQuery,
  SentimentSnapshotDataQuery,
  TalentDiscoveryDataQuery,
  TransitionDataQuery,
} from '@revelio/data-access';
import { get } from 'lodash';
import {
  combineLatest,
  map,
  of,
  switchMap,
  tap,
  first,
  filter,
  Observable,
} from 'rxjs';
import { CalculatedEndpoint, EndpointMapper } from '../data-api/data-api.model';
import {
  SerializedFiltersForQuery,
  SelectionCategories,
  SelectionList,
} from './filters.model';
import { gqlDataTransformer } from './transformers.gql';
import { GqlPlotName } from './gql-models/gql.models';
import {
  getDataView,
  getPrimaryViewFromPrimaryFilter,
  getQueryRequest,
} from '../utils';
import {
  DataView,
  PrimaryView,
  TimeFrameView,
  Views,
  addLoadingStatus,
  PrimaryFilters,
  removeLoadingStatus,
} from '@revelio/core';
import { selectionListDataSource } from './filters.repository';

type GqlResult = OperationResult<
  | TalentDiscoveryDataQuery
  | CompositionDataQuery
  | SentimentSnapshotDataQuery
  | SentimentOvertimeDataQuery
  | SentimentReviewsPositiveQuery
  | SentimentReviewsNegativeQuery
  | PostingsDataQuery
  | PostingsRolesDataQuery
  | TransitionDataQuery
>;

interface ViewSource {
  endpoint: CalculatedEndpoint;
  primaryView: PrimaryView | null;
  dataView: DataView;
  timeFrameView: TimeFrameView;
  view: Views;
  selectionLists?: SelectionList[];
}

interface ViewSourceUnfiltered extends Omit<ViewSource, 'dataView'> {
  dataView: DataView | null;
}

interface QueryResult extends Pick<ViewSource, 'endpoint' | 'view'> {
  queryResult: GqlResult;
}

interface GetGqlDataProps {
  gqlClient: Client;
  endpoint: EndpointMapper;
  includeInGlobalLoader?: boolean;
  requestHash: string;
  filters: SerializedFiltersForQuery;
  prevGqlRequest?: { request: GraphQLRequest | null };
}

export function getGqlData({
  gqlClient,
  endpoint,
  filters,
  requestHash,
  includeInGlobalLoader,
  prevGqlRequest,
}: GetGqlDataProps) {
  return combineLatest({
    hash: of(requestHash),
    endpoint,
    selectionListsData: selectionListDataSource.data$({
      key: [
        SelectionCategories.SKILL_K75,
        SelectionCategories.SKILL_K700,
        SelectionCategories.SKILL_K3000,
        SelectionCategories.REGION,
        SelectionCategories.COUNTRY,
        SelectionCategories.METRO_AREA,
        SelectionCategories.JOB_CATEGORY,
        SelectionCategories.ROLE_K50,
        SelectionCategories.ROLE_K500,
        SelectionCategories.INDUSTRY,
        SelectionCategories.RICS_K10,
        SelectionCategories.RICS_K50,
        SelectionCategories.RICS_K400,
      ],
    }),
  }).pipe(
    first(),
    tap(({ hash }) => {
      if (includeInGlobalLoader) addLoadingStatus(hash);
    }),
    map((source): ViewSourceUnfiltered => {
      const { endpoint } = source;

      const { name, endpointPath } = endpoint;
      const dataView = getDataView({ endpointPath, plotName: name || '' });

      const primaryView = getPrimaryViewFromPrimaryFilter(
        filters.primary_filter as PrimaryFilters
      );

      const timeFrameView = endpointPath.includes('overtime')
        ? TimeFrameView.OVERTIME
        : TimeFrameView.SNAPSHOT;

      const isTDQuery = endpointPath.includes(Views.TALENT_DISCOVERY);
      const re = new RegExp(/\/plots\/([^/]+)\//);
      const view = isTDQuery
        ? Views.TALENT_DISCOVERY
        : (get(endpointPath.match(re), '[1]') as Views);

      return {
        endpoint,
        primaryView,
        dataView,
        timeFrameView,
        view,
        selectionLists: source.selectionListsData.selectionLists,
      };
    }),
    filter((source): source is ViewSource => !!source.dataView),
    switchMap((source): Observable<QueryResult> => {
      const {
        endpoint,
        primaryView,
        dataView,
        timeFrameView,
        view,
        selectionLists,
      } = source;

      const gqlRequest = getQueryRequest({
        filters,
        primaryView,
        timeFrameView,
        dataView,
        selectionLists,
      });

      if (prevGqlRequest) prevGqlRequest.request = gqlRequest;

      return combineLatest({
        queryResult: gqlClient.executeQuery(gqlRequest),
        endpoint: of(endpoint),
        view: of(view),
      });
    }),
    filter((source) => !!source.queryResult),
    map((source) => {
      const { queryResult, endpoint, view } = source;
      const { name, endpointPath } = endpoint;

      if (!queryResult.hasNext) {
        removeLoadingStatus('tabChange');
      }

      const plotName =
        view === Views.TALENT_DISCOVERY
          ? GqlPlotName.TALENT_DISCOVERY
          : (`${name}${
              endpointPath.includes('overtime') ? '_overtime' : ''
            }` as GqlPlotName);

      return gqlDataTransformer({
        dataResp: queryResult,
        meta: { requestHash, filters, plotName, view },
      });
    })
  );
}
