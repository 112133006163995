import { Flex } from '@chakra-ui/react';
import { flatten } from 'lodash';
import { View } from '@revelio/data-access';
import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
  Views,
} from '@revelio/core';
import {
  SelectionCategories,
  useViewFilters,
  FilterContainer,
  FilterChips,
  FilterMenu,
  ViewTypes,
  useViewFilterDefaults,
  useStoredFilterSet,
  FilterMenuLimits,
  useSelectionLists,
  useDefaultLastMonth,
  DefaultDates,
  FilterSets,
  FilterMenuItemOrConfig,
  createSelectableFiltersMap,
  SHARED_SET_ENTITY_LIMIT,
  LocalSelectionCategories,
  Tab,
  FilterChipsContainer,
  FiltersUsedInTabs,
  useAdaptiveRoleTaxonomy,
  FilterSetSaveMenu,
  useTabMeta,
  PrimaryEntityPopoutTreeFilter,
  getNestedEntity,
  useSyncFiltersToSearchParamsPure,
  useInitializePrimaryFilter,
} from '@revelio/filtering';
import DashboardPage from '../../DashboardPage';
import { useGetSentimentReviewsData } from './hooks/use-get-sentiment-reviews-data';
import { SentimentReviewsSummary } from './sentiment-reviews-summary';
import { SentimentEffectsCharts } from './sentiment-effects-charts';
import { SentimentTabs } from './components';
import { useActiveColors } from './hooks/use-active-primary-entities-state';
import { useInitSentimentTabs } from './hooks/use-init-sentiment-tabs';

export interface SentimentEffectsProps {
  title: PageTitles[];
  viewGroup: Tab;
  primaryViewFilters: FilterMenuItemOrConfig[];
  selectableFilters: FilterMenuItemOrConfig[];
}

const SENTIMENT_VIEW_GROUP_CONFIG = {
  [ViewTypes.COMPANY]: {
    sharedFilterSetId: FilterSets.COMPANY,
    filterSet: FilterSets.COMPANY_SENTIMENT,
    savedSetView: View.Company,
    primaryFilter: PrimaryFilters.COMPANY,
  },
  [ViewTypes.ROLE]: {
    sharedFilterSetId: FilterSets.ROLE,
    filterSet: FilterSets.ROLE_SENTIMENT,
    savedSetView: View.Role,
    primaryFilter: PrimaryFilters.ROLE,
  },
  [ViewTypes.GEO]: {
    sharedFilterSetId: FilterSets.GEOGRAPHY,
    filterSet: FilterSets.GEOGRAPHY_SENTIMENT,
    savedSetView: View.Geography,
    primaryFilter: PrimaryFilters.GEOGRAPHY,
  },
};

const SENTIMENT_OVERTIME_LIMIT = 6;
const view = Views.SENTIMENT;

export const primaryViewMap: Record<Tab, PrimaryView> = {
  [ViewTypes.COMPANY]: PrimaryView.COMPANY,
  [ViewTypes.ROLE]: PrimaryView.ROLE,
  [ViewTypes.GEO]: PrimaryView.GEOGRAPHY,
};

export function Sentiment({
  title,
  viewGroup,
  primaryViewFilters,
  selectableFilters: selectableFiltersProps,
}: SentimentEffectsProps) {
  const primaryFilter = SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].primaryFilter;
  const primaryEntity = getNestedEntity(viewGroup);
  const primaryView = primaryViewMap[viewGroup];

  const { tabIndex, setTabIndex, viewType } = useInitSentimentTabs();

  const selectableFilters =
    tabIndex === 0
      ? [...selectableFiltersProps, SelectionCategories.SNAPSHOT_DATE]
      : [...selectableFiltersProps, SelectionCategories.DATE_RANGE];

  const selectableFiltersMap = createSelectableFiltersMap([
    ...selectableFiltersProps,
    SelectionCategories.SNAPSHOT_DATE,
    SelectionCategories.DATE_RANGE,
  ]);

  const primaryFilters = flatten(
    createSelectableFiltersMap(primaryViewFilters)
  ) as SelectionCategories[];
  const flatSelectableFilters = flatten(selectableFiltersMap);

  const storedFilterSetArgs = {
    sharedSetId: SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].sharedFilterSetId,
    tab: viewGroup,
    primaryEntitiesSync: true,
    limit: SENTIMENT_OVERTIME_LIMIT,
    filterNames: primaryFilters,
    uniqueSetId: SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].filterSet,
    defaultLimit: SENTIMENT_OVERTIME_LIMIT,
  };

  useStoredFilterSet(storedFilterSetArgs);

  useSelectionLists([
    ...primaryFilters,
    ...flatSelectableFilters,
    ...FiltersUsedInTabs,
  ]);

  useViewFilters([
    ...primaryFilters,
    ...flatSelectableFilters,
    SelectionCategories.DATE_RANGE,
  ]);

  useAdaptiveRoleTaxonomy({
    viewType: viewGroup,
    primaryFilters,
  });

  useTabMeta({
    savedSetView: SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].savedSetView,
    view,
    viewType: viewGroup,
    limit: SENTIMENT_OVERTIME_LIMIT,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });

  const viewFilterDefaultArgs = {
    view,
    viewType: viewGroup,
    presetView: SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: SENTIMENT_OVERTIME_LIMIT,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };
  useViewFilterDefaults(viewFilterDefaultArgs);

  useDefaultLastMonth({
    view,
    viewType: viewGroup,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
    dateKey: SelectionCategories.DATE_RANGE,
  });

  const props = useGetSentimentReviewsData({
    viewType,
    view: primaryView,
    primaryFilters,
  });

  const allFilters = [...primaryFilters, ...flatSelectableFilters];

  useInitializePrimaryFilter({ primaryFilter });

  useSyncFiltersToSearchParamsPure({
    primaryFilters,
    syncToPrimaryEntities: true,
    isLoading: props.loading,
    paramsToPreserve: [LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME],
  });

  const activeColors = useActiveColors(primaryFilters);

  return (
    <DashboardPage
      title={title}
      hideSelectionsMargins
      loading={props.loading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            isPrimaryChip={true}
            min={1}
            limit={6}
            showColors
            addButton={
              <PrimaryEntityPopoutTreeFilter
                {...primaryEntity}
                maxSelections={SHARED_SET_ENTITY_LIMIT}
                minSelections={1}
                activeLimit={SENTIMENT_OVERTIME_LIMIT}
              >
                {AddEntityButtonText[primaryFilter]}
              </PrimaryEntityPopoutTreeFilter>
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={[
              ...selectableFiltersProps,
              SelectionCategories.SNAPSHOT_DATE,
              SelectionCategories.DATE_RANGE,
            ]}
            variant="filterChip"
            limit={FilterMenuLimits.SENTIMENT}
            viewType={viewGroup}
            propsView={Views.SENTIMENT}
            showGranularity
            filtersToIgnore={
              viewType === 'snapshot'
                ? [SelectionCategories.DATE_RANGE]
                : [SelectionCategories.SNAPSHOT_DATE]
            }
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  filters={selectableFilters}
                  selectMenuOpenDefault
                  limit={FilterMenuLimits.SENTIMENT}
                  view={view}
                />
                <FilterSetSaveMenu
                  view={SENTIMENT_VIEW_GROUP_CONFIG[viewGroup].savedSetView}
                />
              </>
            }
          />
        </Flex>
        <SentimentTabs index={tabIndex} onChange={setTabIndex} />
      </FilterContainer>

      <Flex width="100%" height="100%" gap={4}>
        <Flex flex="2.5" minWidth="0" minHeight="0">
          <SentimentEffectsCharts colors={activeColors} {...props} />
        </Flex>

        <Flex
          flex="1"
          gap={4}
          flexDirection="column"
          minWidth="0"
          minHeight="0"
        >
          <Flex flex="1" minWidth="0" minHeight="0">
            <SentimentReviewsSummary
              viewType={viewType}
              primaryFilters={primaryFilters}
              allFilters={allFilters}
              reviewType="pos"
              primaryView={primaryView}
            />
          </Flex>

          <Flex flex="1" minWidth="0" minHeight="0">
            <SentimentReviewsSummary
              primaryFilters={primaryFilters}
              allFilters={allFilters}
              reviewType="neg"
              primaryView={primaryView}
              viewType={viewType}
            />
          </Flex>
        </Flex>
      </Flex>
    </DashboardPage>
  );
}
