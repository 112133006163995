import { Flex, Grid } from '@chakra-ui/layout';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SentimentSubFilterNames } from '@revelio/filtering';

import { PlotGrid, TopicPlotCard } from './components';
import { Topic, defaultTopics, topics } from './topics-subfilter/topics';
import { SentimentBarChartData, SentimentLineChartData } from './types';

type SentimentEffectsChartsSnapshot = {
  viewType: 'snapshot';
  data: SentimentBarChartData;
};

type SentimentEffectsChartsTrend = {
  viewType: 'overtime';
  data: SentimentLineChartData;
};

type SentimentEffectsChartsProps =
  | SentimentEffectsChartsSnapshot
  | SentimentEffectsChartsTrend;

export const SentimentEffectsCharts = (
  props: SentimentEffectsChartsProps & { colors?: string[] }
) => {
  const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!topics.length) return;

    const topicsParam = searchParams.get(SentimentSubFilterNames.SUB_TOPICS);

    if (topicsParam) {
      const topicIds = topicsParam.split(',');
      const topicsFromParams = topicIds
        .map((id) => topics.find((topic) => topic.id.toString() === id))
        .filter((topic): topic is Topic => topic !== undefined);
      if (topicsFromParams.length) {
        setSelectedTopics(topicsFromParams);
        return;
      }
    }

    // Fallback: update search params with default topics
    const defaultTopicIds = defaultTopics.map((topic) => topic.id.toString());
    const newParams = new URLSearchParams(searchParams);
    newParams.set(
      SentimentSubFilterNames.SUB_TOPICS,
      defaultTopicIds.join(',')
    );
    setSearchParams(newParams);
    setSelectedTopics(defaultTopics);
  }, [searchParams, setSearchParams]);

  const updateTopicAtIndex = (index: number, newTopic: Topic) => {
    setSelectedTopics((prev) => {
      return prev.map((topic, i) => (i === index ? newTopic : topic));
    });
  };

  const plotGrids: PlotGrid[] = (() => {
    if (props.viewType === 'snapshot') {
      return selectedTopics.map((topic, index) => ({
        topic,
        chartType: 'bar',
        data: props.data[topic.value],
        index,
      }));
    }
    if (props.viewType === 'overtime') {
      return selectedTopics.map((topic, index) => ({
        topic,
        chartType: 'line',
        data: props.data[topic.value],
        index,
      }));
    }
    return [];
  })();

  const { min, max } = plotGrids.reduce(
    (acc, grid) => {
      if (grid.chartType === 'bar') {
        return {
          min: Math.min(
            acc.min,
            ...grid.data.map((d) => d.value).filter((v) => v !== null)
          ),
          max: Math.max(
            acc.max,
            ...grid.data.map((d) => d.value).filter((v) => v !== null)
          ),
        };
      }
      return acc;
    },
    { min: 0, max: 0 }
  );

  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      templateRows="repeat(3, 1fr)"
      gap={4}
      w="100%"
      h="100%"
    >
      {plotGrids.map((plotGrid, i) => (
        <Flex
          key={`${plotGrid.topic.value}-${i}`}
          borderRadius="md"
          border="1px solid #e5ebf1"
          minWidth="0"
          minHeight="0"
        >
          {plotGrid && (
            <TopicPlotCard
              plotGrid={plotGrid}
              selectedTopic={selectedTopics[i]}
              updateTopicAtIndex={(topic) => updateTopicAtIndex(i, topic)}
              minValue={min}
              maxValue={max}
              colors={props.colors}
              index={i}
            />
          )}
        </Flex>
      ))}
    </Grid>
  );
};
