import { format as d3Format, min, precisionFixed } from 'd3';

import { Format, FormatType } from '../types';

const convertGigaToBillion = (
  formatter: (value: number) => string
): ((value: number) => string) => {
  return (value) => formatter(value).replace('G', 'B');
};

type PrecisionConfigProps = {
  values?: number[];
  increasePrecision?: number;
};

export const getFormatter = (
  format: Format,
  { values, increasePrecision = 0 }: PrecisionConfigProps = {}
): ((value: number) => string) => {
  if (typeof format === 'function') return format;

  switch (format) {
    case FormatType.SI:
      return convertGigaToBillion(d3Format('.2s'));

    case FormatType.PERCENTAGE: {
      const minStep = values
        ? min(values.slice(1).map((val, i) => Math.abs(val - values[i])))
        : undefined;

      const precision =
        Math.min(
          2,
          minStep !== undefined ? Math.max(0, precisionFixed(minStep) - 2) : 1
        ) + increasePrecision;
      return d3Format(`.${precision}%`);
    }

    case FormatType.CURRENCY:
      return (value: number) => {
        if (value >= 1000) {
          return convertGigaToBillion(d3Format('$.2s'))(value);
        }

        return d3Format('$,d')(value);
      };

    case FormatType.CURRENCY_INTEGER:
      return convertGigaToBillion(d3Format('$,.0f'));

    case FormatType.INTEGER:
      return d3Format(',.0f');

    case FormatType.DECIMAL: {
      return (value: number) => {
        if (Math.abs(value) < 0.01 && Math.abs(value) > 0) {
          return d3Format('.2e')(value);
        }
        return d3Format(',.2f')(value);
      };
    }

    case FormatType.SCIENTIFIC:
      return d3Format('.2e');
  }
};
