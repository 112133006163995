import { Views } from '@revelio/core';
import { DateRangeValue, FilterRange } from '../../collection';
import {
  AnyFilter,
  DefaultDates,
  ISelectFilter,
  SelectionCategories,
} from '../../../engine/filters.model';
import DateSelect from '../../date-select/date-select';

type DateFiltersProps = {
  view: Views;
  selectValue: ISelectFilter | null | undefined;

  fil: AnyFilter | undefined;
  endDateDefaultFilterName: DefaultDates | undefined;
  dateRangeValue: DateRangeValue | undefined;
  setDateRangeValue: React.Dispatch<
    React.SetStateAction<DateRangeValue | undefined>
  >;
  dateRangeError: string;
  setDateRangeError: React.Dispatch<React.SetStateAction<string>>;
  dateRangeFullValue: DateRangeValue | undefined;
  setDateRangeFullValue: React.Dispatch<
    React.SetStateAction<DateRangeValue | undefined>
  >;
  selectedSnapshotDate: Date | undefined;
  setSelectedSnapshotDate: React.Dispatch<
    React.SetStateAction<Date | undefined>
  >;
};

export const DateFilters = ({
  view,
  selectValue,
  fil,
  endDateDefaultFilterName,
  dateRangeValue,
  setDateRangeValue,
  dateRangeError,
  setDateRangeError,
  dateRangeFullValue,
  setDateRangeFullValue,
  selectedSnapshotDate,
  setSelectedSnapshotDate,
}: DateFiltersProps) => {
  const isDateRange =
    selectValue?.filterName === SelectionCategories.DATE_RANGE;
  const isDateRangeFull =
    selectValue?.filterName === SelectionCategories.DATE_RANGE_FULL;

  return (
    <>
      {(isDateRange || isDateRangeFull) && (
        <FilterRange
          view={view}
          endDateDefaultFilterName={endDateDefaultFilterName}
          dateRangeValue={isDateRangeFull ? dateRangeFullValue : dateRangeValue}
          setDateRangeValue={
            isDateRangeFull ? setDateRangeFullValue : setDateRangeValue
          }
          filterName={
            isDateRangeFull ? SelectionCategories.DATE_RANGE_FULL : undefined
          }
          dateRangeError={dateRangeError}
          setDateRangeError={setDateRangeError}
        />
      )}

      {/* Snapshot Date */}
      {selectValue?.filterName == SelectionCategories.SNAPSHOT_DATE && (
        <DateSelect
          view={view}
          selectedDate={selectedSnapshotDate}
          setSelectedDate={setSelectedSnapshotDate}
        />
      )}
    </>
  );
};
