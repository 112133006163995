import { useEffect } from 'react';
import { useSingleOrMoreFilterState } from '../../../engine/filters.engine';
import { SelectionListIdNames } from '../../../engine/filters.model';
import { get } from 'lodash';
import { PlotColourLookup, updatePlotColorLookup } from '@revelio/core';
import { plotColors } from '@revelio/replots';

const getColorLookup = (filters: any[], colors: string[]) => {
  const colorLookup: PlotColourLookup = {};

  filters?.forEach((f, index) => {
    colorLookup[`${get(f, 'selectionListId')}__${f.id}`] = {
      id: f.id,
      columnName: f.selectionListId,
      shortName: f.shortName,
      longName: f.longName,
      color: colors[index],
    };
  });

  return colorLookup;
};

export const useColorCodingState = (filterNames: SelectionListIdNames[]) => {
  const [activeFilters] = useSingleOrMoreFilterState(filterNames);

  useEffect(() => {
    const activeFilterValues = get(activeFilters, 'value', []);

    if (!Array.isArray(activeFilterValues)) {
      return;
    }

    const colorLookup = getColorLookup(activeFilterValues, plotColors);

    updatePlotColorLookup(colorLookup);

    // eslint-disable-next-line
  }, [activeFilters?.value]);
};
