import { plotColors } from '@revelio/replots';
import {
  Filter,
  SelectionCategories,
  LocalSelectionCategories,
} from '../engine/filters.model';
import { useAllFiltersState } from '../engine/filters.repository';

interface Entity {
  id: string | number;
  selectionListId?: string | number;
  longName?: string;
}

function isEntity(value: unknown): value is Entity {
  return typeof value === 'object' && value !== null && 'id' in value;
}

const getMatchingPrimaryEntityIndices = (
  primaryEntities: Filter | undefined,
  activeEntities: Filter[]
): [number, Entity][] => {
  if (!primaryEntities?.value || !Array.isArray(primaryEntities.value)) {
    return [];
  }

  return primaryEntities.value.reduce<[number, Entity][]>(
    (matches, primaryEntity, index) => {
      const isPrimaryEntityActive = activeEntities.some((activeEntity) => {
        if (!Array.isArray(activeEntity.value)) {
          return false;
        }

        return activeEntity.value.some(
          (activeValue) =>
            isEntity(activeValue) &&
            isEntity(primaryEntity) &&
            activeValue.id === primaryEntity.id &&
            activeEntity.id === primaryEntity.selectionListId
        );
      });

      if (isPrimaryEntityActive && isEntity(primaryEntity)) {
        matches.push([index, primaryEntity]);
      }

      return matches;
    },
    []
  );
};

export function useActiveColors(
  primaryFilters: (SelectionCategories | LocalSelectionCategories)[]
) {
  const activeFilters = useAllFiltersState();

  const primaryEntities = activeFilters.find(
    (filter: Filter) => filter.id === LocalSelectionCategories.PRIMARY_ENTITIES
  );

  const activeEntities = activeFilters.filter((filter: Filter) =>
    primaryFilters.includes(filter.id as SelectionCategories)
  );

  const indexEntityPairs = getMatchingPrimaryEntityIndices(
    primaryEntities,
    activeEntities
  );

  const colorMap: Record<string, string> = {};
  indexEntityPairs.forEach(([index, entity]) => {
    if (entity.longName) {
      colorMap[entity.longName] = plotColors[index];
    }
  });

  return { colorMap };
}
