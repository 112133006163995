import {
  DATE_FORMAT_WITH_DAY,
  formatSnapshotDateString,
  STANDARD_DATE_FORMAT,
  swapDateFormat,
  Views,
} from '@revelio/core';
import {
  AnyFilter,
  FilterItem,
  FilterTypes,
  LocalSelectionCategories,
  SelectionCategories,
} from '../../engine/filters.model';
import {
  deleteFilter,
  upsertFilter,
  upsertFiltersWithProvidedValue,
} from '../../engine/filters.repository';
import {
  HandlerConfig,
  SelectedRef,
  SelectedTreeRef,
  WorkFlows,
} from './types';
import {
  filterWorkflowLookup,
  filterTypeLookup,
  configLookup,
  screenerFiltersWithTimeComponent,
} from './lookups.config';
import {
  defaultDateVal,
  defaultOPVal,
  getStartEndValues,
  screenerDefaults,
} from './helpers.config';
import {
  getDateFormat,
  getSelectedLastMonth,
  getStartDateConst,
} from '../collection';
import { filterStore } from '../../engine/filters.engine';
import { assign, get, padStart } from 'lodash';
import {
  getDefaultLastMonth,
  getLastStartDate,
} from '../../engine/filters.core';
import { format, parse } from 'date-fns';
import { getDate } from 'date-fns';
import { isAfter } from 'date-fns';
import { getAllEntities } from '@ngneat/elf-entities';

// handler object with wrokflow methods
const handler: Record<
  WorkFlows,
  Record<string, (config: HandlerConfig) => void>
> = {
  COMPANY_CLEANED: {
    clear: (config) => {
      if (config.setCompanyValue) {
        config.setCompanyValue([]);
      }
    },
    submit: (config) => {
      if (!config.selectValue) {
        return;
      }
      // check if there is an active company cleaned filter
      const active = config.activeFilters?.find(
        (f: FilterItem | AnyFilter) =>
          f.id === SelectionCategories.COMPANY_CLEANED
      );

      // only update active company cleaned filter if:
      // 1. making changes to currently active filter
      // 2. adding new filters
      if (active || (config.companyValue && config.companyValue.length > 0)) {
        upsertFilter(config.selectValue.filterName, {
          isMulti: false,
          type: filterTypeLookup[config.selectValue.filterName],
          formatOverride: Views.SCREENER,
          value: config.companyValue,
        });
      }
    },
  },
  [WorkFlows.DATE]: {
    clear: ({ setSelectedSnapshotDate }) => {
      setSelectedSnapshotDate(undefined);
    },
    submit: ({ selectedSnapshotDate, view }) => {
      if (selectedSnapshotDate) {
        const formattedSnapshotDate = swapDateFormat(
          selectedSnapshotDate,
          STANDARD_DATE_FORMAT
        );

        const lastMonthFilter = getSelectedLastMonth(view);
        const filters = filterStore.query(getAllEntities());

        // this happens if set to "latest"
        let isSnapshotMaximumRange = false;
        if (formattedSnapshotDate === lastMonthFilter) {
          isSnapshotMaximumRange = true;
          deleteFilter(SelectionCategories.DATE_RANGE);
          deleteFilter(SelectionCategories.DATE_RANGE_FULL);
          return;
        }

        upsertFilter(SelectionCategories.SNAPSHOT_DATE, {
          type: FilterTypes.DATE,
          value: formattedSnapshotDate,
          isMaximumRange: isSnapshotMaximumRange,
        });

        let shouldUpdateEndDate = false;

        const currentDateRangeFilter = filters?.find(
          (filter) => filter.id === SelectionCategories.DATE_RANGE
        );

        if (currentDateRangeFilter) {
          const rangeValue = currentDateRangeFilter.value;

          const endDate = get(rangeValue, 'endDate');

          shouldUpdateEndDate = formattedSnapshotDate > endDate;
        }

        // set the overtime date range filter's start date to match the selected snapshot date
        upsertFiltersWithProvidedValue({
          [SelectionCategories.DATE_RANGE]: {
            value: {
              startDate: formattedSnapshotDate,
              // if the snapshot date is greater than the current end date, update the end date
              ...(shouldUpdateEndDate ? { endDate: lastMonthFilter } : {}),
            },
            isMaximumRange: false,
          },
        });

        const lastStartDateString = filterStore.query(getLastStartDate) || '';
        const lastStartDateDay = getDate(
          parse(lastStartDateString, DATE_FORMAT_WITH_DAY, new Date())
        );

        upsertFiltersWithProvidedValue({
          [SelectionCategories.DATE_RANGE_FULL]: {
            value: {
              startDate: `${formattedSnapshotDate}-${padStart(lastStartDateDay.toString(), 2, '0')}`,
              endDate: lastStartDateString,
            },
            isMaximumRange: false,
          },
        });
      }
    },
  },
  [WorkFlows.DATE_RANGE]: {
    clear: ({ setDateRangeValue }) => {
      setDateRangeValue(undefined);
    },
    submit: ({ dateRangeValue, view, endDateDefaultFilterName }) => {
      if (dateRangeValue?.startDate && dateRangeValue.endDate) {
        const startDateFilterValue = swapDateFormat(
          dateRangeValue.startDate,
          getDateFormat(view)
        ) as string;

        const endDateFilterValue = swapDateFormat(
          dateRangeValue.endDate,
          getDateFormat(view)
        ) as string;

        const earliestStartDate = getStartDateConst(view);
        const hasMaximumStartDate = earliestStartDate === startDateFilterValue;

        const lastMonthFilter = getSelectedLastMonth(view);

        const hasMaximumEndDate = lastMonthFilter === endDateFilterValue;

        upsertFilter(SelectionCategories.DATE_RANGE, {
          type: FilterTypes.DATE_RANGE,
          value: {
            startDate: startDateFilterValue,
            endDate: endDateFilterValue,
          },
          isMaximumRange: hasMaximumEndDate && hasMaximumStartDate,
        });

        // set the snapshot date
        if (startDateFilterValue !== earliestStartDate) {
          // when date range set to max, it removes the filter chip
          // it makes sense to also remove the snapshot date filter chip instead of setting it to 2008
          upsertFilter(SelectionCategories.SNAPSHOT_DATE, {
            type: FilterTypes.DATE,
            value: formatSnapshotDateString(startDateFilterValue),
            isMaximumRange: false,
          });
        } else {
          const lastSnapshotMonth = getSelectedLastMonth(Views.OVERVIEW);

          upsertFilter(SelectionCategories.SNAPSHOT_DATE, {
            type: FilterTypes.DATE,
            value: lastSnapshotMonth,
            isMaximumRange: true,
          });
        }

        const lastStartDateString = filterStore.query(getLastStartDate) || '';
        const lastStartDate = parse(
          lastStartDateString,
          DATE_FORMAT_WITH_DAY,
          new Date()
        );

        const lastStartDateDay = padStart(
          getDate(lastStartDate)?.toString(),
          2,
          '0'
        );

        const selectedEndDateWithDay = `${endDateFilterValue}-${lastStartDateDay}`;
        upsertFiltersWithProvidedValue({
          [SelectionCategories.DATE_RANGE_FULL]: {
            value: {
              startDate: `${startDateFilterValue}-${lastStartDateDay}`,
              endDate: isAfter(
                parse(selectedEndDateWithDay, DATE_FORMAT_WITH_DAY, new Date()),
                lastStartDate
              )
                ? lastStartDateString
                : selectedEndDateWithDay,
            },
            isMaximumRange: false,
          },
        });
      }
    },
  },
  [WorkFlows.DATE_RANGE_FULL]: {
    clear: ({ setDateRangeValue }) => {
      setDateRangeValue(undefined);
    },
    submit: ({ dateRangeFullValue: dateRangeValue, view }) => {
      if (dateRangeValue?.startDate && dateRangeValue.endDate) {
        const startDateFilterValue = swapDateFormat(
          dateRangeValue.startDate,
          getDateFormat(view)
        ) as string;

        const endDateFilterValue = swapDateFormat(
          dateRangeValue.endDate,
          getDateFormat(view)
        ) as string;

        const earliestStartDate = getStartDateConst(view);
        const hasMaximumStartDate = earliestStartDate === startDateFilterValue;

        const lastMonthFilter = getSelectedLastMonth(view);

        const hasMaximumEndDate = lastMonthFilter === endDateFilterValue;

        upsertFilter(SelectionCategories.DATE_RANGE_FULL, {
          type: FilterTypes.DATE_RANGE_FULL,
          value: {
            startDate: startDateFilterValue,
            endDate: endDateFilterValue,
          },
          isMaximumRange: hasMaximumEndDate && hasMaximumStartDate,
        });

        const startDateWithoutDay = format(
          parse(startDateFilterValue, DATE_FORMAT_WITH_DAY, new Date()),
          STANDARD_DATE_FORMAT
        );
        const endDateWithoutDay = format(
          parse(endDateFilterValue, DATE_FORMAT_WITH_DAY, new Date()),
          STANDARD_DATE_FORMAT
        );
        const lastMonthDateString =
          filterStore.query(getDefaultLastMonth) || '';
        const lastStartDate = parse(
          lastMonthDateString,
          STANDARD_DATE_FORMAT,
          new Date()
        );
        upsertFilter(SelectionCategories.DATE_RANGE, {
          type: FilterTypes.DATE_RANGE,
          value: {
            startDate: startDateWithoutDay,
            endDate: isAfter(
              parse(endDateWithoutDay, STANDARD_DATE_FORMAT, new Date()),
              lastStartDate
            )
              ? lastMonthDateString
              : endDateWithoutDay,
          },
          isMaximumRange: hasMaximumEndDate && hasMaximumStartDate,
        });

        // set the snapshot date
        if (startDateFilterValue !== earliestStartDate) {
          // when date range set to max, it removes the filter chip
          // it makes sense to also remove the snapshot date filter chip instead of setting it to 2008
          upsertFilter(SelectionCategories.SNAPSHOT_DATE, {
            type: FilterTypes.DATE,
            value: startDateWithoutDay,
            isMaximumRange: false,
          });
        } else {
          const lastSnapshotMonth = getSelectedLastMonth(Views.OVERVIEW);

          upsertFilter(SelectionCategories.SNAPSHOT_DATE, {
            type: FilterTypes.DATE,
            value: lastSnapshotMonth,
            isMaximumRange: true,
          });
        }
      }
    },
  },
  SAVED_FILTER_SET: {},
  LAYOFF: {
    submit: (config) => {
      if (config.selectValue) {
        const filterType: FilterTypes =
          filterTypeLookup[config.selectValue.filterName];

        if (
          filterType === FilterTypes.LAYOFF_MSA_STATE &&
          Object.values(config.tempSelections || {}).length === 0
        ) {
          deleteFilter(config.selectValue.filterName);
          return;
        }

        upsertFilter(config.selectValue.filterName, {
          ...configLookup[filterType](config),
        });
      }
    },
  },
  SCREENER: {
    clear: ({
      selectValue,
      submitRef,
      setDateValue,
      setOpValue,
      setStartValue,
      setEndValue,
    }) => {
      // TODO: think about refactoring to not rely on refs

      if (
        selectValue &&
        submitRef &&
        setDateValue &&
        setOpValue &&
        setStartValue &&
        setEndValue
      ) {
        // clear all emp type trees
        [
          'screener-job-category',
          'screener-seniority',
          'screener-regions',
        ].forEach((sel) => {
          (
            submitRef.current[sel] as SelectedTreeRef
          )?.value.handleClearSelections();
        });

        // reset fields to default values
        setDateValue(defaultDateVal);
        setOpValue(defaultOPVal);
        setStartValue(screenerDefaults[selectValue.filterName].start);
        setEndValue(screenerDefaults[selectValue.filterName].end);
      }
    },
    submit: (config) => {
      if (config.selectValue) {
        const filterName = config.selectValue.filterName;

        const dateConfig = {};

        if (
          screenerFiltersWithTimeComponent.includes(
            filterName as SelectionCategories
          )
        ) {
          assign(dateConfig, { date: config.dateValue });
        }

        const value = {
          opValue: config.opValue,
          isPercentage: [
            SelectionCategories.HIRING_RATE,
            SelectionCategories.ATTRITION_RATE,
            SelectionCategories.GROWTH_RATE,
          ].includes(config.selectValue.filterName as SelectionCategories),
          ...dateConfig,
          ...getStartEndValues(
            config.selectValue,
            config.startValue,
            config.endValue,
            config.opValue
          ),
        };

        upsertFilter(config.selectValue.filterName, {
          isMulti: false,
          type: FilterTypes.BOARD,
          value,
        });
      }
    },
  },
  [WorkFlows.KEYWORD]: {
    clear: (config) => {
      const { selectValue, setKeywordSelections } = config;

      const filterName = get(selectValue, 'filterName', '');

      setKeywordSelections?.({ [filterName]: [] });
    },
    submit: (config) => {
      const { selectValue, keywordSelections = {} } = config;

      const filterName = get(selectValue, 'filterName', '');

      const keywordsList = get(keywordSelections, filterName);

      if (!keywordsList || !filterName) {
        return;
      }

      if (keywordsList.length === 0) {
        deleteFilter(filterName);
        return;
      }

      upsertFilter(filterName, {
        selectionListId: filterName,
        isMulti: true,
        value: keywordsList,
      });
    },
  },
  DEFAULT: {
    clear: ({ filterName, selectValue, submitRef }) => {
      // TODO: think about refactoring to not rely on refs

      const key = selectValue?.filterName || filterName;
      const selectedRef: SelectedRef | undefined =
        key && submitRef && submitRef.current[key];
      if (selectedRef) {
        (selectedRef as SelectedTreeRef).value.handleClearSelections();
      }
    },
    submit: (config) => {
      const { tempSelections = {}, selectValue } = config;
      // TODO: think about refactoring to not rely on refs
      const selectedRef: SelectedRef | undefined | null =
        config.selectValue &&
        config.submitRef &&
        config.submitRef.current[config.selectValue?.filterName];

      if (selectValue?.filterName === LocalSelectionCategories.PROVIDER) {
        upsertFilter(LocalSelectionCategories.PROVIDER, {
          isMulti: true,
          selectionListId: LocalSelectionCategories.PROVIDER,
          value: Object.values(tempSelections).map(
            (selection) => selection.item
          ),
        });
        // config.onClose();?
      }

      if (selectValue?.filterName === LocalSelectionCategories.METRIC_MODE) {
        const [metricModeSelection] = Object.values(tempSelections);
        const selectedItem = metricModeSelection?.item;

        upsertFilter(LocalSelectionCategories.METRIC_MODE, {
          isMulti: false,
          selectionListId: LocalSelectionCategories.METRIC_MODE,
          value: selectedItem,
        });
      }

      if (selectedRef) {
        (selectedRef as SelectedTreeRef).value.submit(tempSelections);
      }
    },
  },
};

/**
 * get workflow handler corresponding to input selection
 *
 * @param selection - current select value
 *
 * @returns workflow handler object
 */
export const getWorkflow = (selection: SelectionCategories | Views) => {
  const key: SelectionCategories | Views =
    selection in filterWorkflowLookup ? selection : SelectionCategories.OTHER;

  return handler[filterWorkflowLookup[key]];
};
