import { Flex, Box, Tooltip } from '@chakra-ui/react';
import {
  AnyFilter,
  DefaultDates,
  FilterMenuItemOrConfig,
  FilterList,
  FilterOrSubfilterName,
  SelectionCategories,
  NestedMenuItemConfig,
  LocalSelectionCategories,
} from '../../engine/filters.model';
import FilterChip from '../filter-chip/filter-chip';
import { useEffect, useState } from 'react';
import { flatten, get, isArray } from 'lodash';
import { Views } from '@revelio/core';
import { getWorkflow } from './workflows.config';
import { mergeGraphFilterItems, partitionFilterNames } from './helpers.config';
import { ViewTypes } from '../../data-api/data-api.model';
import {
  deleteFilters,
  useSingleOrMoreFilterState,
} from '../../engine/filters.engine';
import { FilterMenu } from '../collection';
import { plotColors } from '@revelio/replots';
import {
  getPlotColorById,
  PlotColourLookup,
  updatePlotColorLookup,
} from '@revelio/core';
import { allFilters } from '../filter-menu/filters.config';
import { mapSelectableFilters } from '../filter-menu/helpers.config';

export const filtersToShowGranularity = [
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K1500,
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.SKILL_K75,
  SelectionCategories.SKILL_K700,
  SelectionCategories.SKILL_K3000,
];

export type FilterChipTreeItemDataProps = {
  clearAll?: false;
  filterSelections: AnyFilter;
};

export interface FilterChipsProps {
  filterNames: FilterMenuItemOrConfig[];
  TDMenuConfig?: NestedMenuItemConfig[];
  addButton?: JSX.Element;
  variant?: string;
  showColors?: boolean;
  view?: Views;
  propsView?: Views;
  viewType?: ViewTypes;
  limit?: number;
  isPrimaryChip?: boolean;
  min?: number;
  offsetParent?: (SelectionCategories | LocalSelectionCategories)[];
  filtersToDisable?: SelectionCategories[];
  hideSelect?: boolean;
  filtersToIgnore?: SelectionCategories[];
  endDateDefaultFilterName?: DefaultDates;
  showGranularity?: boolean;
  useChipsSkeleton?: boolean;
}

export function FilterChips({
  filterNames,
  TDMenuConfig = [],
  addButton,
  variant,
  showColors,
  view,
  propsView,
  viewType,
  limit,
  isPrimaryChip = false,
  min = 0,
  offsetParent = [],
  hideSelect = true,
  filtersToIgnore,
  filtersToDisable,
  endDateDefaultFilterName,
  showGranularity = false,
  useChipsSkeleton = true,
}: FilterChipsProps) {
  const [unnested = [], nested = [], nestedConfigs] =
    partitionFilterNames(filterNames);

  const { mappedFilters: selectableFilters } = mapSelectableFilters(
    filterNames,
    [],
    limit
  );

  const allFilterNames = [...unnested, ...flatten(nested)];

  const partialGranularityFilters = [] as any;

  const filterNames2 = selectableFilters.map((item: any) => item.filterName);

  const filteredAllFilters = allFilters.filter((filterObj) => {
    const isPartialMatch = filterObj.filter.some((item: any) =>
      filterNames2.includes(item)
    );
    const isFullMatch = filterObj.filter.every((item: any) =>
      filterNames2.includes(item)
    );

    if (isPartialMatch && !isFullMatch && filterObj.value) {
      partialGranularityFilters.push(filterObj);
    }

    return !isPartialMatch;
  });

  const viewTypeFilters: any = {
    [ViewTypes.COMPANY]: filteredAllFilters.filter((filterObj) => {
      return filterObj.value !== SelectionCategories.INDUSTRY;
    }),
    [ViewTypes.GEO]: filteredAllFilters.filter((filterObj) => {
      return filterObj.value !== SelectionCategories.REGION;
    }),
    [ViewTypes.ROLE]: filteredAllFilters.filter(
      (filterObj) => filterObj.value !== SelectionCategories.JOB_CATEGORY
    ),
  };

  const finalFiltered =
    viewTypeFilters[viewType as ViewTypes] || filteredAllFilters;

  const filterValues = finalFiltered.map((d: any) => {
    if (get(d, 'unsupportedViews', []).includes(view)) {
      return d.value || d.filter[1];
    }
    return d.value;
  });

  const [unnested2] = partitionFilterNames(flatten(filterValues));

  const [filters] = useSingleOrMoreFilterState<AnyFilter[]>(unnested);
  const [secondaryFilters] = useSingleOrMoreFilterState<AnyFilter[]>(unnested2);

  const partialFilterNames = partialGranularityFilters.reduce(
    (acc: any, cur: any) => {
      const unsupportedGranularities = cur.filter.filter((gran: any) => {
        return !allFilterNames.includes(gran);
      });

      return [...acc, ...unsupportedGranularities];
    },
    []
  );

  const [partialFilters] =
    useSingleOrMoreFilterState<AnyFilter[]>(partialFilterNames);

  const [graphFilters] = useSingleOrMoreFilterState<AnyFilter[]>(
    flatten(nested),
    undefined,
    true
  );

  const [filterItems, setFilterItems] = useState<AnyFilter[]>([]);
  const [isMin, setIsMin] = useState<boolean>(false);

  /**
   * Maps a color to each active filter item
   *
   * @param filtered - array of active filter items
   * @param colors - array of colors to map
   *
   * @returns void
   */
  const assignColorsToActiveFilters = (
    filtered: AnyFilter[],
    colors: string[]
  ) => {
    const colorLookup: PlotColourLookup = {};

    let colorIndex = 0;

    filtered?.forEach((f) => {
      (f.value as FilterList).forEach((v: any) => {
        // format key as: {column_name}__{numericalId}
        colorLookup[`${get(f, 'selectionListId')}__${v.id}`] = {
          id: v.id,
          columnName: f.id,
          shortName: v.shortName,
          longName: v.longName || v.label,
          color: colors[colorIndex++],
        };
      });
    });

    updatePlotColorLookup(colorLookup);
  };

  /**
   * get an assigned color for a filter chip with a specific item id
   *
   * @param id
   * @returns assigned color
   */

  const findAssignedColor = (
    id: string | number,
    columnName: FilterOrSubfilterName
  ) => {
    return getPlotColorById(id, columnName);
  };

  useEffect(() => {
    const filtered = getWorkflow(view).filter({
      filters,
      filterNames: unnested,
      filtersToIgnore,
    });

    const mergedGraphFilterItems = mergeGraphFilterItems(
      nestedConfigs,
      graphFilters
    );

    if (isPrimaryChip) {
      let numActive = 0;

      if (filtered) {
        filtered.forEach((f) => {
          if ((f.value as FilterList).length === 0) {
            // if filter has no active values, remove it from store
            deleteFilters([f.id as SelectionCategories]);
          }
          numActive += (f.value as FilterList).length;
        });

        if (numActive === min) {
          setIsMin(true);
        } else {
          setIsMin(false);
        }
        if (showColors) {
          assignColorsToActiveFilters(filtered, plotColors);
        }
      }
    }

    if (filtered) {
      let filtersToDisable: any = []; // TODO: update typing, leaving as any for now as a quick fix
      if (secondaryFilters) {
        const filteredPartialFilters = partialFilters.filter((filter) => {
          return !filterNames2.includes(get(filter, 'selectionListId'));
        });

        const disabledFilters = [
          ...secondaryFilters,
          ...filteredPartialFilters,
        ];

        filtersToDisable = disabledFilters.map((fil) => {
          return {
            ...fil,
            isDisabled: true,
          };
        });
      }

      const mergedFilters =
        isPrimaryChip || view == Views.TALENT_DISCOVERY
          ? filtered
          : [...filtered, ...filtersToDisable];

      setFilterItems([...mergedGraphFilterItems, ...mergedFilters]);
    }

    // eslint-disable-next-line
  }, [filters, secondaryFilters, graphFilters, showColors, filtersToIgnore]);

  const getFilterMenuDefaultValue = (fil: AnyFilter) => {
    if (view === Views.TALENT_DISCOVERY) {
      return get(fil, 'linkedFilters.heading');
    }

    const linkedFilters = get(fil, 'linkedFilters');

    if (Array.isArray(linkedFilters)) {
      return linkedFilters;
    }

    return get(linkedFilters, 'filters', fil.id as SelectionCategories);
  };

  const isVisible = (fil: any) => {
    const isDateFilter = [
      SelectionCategories.DATE_RANGE,
      SelectionCategories.SNAPSHOT_DATE,
      SelectionCategories.DATE_RANGE_FULL,
    ].includes(get(fil, 'id') as SelectionCategories);

    if (isDateFilter) return !get(fil, 'isMaximumRange', false);

    return true;
  };

  const transformedFilterItems = filterItems;

  return (
    <Box>
      <Flex
        justifyContent="flex-start"
        alignItems="flex-start"
        flexDirection="row"
        wrap="wrap"
        rowGap="0.5rem"
        data-testid={
          isPrimaryChip ? 'primary-filter-chips' : 'non-primary-filter-chips'
        }
      >
        {transformedFilterItems.map((fil, i: number) =>
          isPrimaryChip && isArray(fil.value)
            ? fil.value.map((val: any, fI: number) => {
                const columnName = fil.id;
                const assignedColor = showColors
                  ? findAssignedColor(val.id, columnName)
                  : undefined;

                return (
                  <FilterChip
                    key={fI}
                    color={assignedColor}
                    filterItem={val}
                    filterName={fil.id as SelectionCategories}
                    variant={variant}
                    isSingleChip={false}
                    isMin={isMin}
                    min={min}
                    isPrimaryChip={isPrimaryChip}
                    propsView={propsView}
                    viewType={viewType}
                  />
                );
              })
            : isVisible(fil) && (
                <FilterMenu
                  key={i}
                  title="Filter"
                  view={view}
                  fil={fil}
                  filters={
                    view === Views.TALENT_DISCOVERY
                      ? TDMenuConfig
                      : [fil?.linkedFilters || (fil.id as SelectionCategories)]
                  }
                  endDateDefaultFilterName={endDateDefaultFilterName}
                  filtersToDisable={filtersToDisable}
                  defaultFilter={getFilterMenuDefaultValue(fil)}
                  hideSelect={hideSelect}
                  resetSelectOnOpen={false}
                  defaultIsOpen={false}
                  limit={limit}
                  offsetParent={offsetParent}
                  isDisabled={get(fil, 'isDisabled', false)}
                  triggerElement={
                    <Tooltip
                      label={get(
                        fil,
                        'tooltipMsg',
                        'This filter is unavailable on this page.'
                      )}
                      variant="label"
                      hasArrow
                      openDelay={375}
                      isDisabled={!get(fil, 'isDisabled', false)}
                    >
                      <Box role="button">
                        <FilterChip
                          filterItem={fil}
                          isAggregateChip={get(
                            fil,
                            'linkedFilters.isAggregateChip',
                            false
                          )}
                          filterName={fil.id as SelectionCategories}
                          variant={variant}
                          propsView={propsView}
                          viewType={viewType}
                          showGranularity={
                            showGranularity &&
                            filtersToShowGranularity.includes(
                              get(fil, 'selectionListId')
                            )
                          }
                          isDisabled={get(fil, 'isDisabled', false)}
                        />
                      </Box>
                    </Tooltip>
                  }
                />
              )
        )}
        <Flex columnGap="2px">{addButton}</Flex>
      </Flex>
    </Box>
  );
}

export default FilterChips;
