import {
  SelectionCategories,
  FilterContainer,
  // useHiddenFiltersWithProvidedValues,
  TabsFilter,
  FilterMenu,
  FilterChips,
  useSingleOrMoreFilterState,
  useViewFilters,
  requireAtLeastOneFilterValueOf,
  OtherFilterNames,
  useViewFilterDefaults,
  EndpointSegment,
  provideBasePlotConfigDefaults,
  ViewTypes,
  useStoredFilterSet,
  FilterSets,
  SelectFilter,
  FilterList,
  upsertFilter,
  PrimaryFilterLimits,
  FilterMenuLimits,
  LocalSelectionCategories,
  useManyPreFetchPlotConfigProviders,
  usePrimaryFilter,
  SelectableFiltersMenuProp,
  CompositionSupportedViewTypes,
  useDefaultLastMonth,
  DefaultDates,
  SubfilterConfigs,
  ValidValueTypes,
  FilterItem,
  ValueItem,
  FilterMenuItemOrConfig,
  useSyncFiltersToSearchParamsPure,
  getPrimaryDataView,
  useSelectionLists,
  SHARED_SET_ENTITY_LIMIT,
  FilterChipsContainer,
  PlotAdditionalQueryParams,
  CalculatedEndpoint,
  FiltersUsedInTabs,
  useAdaptiveRoleTaxonomy,
  FilterSetSaveMenu,
  useTabMeta,
  PrimaryEntityPopoutTreeFilter,
  SubFilterNames,
  getNestedEntity,
} from '@revelio/filtering';
import { Grid, GridItem, Flex, Box } from '@chakra-ui/react';
import { filter, tap } from 'rxjs/operators';
import { D3ChartNames } from '@revelio/d3';
import { BaseSmallPlotConfigLookup } from './base-small-plots.config';
import { Observable, pipe } from 'rxjs';
import {
  OverTimeChartPropsLookup,
  SnapshotChartPropsLookup,
} from './chart-props.config';
import { BasePlotConfigLookup } from './base-plots.config';
import { useEffect, useMemo, useState } from 'react';
import { flatten, startCase } from 'lodash';
import { DefaultCard } from '@revelio/composed';
import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
  TimeFrameView,
  TourClasses,
  Views,
  useGlobalLoaderStatus,
  useResponsivePageGridDefs,
} from '@revelio/core';
import {
  createSelectableFiltersMap,
  PrimaryDataView,
} from '@revelio/filtering';

import DashboardPage from '../DashboardPage';
import { useCompositionDataFetch } from './data-fetch';
import { BottomConfigMapperBase, TopConfigMapperBase } from './utils';
import { useTrackPerformance } from '../../hooks/mixpanel/useTrackPerformance';
import { View } from '@revelio/data-access';

export interface OverviewProps {
  title: PageTitles[];
  viewType: CompositionSupportedViewTypes;
  primaryFilter: PrimaryFilters;
  primaryView: PrimaryView;
  primaryViewFilters: FilterMenuItemOrConfig[];
  primaryViewFilterLimit: PrimaryFilterLimits;
  filterMenuLimit: FilterMenuLimits;
  selectableFilters: SelectableFiltersMenuProp;
  sharedFilterSetId?: FilterSets;
  filterSet: FilterSets;
  trialNoResultsMessage?: JSX.Element;
  savedSetView: View;
}

export function Overview({
  title,
  viewType,
  primaryFilter,
  primaryView,
  primaryViewFilters,
  primaryViewFilterLimit,
  filterMenuLimit,
  selectableFilters,
  sharedFilterSetId = FilterSets.NONE,
  filterSet,
  trialNoResultsMessage,
  savedSetView,
}: OverviewProps) {
  const view = Views.OVERVIEW;
  const {
    templateColumns,
    templateRows,
    bigPlotColSpan,
    bigPlotRowSpan,
    gridItemMinHeight,
  } = useResponsivePageGridDefs(view);

  const primaryDataView: PrimaryDataView = useMemo(
    () => getPrimaryDataView(viewType),
    [viewType]
  );

  const dateRangeFilterId = SelectionCategories.DATE_RANGE;
  const smallPlotConfigs = useMemo(
    () => BaseSmallPlotConfigLookup[viewType],
    [viewType]
  );

  const sharesPlotConfigs = useMemo(
    () => BasePlotConfigLookup[viewType],
    [viewType]
  );

  const snapshotChartProps = useMemo(
    () => SnapshotChartPropsLookup[viewType],
    [viewType]
  );

  const overTimeChartProps = useMemo(
    () => OverTimeChartPropsLookup[viewType],
    [viewType]
  );

  const [isSnapshotState, setIsSnapshotState] = useState(true);
  const allFilters = Array.from(
    new Set([...selectableFilters.snapshot, ...selectableFilters.overtime])
  );

  const primaryEntity = getNestedEntity(viewType);
  const primaryFilters = useMemo(
    () => flatten(createSelectableFiltersMap(primaryViewFilters)),
    [primaryViewFilters]
  ) as SelectionCategories[];

  const selectableFiltersMap = createSelectableFiltersMap(allFilters);
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  const storedFilterSetArgs = {
    sharedSetId: sharedFilterSetId,
    tab: savedSetView,
    primaryEntitiesSync: true,
    limit: primaryViewFilterLimit,
    filterNames: primaryFilters,
    uniqueSetId: filterSet,
  };

  useStoredFilterSet(storedFilterSetArgs);

  useSelectionLists([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    ...FiltersUsedInTabs,
    SelectionCategories.SKILL,
    SelectionCategories.JOB_CATEGORY,
    SelectionCategories.ROLE_K50,
    SelectionCategories.ROLE_K500,
    SelectionCategories.KEYWORD,
    SelectionCategories.RICS_K400,
  ]);
  useViewFilters([
    ...primaryFilters,
    // date filters are excluded because they are passed in as "additionalNonActiveFilters"
    // so the download endpoint is only called with either snapshot or date range depending on which plot is downloaded
    ...selectableFiltersMap.filter(
      (f) =>
        f !== SelectionCategories.SNAPSHOT_DATE &&
        f !== SelectionCategories.DATE_RANGE
    ),
  ]);

  useTabMeta({
    savedSetView,
    view,
    viewType,
    limit: PrimaryFilterLimits.OVERVIEW,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });
  useViewFilterDefaults({
    view,
    viewType,
    presetView: sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
      ...Object.values(SubFilterNames),
    ],
    viewFilters: [
      LocalSelectionCategories.DATA_METRIC,
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    limit: PrimaryFilterLimits.OVERVIEW,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  });

  useDefaultLastMonth({
    view,
    viewType,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
    dateKey: SelectionCategories.DATE_RANGE,
  });

  useAdaptiveRoleTaxonomy({
    viewType,
    primaryFilters,
  });

  // grouped & subfilter never on snapshot
  // Over Time: subfilter & grouped is only on bottom 6, not allowed on top 6
  // useHiddenFiltersWithProvidedValues({
  //   [OtherFilterNames.GROUPED]: true,
  // });

  usePrimaryFilter(primaryFilter);

  const requireAtLeastOne = requireAtLeastOneFilterValueOf(primaryFilters);

  const additionalOperatorsBeforeQuery = pipe(
    requireAtLeastOne,
    filter((source: PlotAdditionalQueryParams) => {
      // Require date to be set
      // do not fire a request until date range is set to avoid cancelled request (can happen before last month has resolved)
      const isOvertime = (
        source.endpoint as CalculatedEndpoint
      ).endpointPath.includes('overtime');
      return !!source.additionalFilters.find(
        (filter) =>
          filter.id ===
          (isOvertime
            ? SelectionCategories.DATE_RANGE
            : SelectionCategories.SNAPSHOT_DATE)
      );
    })
  );
  const viewDefaultsForSmallPlots = provideBasePlotConfigDefaults({
    view,
    viewType: ViewTypes.SNAPSHOT,
    chartType: D3ChartNames.BarChartHorizontal,
    metaData: {
      requiredParams: [SelectionCategories.PRIMARY_FILTER],
      pageGroupName: 'compositions',
      primaryDataView: primaryDataView,
    },
    preFetchConfig: {
      viewType: ViewTypes.OVERTIME,
      chartType: D3ChartNames.LineChart,
    },
  });

  const {
    mappers: {
      currentConfigMappers: smallConfigMappers,
      preFetchConfigMappers: smallPreFetchConfigMappers,
    },
  } = useManyPreFetchPlotConfigProviders(
    smallPlotConfigs.map((sConfig) => {
      const { endpoint } = sConfig;
      return viewDefaultsForSmallPlots({
        ...sConfig,
        chartProps: snapshotChartProps[endpoint as EndpointSegment],
      });
    })
  );

  const viewDefaultsForPlots = provideBasePlotConfigDefaults({
    view,
    viewType: ViewTypes.SHARES_SNAPSHOT,
    chartType: D3ChartNames.StackedBarChartHorizontal,
    metaData: {
      requiredParams: [SelectionCategories.PRIMARY_FILTER],
      pageGroupName: 'compositions',
      primaryDataView: primaryDataView,
    },
    preFetchConfig: {
      viewType: ViewTypes.SHARES_OVERTIME,
      chartType: D3ChartNames.LineChart,
    },
  });

  const {
    mappers: { currentConfigMappers: configMappers, preFetchConfigMappers },
  } = useManyPreFetchPlotConfigProviders(
    sharesPlotConfigs.map((config) => {
      const { endpoint } = config;
      return viewDefaultsForPlots({
        ...config,
        endpoint: endpoint,
        chartProps: snapshotChartProps[endpoint as EndpointSegment],
      });
    })
  );

  const [showDiagnosticsMenu, setShowDiagnosticsMenu] = useState(false);

  useSingleOrMoreFilterState<SelectFilter<FilterList>[]>(
    primaryFilters,
    pipe(
      tap((filters) => {
        const numSelected = (filters as SelectFilter<FilterList>[]).reduce(
          (total, f) => {
            total = total + f.value.length;
            return total;
          },
          0
        );

        if (viewType === ViewTypes.COMPANY) {
          const companyFilter = (filters as SelectFilter<FilterList>[]).find(
            (item) => item.id === SelectionCategories.COMPANY
          );

          const numActiveCompanies = companyFilter?.value?.length || 0;

          setShowDiagnosticsMenu((previousState) => {
            const showMenu = numActiveCompanies > 0;

            if (showMenu !== previousState) {
              return showMenu;
            }

            return previousState;
          });
        }
        // TODO: uncomment below line when we refactor Grouped feature
        // setShowGroupedSwitch(numSelected == 1 ? true : false);
        if (numSelected > 1) {
          upsertFilter(OtherFilterNames.GROUPED, { value: true });
        }
      })
    )
  );

  useSingleOrMoreFilterState<SelectFilter<FilterItem<ValueItem>>>(
    LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME,
    pipe(
      tap((filter) => {
        const singleFilter = filter as SelectFilter<FilterItem<ValueItem>>;
        if (singleFilter?.value.id) {
          const isSnapshot = singleFilter.value.id == ViewTypes.SNAPSHOT;

          setIsSnapshotState(isSnapshot);

          const newViewType = (cond: boolean) =>
            cond ? ViewTypes.SNAPSHOT : ViewTypes.OVERTIME;

          const updatedChartType = (cond: boolean) =>
            cond ? D3ChartNames.BarChartHorizontal : D3ChartNames.LineChart;

          const sharesViewType = (cond: boolean) =>
            cond ? ViewTypes.SHARES_SNAPSHOT : ViewTypes.SHARES_OVERTIME;

          const updatedSharesChartType = (cond: boolean) =>
            cond
              ? D3ChartNames.StackedBarChartHorizontal
              : D3ChartNames.LineChart;

          const chartPropsConfigLookup = (cond: boolean) =>
            cond ? snapshotChartProps : overTimeChartProps;

          smallConfigMappers.forEach(
            ({ endpointSegment, updater, metaData }, i) => {
              const preFetchUpdater = smallPreFetchConfigMappers[i].updater;

              const updatedAdditionalFilters = (cond: boolean) =>
                cond
                  ? [
                      SelectionCategories.PRIMARY_FILTER,
                      SelectionCategories.SNAPSHOT_DATE,
                    ]
                  : [SelectionCategories.PRIMARY_FILTER, dateRangeFilterId];

              const updatedBrokenoutFilterIds = (cond: boolean) =>
                /* eslint-disable-next-line no-nested-ternary */
                cond
                  ? [SelectionCategories.PRIMARY_FILTER]
                  : metaData?.isGqlQuery
                    ? [SelectionCategories.PRIMARY_FILTER]
                    : [SelectionCategories.PRIMARY_FILTER, dateRangeFilterId];

              updater.next({
                viewType: newViewType(isSnapshot),
                chartType: updatedChartType(isSnapshot),
                chartProps:
                  chartPropsConfigLookup(isSnapshot)[
                    endpointSegment as EndpointSegment
                  ],

                additionalNonActiveFilters:
                  updatedAdditionalFilters(isSnapshot),
                brokenOutFilterIds: updatedBrokenoutFilterIds(isSnapshot),
              });

              preFetchUpdater.next({
                viewType: newViewType(!isSnapshot),
                chartType: updatedChartType(!isSnapshot),
                chartProps:
                  chartPropsConfigLookup(!isSnapshot)[
                    endpointSegment as EndpointSegment
                  ],
                additionalNonActiveFilters:
                  updatedAdditionalFilters(!isSnapshot),
                brokenOutFilterIds: updatedBrokenoutFilterIds(!isSnapshot),
              });
            }
          );

          configMappers.forEach(
            ({ endpointSegment, updater, subfilters, metaData }, i) => {
              const preFetchUpdater = preFetchConfigMappers[i].updater;

              const SHARED_OVERTIME_ADDITIONAL_BROKENOUT_FILTERS = [
                SelectionCategories.PRIMARY_FILTER,
                OtherFilterNames.SUBFILTER,
                OtherFilterNames.GROUPED,
                subfilters.filterName,
              ];
              const updatedAdditionalFilters = (cond: boolean) =>
                cond
                  ? [
                      SelectionCategories.PRIMARY_FILTER,
                      SelectionCategories.SNAPSHOT_DATE,
                    ]
                  : [
                      ...SHARED_OVERTIME_ADDITIONAL_BROKENOUT_FILTERS,
                      dateRangeFilterId,
                    ];

              const updatedBrokenoutFilterIds = (cond: boolean) =>
                /* eslint-disable-next-line no-nested-ternary */
                cond
                  ? [SelectionCategories.PRIMARY_FILTER]
                  : metaData?.isGqlQuery
                    ? [...SHARED_OVERTIME_ADDITIONAL_BROKENOUT_FILTERS]
                    : [
                        ...SHARED_OVERTIME_ADDITIONAL_BROKENOUT_FILTERS,
                        dateRangeFilterId,
                      ];

              updater.next({
                viewType: sharesViewType(isSnapshot),
                chartType: updatedSharesChartType(isSnapshot),
                chartProps:
                  chartPropsConfigLookup(isSnapshot)[
                    endpointSegment as EndpointSegment
                  ],
                additionalNonActiveFilters:
                  updatedAdditionalFilters(isSnapshot),
                brokenOutFilterIds: updatedBrokenoutFilterIds(isSnapshot),
              });

              preFetchUpdater.next({
                viewType: sharesViewType(!isSnapshot),
                chartType: updatedSharesChartType(!isSnapshot),
                chartProps:
                  chartPropsConfigLookup(!isSnapshot)[
                    endpointSegment as EndpointSegment
                  ],
                additionalNonActiveFilters:
                  updatedAdditionalFilters(!isSnapshot),
                brokenOutFilterIds: updatedBrokenoutFilterIds(!isSnapshot),
              });
            }
          );
        }
      })
    )
  );

  const selectableFiltersByTime = isSnapshotState
    ? selectableFilters.snapshot
    : selectableFilters.overtime;

  const filtersForMenu = [...selectableFiltersByTime];

  /** TODO: Using this until we have more efficient plot rendering */
  const globalLoading = useGlobalLoaderStatus();

  type SmallConfigMapper = Omit<
    (typeof smallConfigMappers)[0],
    'endpointSegment'
  > &
    TopConfigMapperBase;
  type ConfigMapper = Omit<(typeof configMappers)[0], 'endpointSegment'> &
    BottomConfigMapperBase;

  const {
    topPlots,
    bottomPlots,
    loading: compositionDataLoading,
    error: compositionDataError,
    isQueryReady: isCompositionQueryReady,
  } = useCompositionDataFetch<SmallConfigMapper, ConfigMapper>({
    view: primaryView,
    timeframe: isSnapshotState
      ? TimeFrameView.SNAPSHOT
      : TimeFrameView.OVERTIME,
    primaryFilters,
    topConfigMapper: smallConfigMappers as SmallConfigMapper[],
    bottomConfigMapper: configMappers as ConfigMapper[],
  });

  const [headcountRendered, setHeadcountRendered] = useState(false);
  const [roleRendered, setRoleRendered] = useState(false);
  const handleChartRendered = (name: string, rendered: boolean) => {
    if (['headcount', 'trends-headcount'].includes(name)) {
      setHeadcountRendered(rendered);
    }
    if (['role', 'trends-role'].includes(name)) setRoleRendered(rendered);
  };

  const [allChartsRendered, setAllChartsRendered] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setAllChartsRendered(true);
    }, 6000);
  }, []);

  useEffect(() => {
    if (headcountRendered && roleRendered) {
      setAllChartsRendered(true);
    }
  }, [headcountRendered, roleRendered]);

  useTrackPerformance({
    loading: compositionDataLoading,
    eventName: 'plot_page_performance',
  });

  const isLoading =
    compositionDataLoading ||
    globalLoading ||
    !allChartsRendered ||
    !isCompositionQueryReady;

  useSyncFiltersToSearchParamsPure({
    primaryFilters,
    syncToPrimaryEntities: true,
    isLoading,
  });

  return (
    <DashboardPage
      title={title}
      hideSelectionsMargins
      loading={isLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            showColors={!isSnapshotState}
            variant="companyChip"
            view={view}
            isPrimaryChip={true}
            min={1}
            limit={primaryViewFilterLimit}
            addButton={
              <Box className={TourClasses.TOUR_TRACKING_CLASS}>
                <PrimaryEntityPopoutTreeFilter
                  {...primaryEntity}
                  maxSelections={SHARED_SET_ENTITY_LIMIT}
                  minSelections={1}
                >
                  {AddEntityButtonText[primaryFilter]}
                </PrimaryEntityPopoutTreeFilter>
              </Box>
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={allFilters} // maximum filter names across snapshot and overtime to avoid re-renders and removing chips
            variant="filterChip"
            limit={filterMenuLimit}
            viewType={viewType}
            propsView={view}
            showGranularity={true}
            filtersToIgnore={
              isSnapshotState
                ? [SelectionCategories.DATE_RANGE]
                : [SelectionCategories.SNAPSHOT_DATE]
            }
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  filters={filtersForMenu}
                  limit={filterMenuLimit}
                  selectMenuOpenDefault
                  endDateDefaultFilterName={DefaultDates.DEFAULT_LAST_MONTH}
                  viewIdForDefault={`${view}_${viewType}`}
                  viewType={viewType}
                />
                <FilterSetSaveMenu view={savedSetView} />
              </>
            }
          />
        </Flex>

        <Box className={TourClasses.TOUR_VIEW_CLASS}>
          <TabsFilter
            initialValue={{
              id: ViewTypes.SNAPSHOT,
              label: startCase(ViewTypes.SNAPSHOT),
            }}
            filterName={LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME}
          ></TabsFilter>
        </Box>
      </FilterContainer>

      <Grid
        height="100%"
        templateRows={templateRows}
        templateColumns={templateColumns}
        gap={4}
        data-testid="plots-grid"
      >
        {topPlots.map((config, i) => {
          return (
            <GridItem
              key={i}
              minH={gridItemMinHeight}
              className={`overview-plot-top-${config.endpointSegment} plot${
                i + 1
              }`}
            >
              <DefaultCard
                cardConfig={{
                  header: config.name,
                  slim: true,
                  endpointSegment: config.endpointSegment,
                  view,
                  includeDiagnosticsModal:
                    showDiagnosticsMenu &&
                    config.metaData?.includeDiagnosticsModal,
                }}
                plotConfig={{
                  additionalNonActiveFilters: config.additionalNonActiveFilters,
                  brokenOutFilterIds: config.brokenOutFilterIds,
                  additionalOperatorsBeforeQuery:
                    additionalOperatorsBeforeQuery,
                  endpoint: config.endpointMapper,
                  requiredParams: config.metaData?.requiredParams,
                  chartTypeAndProps: config.plotConfigMapper,
                  dataProvider: config.dataProvider,
                  preFetchConfig: smallPreFetchConfigMappers[i],
                  isGqlQuery: config.metaData?.isGqlQuery,
                }}
                downloadConfig={{
                  endpoint: config.downloadEndpointMapper,
                  isGoRequest: config.metaData?.isGoRequest,
                }}
                {...(config?.data && { data: config.data })}
                loading={compositionDataLoading}
                error={compositionDataError}
                setHasRendered={handleChartRendered}
              />
            </GridItem>
          );
        })}
        {bottomPlots.map((config, i) => {
          return (
            <GridItem
              key={i}
              minH={gridItemMinHeight}
              rowSpan={bigPlotRowSpan}
              colSpan={bigPlotColSpan}
              className={`overview-plot-bottom-${
                config.endpointSegment
              } plot${i + 7}`}
            >
              <DefaultCard
                cardConfig={{
                  header: config.name,
                  endpointSegment: config.endpointSegment,
                  view,
                  viewType: isSnapshotState
                    ? [ViewTypes.SNAPSHOT, viewType]
                    : [ViewTypes.OVERTIME, viewType],
                }}
                plotConfig={{
                  additionalNonActiveFilters: config.additionalNonActiveFilters,
                  brokenOutFilterIds: config.brokenOutFilterIds,
                  additionalOperatorsBeforeQuery:
                    additionalOperatorsBeforeQuery,
                  endpoint: config.endpointMapper,
                  requiredParams: config.metaData?.requiredParams,
                  chartTypeAndProps: config.plotConfigMapper,
                  dataProvider: config.dataProvider,
                  preFetchConfig: preFetchConfigMappers[i],
                  isGqlQuery: config.metaData?.isGqlQuery,
                }}
                downloadConfig={{
                  endpoint: config.downloadEndpointMapper,
                  isGoRequest: config.metaData?.isGoRequest,
                  disabled: config.metaData?.disableDownload,
                }}
                subfilter={{
                  placementOverride: config.subfilters.placement,
                  subfiltersMap: config.subfilters.subfiltersMap,
                  defaultState: config.subfilters.default as Partial<
                    SelectFilter<FilterList<ValidValueTypes>>
                  >,
                  treeCtrl$: config.subfilters$ as Observable<SubfilterConfigs>,
                  showGrouped: false,
                  filterName: config.subfilters.filterName,
                  selectionLists: config.subfilters.selectionLists,
                }}
                {...(config?.data && { data: config.data })}
                loading={compositionDataLoading}
                error={compositionDataError}
                setHasRendered={handleChartRendered}
              />
            </GridItem>
          );
        })}
      </Grid>
    </DashboardPage>
  );
}

export default Overview;
