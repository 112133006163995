import React from 'react';
import { Views } from '@revelio/core';
import {
  AnyFilter,
  DateValSelectFilter,
  DefaultDates,
  FilterItem,
  FilterOrSubfilterName,
  ISelectFilter,
  OPSelectFilter,
  SelectionCategories,
  TreeItem,
} from '../../engine/filters.model';
import { DateRangeValue } from '../collection';

export enum WorkFlows {
  COMPANY_CLEANED = 'COMPANY_CLEANED',
  DATE_RANGE = 'DATE_RANGE',
  DATE_RANGE_FULL = 'DATE_RANGE_FULL',
  DATE = 'DATE',
  LAYOFF = 'LAYOFF',
  SCREENER = 'SCREENER',
  SAVED_FILTER_SET = 'SAVED_FILTER_SET',
  DEFAULT = 'DEFAULT',
  KEYWORD = 'KEYWORD',
}

export type TempSelections = {
  [key: string]: TreeItem;
};

export type KeywordSelectionItem = {
  label: string;
  value: string;
  __isNew__?: boolean;
};

export type KeywordSelections = {
  [key: string]: KeywordSelectionItem[];
};
export interface TempEmpTypes {
  [category: string]: FilterItem[];
}

export type TreeMethodHandle = {
  submit: (temp?: { [key: string]: TreeItem }) => void;
  handleClearSelections: () => void;
};

export type SelectedTreeRef = {
  filterName: FilterOrSubfilterName;
  value: TreeMethodHandle;
};

export type SelectedRef = SelectedTreeRef;

export type SubmitRefHandle = {
  [key: string]: SelectedRef;
};

export interface HandlerConfig {
  setId?: string;
  filterName?: SelectionCategories;
  selectValue?: ISelectFilter | null;
  activeFilters?: AnyFilter[];
  tempSelections?: { [key: string]: TreeItem };
  keywordSelections?: KeywordSelections;
  setKeywordSelections?: React.Dispatch<
    React.SetStateAction<KeywordSelections>
  >;
  submitRef?: React.MutableRefObject<SubmitRefHandle>;
  view: Views;
  endDateDefaultFilterName: DefaultDates | undefined;

  dateValue?: DateValSelectFilter;
  setDateValue: React.Dispatch<React.SetStateAction<DateValSelectFilter>>;

  opValue?: OPSelectFilter;
  setOpValue: React.Dispatch<React.SetStateAction<OPSelectFilter>>;

  startValue?: number;
  setStartValue: React.Dispatch<React.SetStateAction<number | undefined>>;

  endValue?: number;
  setEndValue: React.Dispatch<React.SetStateAction<number | undefined>>;

  companyValue?: string[];
  setCompanyValue: React.Dispatch<React.SetStateAction<string[]>>;

  dateRangeValue?: DateRangeValue;
  setDateRangeValue: React.Dispatch<DateRangeValue | undefined>;

  dateRangeFullValue?: DateRangeValue;
  setDateRangeFullValue: React.Dispatch<DateRangeValue | undefined>;

  selectedSnapshotDate?: Date;
  setSelectedSnapshotDate: React.Dispatch<
    React.SetStateAction<Date | undefined>
  >;

  fetchSubsidiaries?: any;
}
