import { StackedBarChartHorizontalData } from '@revelio/d3';
import { TransitionDataQuery, TransitionInfo } from '@revelio/data-access';

import {
  entityMeticLookup,
  generatePlotStackOthers,
  TransitionBarPlotName,
} from '@revelio/filtering';

const getMetricValues = (
  metric: TransitionInfo,
  group: 'Current' | 'Inflow' | 'Outflow'
): { count: number; share: number } => {
  switch (group) {
    case 'Current':
      return { count: metric.current || 0, share: metric.currentShare || 0 };
    case 'Inflow':
      return { count: metric.inflow || 0, share: metric.inflowShare || 0 };
    case 'Outflow':
      return { count: metric.outflow || 0, share: metric.outflowShare || 0 };
  }
};

interface StackedBarDataOptions {
  metrics: TransitionInfo[] | null | undefined;
  groupId: 'Current' | 'Inflow' | 'Outflow';
  shouldProcessTopSeven?: boolean;
  prioritizedIds?: number[]; // IDs that will be shown individually, rest go to "Other"
}

const createStackedBarData = ({
  metrics,
  groupId,
  shouldProcessTopSeven,
  prioritizedIds,
}: StackedBarDataOptions): {
  data: StackedBarChartHorizontalData;
  topSevenIds?: number[];
} => {
  const values =
    metrics
      ?.filter(
        (metric): metric is TransitionInfo =>
          !(
            metric?.shortName?.toLowerCase() === 'empty' ||
            metric?.longName?.toLowerCase() === 'empty'
          )
      )
      ?.map((metric) => {
        const { count, share } = getMetricValues(metric, groupId);
        return {
          id: metric.id,
          metadata: {
            shortName: metric.shortName,
            longName: metric.longName,
            count,
            share,
          },
          value: share,
        };
      }) || [];

  if (prioritizedIds) {
    // Use provided IDs to split between prioritized and other
    const prioritizedValues = prioritizedIds
      .map((id) => values.find((v) => v.id === id))
      .filter((v): v is NonNullable<typeof v> => !!v);

    const otherValues = values.filter(
      (v) => !prioritizedIds.includes(v.id as number)
    );

    return {
      data: {
        id: groupId,
        metadata: { shortName: groupId, longName: groupId },
        value: [...prioritizedValues, generatePlotStackOthers(otherValues)],
      },
    };
  }

  if (!shouldProcessTopSeven) {
    return {
      data: {
        id: groupId,
        metadata: { shortName: groupId, longName: groupId },
        value: values,
      },
    };
  }

  // For Current group, calculate top 7
  const sortedValues = [...values].sort(
    (a, b) => (b.value || 0) - (a.value || 0)
  );
  const prioritizedValues = sortedValues.slice(0, 7);
  const otherValues = sortedValues.slice(7);
  const topSevenIds = prioritizedValues.map((v) => v.id);

  return {
    data: {
      id: groupId,
      metadata: { shortName: groupId, longName: groupId },
      value: [...prioritizedValues, generatePlotStackOthers(otherValues)],
    },
    topSevenIds: topSevenIds as number[],
  };
};

export const transformStackedBarData = (
  data: TransitionDataQuery | undefined,
  inflow_or_outflow: 'inflow' | 'outflow',
  plotName: string
) => {
  const isInflow = inflow_or_outflow === 'inflow';
  const dataIndex = isInflow ? 0 : 1;
  const barPlotName = plotName.toLowerCase();
  const shouldProcessTopSeven = ['role', 'geography', 'industry'].includes(
    barPlotName
  );

  const metricName =
    entityMeticLookup[
      barPlotName === 'geography'
        ? 'geo'
        : (barPlotName as TransitionBarPlotName)
    ];

  const metrics = data?.transitions2D?.[dataIndex]?.metrics?.[metricName] as
    | TransitionInfo[]
    | null
    | undefined;
  const flowGroup = isInflow ? 'Inflow' : 'Outflow';

  const { data: currentData, topSevenIds } = createStackedBarData({
    metrics,
    groupId: 'Current',
    shouldProcessTopSeven,
  });

  // we use the topSevenIds when available to make sure current vs flow group have consistent entities shown
  const { data: flowData } = createStackedBarData({
    metrics,
    groupId: flowGroup,
    prioritizedIds: topSevenIds,
  });

  return [currentData, flowData];
};
