// Third-party libraries
import { useConst } from '@chakra-ui/react';
import { useEffectFn } from '@ngneat/effects-hooks';
import { distinctUntilArrayItemChanged, filterNil, select } from '@ngneat/elf';
import {
  getAllEntities,
  getEntity,
  selectEntity,
  selectMany,
} from '@ngneat/elf-entities';
import {
  useEffect$,
  useObservable,
  useUntilDestroyed,
} from '@ngneat/react-rxjs';
import {
  defer,
  delay as _delay,
  flatten,
  get,
  has,
  includes,
  isArray,
  isEmpty,
  isString,
  isUndefined,
  keys,
  mapValues,
  merge as _merge,
  pick,
  set,
  some,
  unset,
} from 'lodash';
import objectHash from 'object-hash';
import { stringify } from 'query-string';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLifecycles, useMount, useUnmount } from 'react-use';
import {
  BehaviorSubject,
  EMPTY,
  MonoTypeOperatorFunction,
  Observable,
  OperatorFunction,
  Subject,
  combineLatest,
  from,
  iif,
  isObservable,
  merge,
  of,
  pipe,
} from 'rxjs';
import {
  auditTime,
  catchError,
  debounceTime,
  distinct,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  mergeScan,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  DecodedValueMap,
  JsonParam,
  StringParam,
  encodeQueryParams,
} from 'serialize-query-params';
import { SetOptional } from 'type-fest';
import { Client, GraphQLRequest, useClient } from 'urql';

// Internal nx libraries
import {
  MetadataKey,
  authStore,
  getUserMetadataValue,
  useGetLoggedInUser,
} from '@revelio/auth';
import {
  _globalPageLoadingStatus,
  addLoadingStatus$,
  globalMaxWaitEnabled,
  globalPageLoadingStatus,
  overrideLoadingState,
  overrideLoadingStatus$,
  removeLoadingStatus,
  removeLoadingStatus$,
  toggleStatusOnGlobalLoaderOrSkipOne,
  turnOffGlobalLoader,
  Views,
} from '@revelio/core';
import { CustomRoleTaxonomySelection, User } from '@revelio/data-access';

// Relative imports
import { getPlotEndpointConfig } from '../data-api/data-api';
import {
  CalculatedEndpoint,
  DataFetcher,
  EndpointSegment,
  ViewTypes,
} from '../data-api/data-api.model';
import {
  MaxDateRangeToDefault,
  getStartDateConst,
} from '../filter-components/collection';
import { getDataView } from '../utils';
import {
  ALL_ROLE_FILTERS,
  API_ROOT,
  GO_API_ROOT,
  PYTHON_COMPANY_SELECTION_ID,
} from './filters.constants';
import {
  filterStore,
  getDefaultLastMonth,
  getLastStartDate,
  selectDefaultLastMonth,
  selectLastStartDate,
  selectionListEntitiesRef,
} from './filters.core';
import {
  getViewDefaultFilters,
  setSearchParamFiltersPure,
} from './filters.deepLinks';
import { ALL_SUPPORTED_FILTERS } from './filters.deepLinks.model';
import { getViewDefault, getViewDefaultMonth } from './filters.defaults';
import { viewFiltersDefaultsDataSource } from './filters.defaults.core';
import { getGqlData } from './filters.gql';
import {
  BuildDataRequest,
  DefaultDates,
  EnumeratedFilters,
  Filter,
  FilterItem,
  FilterName,
  FilterNameIds,
  FilterStoreRootProps,
  FilterTypes,
  GetViewDefaultMonth,
  GetViewFilterDefaults,
  IFilterLimit,
  InternalBuildDataRequest,
  LocalSelectionCategories,
  OtherFilterNames,
  ProvideAdditionalOperators,
  ProvidedFilterOrRawValueMap,
  RangeFilter,
  RequestMethod,
  RoleSelectionCategories,
  SelectFilter,
  SelectableCategories,
  SelectionCategories,
  SelectionList,
  SelectionListIdNames,
  SelectionListItem,
  SelectionListItems,
  SerializedFiltersForQuery,
  ValidValueTypes,
} from './filters.model';
import {
  addActiveFiltersIds,
  clearRoleCacheFilters,
  deleteFilter,
  deleteFilters,
  doesFilterHaveState,
  fetchFilterSelections,
  getActiveFiltersState,
  getFilterSelectionLists,
  getManyFiltersState,
  getSingleFilterState,
  persistFilterStore,
  queryEndpoint,
  removeActiveFiltersIds,
  removeSelectFilterValue,
  requireAtLeastOneFilterValueOf,
  selectionListDataSource,
  serializeFiltersForQuery,
  setActiveFiltersIds,
  upsertFilter,
  upsertFilterStoreRootProps,
  upsertFiltersWithProvidedValue,
  usePrimaryFilter,
} from './filters.repository';
import { buildFilters } from './filters.serialize';
import { useRoleTaxonomySetting } from './role-taxonomy/filters.role-taxonomy';
import { getRoleTaxonomyValueFromDeepLink } from './role-taxonomy/get-adaptive-role-taxonomy-id';
import {
  SelectionListParentMap,
  fetchCustomRoleTaxonomy,
  fetchGqlSelectionList,
  setRoleSelectionListToCustomTaxonomy,
} from './selection-lists.gql';

/**
 * Hook that optionally returns observable state of the active filters that are determined by the passed in set. As well as does cleanup on component/view destroy.
 *
 * @param viewFilters - array of named filter strings/enum to set as active filters for this view
 */
export function useViewFilters(
  viewFilters: (FilterNameIds | FilterNameIds[])[],
  returnActiveFilterState = false
) {
  const viewFiltersFlattened = flatten(viewFilters);
  useLifecycles(
    () => setActiveFiltersIds(viewFiltersFlattened),
    () => removeActiveFiltersIds(viewFiltersFlattened)
  );
  const [filter] = useActiveFiltersV2();
  if (returnActiveFilterState) {
    return filter;
  }

  return undefined;
}

/**
 * Set any defined or custom property on the root of the FilterStore to be used/referenced in the passed View. When the component unmounts, it returns the state props to what theire value was before mount.
 *
 */
export function useFilterStoreSettingsForView(
  view: Views,
  propsMap: FilterStoreRootProps
) {
  const originalState = useConst(
    filterStore.query((state) =>
      pick<FilterStoreRootProps>(state, keys(propsMap))
    )
  );
  useEffect(() => {
    upsertFilterStoreRootProps(propsMap);
  }, [propsMap, view]);

  useUnmount(() => upsertFilterStoreRootProps(originalState));
}

export function useHiddenFiltersWithProvidedValues(
  staticFilters: ProvidedFilterOrRawValueMap,
  setActive = false
) {
  useMount(() => {
    upsertFiltersWithProvidedValue(staticFilters, setActive);
  });
}

export function useActiveFilters() {
  const { untilDestroyed } = useUntilDestroyed();

  return useObservable(
    getActiveFiltersState().pipe(
      untilDestroyed(),
      distinctUntilArrayItemChanged(),
      shareReplay({ refCount: true })
    )
  );
}

export function useActiveFiltersV2<R = Filter[]>(
  additionalOperators = pipe() as OperatorFunction<Filter[], R>
): [R, any] {
  const { untilDestroyed } = useUntilDestroyed();

  const [data, setData] = useState<R>(undefined as any);
  const [error, seterror] = useState(undefined as any);

  useEffect$(
    () =>
      getActiveFiltersState().pipe(
        untilDestroyed(),
        distinctUntilArrayItemChanged<Filter>(),
        shareReplay({ refCount: true }),
        additionalOperators,
        tap((data) => {
          setData(data);
          seterror(undefined);
        }),
        catchError((e) => {
          seterror(e);
          return e;
        })
      ),
    []
  );

  return [data, error];
}

export function useSingleOrMoreFilterState<T = Filter, R = T>(
  filterNames: FilterNameIds | FilterNameIds[],
  additionalOperators: ProvideAdditionalOperators<any, any> = (o) => o,
  orderByFilterNames?: boolean
): [R, any] {
  const { untilDestroyed } = useUntilDestroyed();

  const [data, setData] = useState<R>(undefined as any);
  const [error, seterror] = useState(undefined as any);

  useEffect$(
    () =>
      iif(
        () => isArray(filterNames),
        getManyFiltersState(filterNames as any[], orderByFilterNames),
        getSingleFilterState<T>(filterNames as any)
      ).pipe(
        untilDestroyed(),
        shareReplay({ refCount: true }),
        additionalOperators,
        tap<R>((data) => {
          setData(data);
          seterror(undefined);
        }),
        catchError((e) => {
          seterror(e);
          return e;
        })
      ),
    []
  );

  return [data, error];
}

/**
 *
 * @deprecated need to replace any use and then remove, don't use this
 */
export function useSelectionList(selectionListToGet: SelectionListIdNames) {
  useEffectFn(getFilterSelectionLists)([selectionListToGet]);
  const [
    {
      selectionLists: [selectionList],
    },
  ] = useObservable(
    selectionListDataSource.data$({ key: [selectionListToGet] })
  );
  return selectionList;
}

export const DEFAULT_SELECTION_LIST_PARENT_MAP: SelectionListParentMap = {
  [SelectionCategories.KEYWORD]: SelectionCategories.KEYWORDS_CATEGORY,
  [SelectionCategories.COMPANY_REPORT]: SelectionCategories.INDUSTRY,
  [SelectionCategories.COMPANY]: SelectionCategories.INDUSTRY,
  [SelectionCategories.RICS_K50]: SelectionCategories.RICS_K10,
  [SelectionCategories.RICS_K400]: SelectionCategories.RICS_K50,
  [PYTHON_COMPANY_SELECTION_ID as SelectionCategories]:
    SelectionCategories.INDUSTRY,
  [SelectionCategories.ROLE_K50]: SelectionCategories.JOB_CATEGORY,
  [SelectionCategories.ROLE_K150]: SelectionCategories.JOB_CATEGORY,
  [SelectionCategories.ROLE_K500]: SelectionCategories.ROLE_K150,
  [SelectionCategories.ROLE_K1500]: SelectionCategories.ROLE_K150,
  [SelectionCategories.COUNTRY]: SelectionCategories.REGION,
  [SelectionCategories.METRO_AREA]: SelectionCategories.COUNTRY,
  [SelectionCategories.SKILL_MAPPED]: SelectionCategories.SKILL_K700,
  [SelectionCategories.SKILL_K25]: SelectionCategories.SKILL_MAPPED,
  [SelectionCategories.SKILL_K50]: SelectionCategories.SKILL_K25,
  [SelectionCategories.SKILL_K75]: SelectionCategories.SKILL_K50,
  [SelectionCategories.SKILL_K700]: SelectionCategories.SKILL_K75,
  [SelectionCategories.SKILL_K2500]: SelectionCategories.SKILL_K700,
  [SelectionCategories.SKILL_K3000]: SelectionCategories.SKILL_K700,

  [LocalSelectionCategories.PROVIDER]: LocalSelectionCategories.PROVIDER,
};
export function useSelectionLists(
  selectionListsToGet: SelectionListIdNames[],
  selectionListParentMap: SelectionListParentMap = DEFAULT_SELECTION_LIST_PARENT_MAP,
  additionalOperatorsBefore: (
    obs: Observable<SelectionListIdNames[]>
  ) => Observable<SelectionListIdNames[]> = pipe(),
  additionalOperatorsAfter: (obs: Observable<any>) => Observable<any> = pipe()
) {
  const gqlClient = useClient();
  const { untilDestroyed } = useUntilDestroyed();
  const [selectionLists, setSelectionLists] = useState<
    SelectionList<ValidValueTypes>[]
  >([]);
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const roleTaxonomyValue = getRoleTaxonomyValueFromDeepLink(searchParams);
    if (roleTaxonomyValue) {
      clearRoleCacheFilters();
      ALL_ROLE_FILTERS.forEach((roleFilter) => {
        setRoleSelectionListToCustomTaxonomy({
          gqlClient,
          selectionListNeeded: roleFilter,
          roleTaxonomyId: roleTaxonomyValue,
        });
      });
    }
    // only want this to trigger on initial load of deeplink
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect$(() =>
    combineLatest({
      init: persistFilterStore.initialized$,
      user: authStore.pipe(
        select((state) => state.user),
        filterNil()
      ),
      customTaxonomy: filterStore.pipe(
        selectEntity(OtherFilterNames.ROLE_TAXONOMY)
      ),
    }).pipe(
      switchMap(({ user, customTaxonomy }) =>
        from(selectionListsToGet).pipe(
          map((selectionListKey) => ({
            user,
            selectionListKey,
            customTaxonomy,
          }))
        )
      ),
      mergeMap(({ user, selectionListKey, customTaxonomy }) => {
        const hasCustomTaxonomy = !!customTaxonomy;
        const isLiveUser = user.live as boolean;
        const isRevelioUser = user.email?.endsWith('@reveliolabs.com');
        const restrictedCompanies = getUserMetadataValue(
          MetadataKey.LimitedCompanies,
          user
        );
        const isCompanyOrIndustryList = includes(
          [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
          selectionListKey
        );

        const isRoleKey = includes(
          ALL_ROLE_FILTERS,
          selectionListKey as SelectionCategories
        );
        if (isCustomRoleTaxonomyEnabled && isRoleKey) {
          const roleSelectionList = filterStore.query(
            getEntity(SelectionCategories.JOB_CATEGORY, {
              ref: selectionListEntitiesRef,
            })
          );
          if (!hasCustomTaxonomy && roleSelectionList?.roleTaxonomyId) {
            clearRoleCacheFilters();
          }

          if (hasCustomTaxonomy && !roleSelectionList?.roleTaxonomyId) {
            clearRoleCacheFilters();
          }
        }

        if (
          includes(Object.values(LocalSelectionCategories), selectionListKey) ||
          (!isLiveUser && restrictedCompanies && isCompanyOrIndustryList)
        ) {
          return fetchFilterSelections(selectionListKey, {
            isLiveUser,
            restrictedCompanies: isCompanyOrIndustryList
              ? restrictedCompanies
              : undefined,
            isRevelioUser,
          });
        }

        if (
          isCustomRoleTaxonomyEnabled &&
          hasCustomTaxonomy &&
          isRoleKey &&
          customTaxonomy
        ) {
          return fetchCustomRoleTaxonomy({
            gqlClient,
            selectionListNeeded: selectionListKey as RoleSelectionCategories,
            roleTaxonomyId:
              customTaxonomy?.value as FilterItem<CustomRoleTaxonomySelection>,
          });
        }

        return fetchGqlSelectionList(gqlClient, selectionListKey);
      })
    )
  );

  useEffect$(() => {
    return getSelectionListFromStore({
      selectionListsToGet,
      selectionListParentMap,
      selectionListCallback: (selectionLists) => {
        setSelectionLists(selectionLists);
      },
      additionalOperatorsBefore,
      additionalOperatorsAfter,
      untilDestroyed,
    });
  });

  return selectionLists;
}

export const validateSelectionLists = (
  lists: SelectionList[]
): SelectionListItems[] =>
  lists.map(
    (list): SelectionListItems => ({
      id: list.id,
      value: list.value.map(
        (item): SelectionListItem => ({ ...item, id: `${item.id}` })
      ),
    })
  );

export const useSelectionListsValidated = (
  selectionListIds: SelectionCategories[]
) => {
  const selectionLists = useSelectionLists(selectionListIds);
  return useMemo(() => {
    const selectionListsValidated = validateSelectionLists(selectionLists);
    const selectionListsSorted = selectionListsValidated.map(
      (list): SelectionListItems => {
        switch (list.id) {
          case SelectionCategories.RICS_K10:
          case SelectionCategories.RICS_K50:
            return {
              ...list,
              value: list.value.sort((_a, b) => {
                if (b.shortName === 'Other') return -1;
                else return 0;
              }),
            };
          default:
            return list;
        }
      }
    );

    return selectionListsSorted;
  }, [selectionLists]);
};

export const addParentToSelectionLists = ({
  selectionLists,
  selectionListParentMap = DEFAULT_SELECTION_LIST_PARENT_MAP,
}: {
  selectionLists: SelectionList[];
  selectionListParentMap?: SelectionListParentMap;
}) =>
  selectionLists.map((list: SelectionList) => {
    const parent = selectionListParentMap[list.id as SelectionListIdNames];
    return parent ? { ...list, parent } : list;
  });

function getSelectionListFromStore({
  selectionListsToGet,
  selectionListCallback,
  additionalOperatorsBefore = pipe(),
  additionalOperatorsAfter = pipe(),
  untilDestroyed = tap,
  selectionListParentMap = DEFAULT_SELECTION_LIST_PARENT_MAP,
}: {
  selectionListsToGet: SelectionListIdNames[];
  selectionListCallback: (
    selectionLists: SelectionList<ValidValueTypes>[]
  ) => void;
  additionalOperatorsBefore?: (
    obs: Observable<SelectionListIdNames[]>
  ) => Observable<SelectionListIdNames[]>;
  additionalOperatorsAfter?: (obs: Observable<any>) => Observable<any>;
  untilDestroyed?: <T>() => MonoTypeOperatorFunction<T>;
  selectionListParentMap?: SelectionListParentMap;
}) {
  const killItTriger = new Subject<boolean>();
  return persistFilterStore.initialized$.pipe(
    switchMap(() => of(selectionListsToGet)),
    untilDestroyed(),
    additionalOperatorsBefore,
    switchMap(() => filterStore.asObservable()),
    selectMany(selectionListsToGet, { ref: selectionListEntitiesRef }),
    filter((maybeList) => {
      const hardCodedSelectionLists: SelectionListIdNames[] = [
        LocalSelectionCategories.PROVIDER,
      ];

      const gotThemAll =
        selectionListsToGet.filter(
          (id) => !hardCodedSelectionLists.includes(id)
        ).length == maybeList?.length;

      if (gotThemAll) {
        killItTriger.next(true);
      }
      return gotThemAll;
    }),
    distinctUntilChanged(),
    map((selectionLists) =>
      addParentToSelectionLists({ selectionLists, selectionListParentMap })
    ),
    tap((listsToSet) => selectionListCallback(listsToSet)),
    (source) => additionalOperatorsAfter(source),
    takeUntil(killItTriger.pipe(auditTime(0)))
  );
}

/**
 *
 * @deprecated only leaving until it's confirmed we don't need old backend lists anymore
 */
export function useMultipleSelectionLists(
  selectionListsToGet: SelectionListIdNames[],
  additionalOperatorsBefore: (
    obs: Observable<SelectionListIdNames[]>
  ) => Observable<SelectionListIdNames[]> = pipe(),
  additionalOperatorsAfter: (obs: Observable<any>) => Observable<any> = pipe()
) {
  useEffectFn(getFilterSelectionLists)(selectionListsToGet);

  const { untilDestroyed } = useUntilDestroyed();
  const trigger = useRef(new Subject<SelectionListIdNames[]>());
  trigger.current.next(selectionListsToGet);
  const [selectionLists, setSelectionLists] = useState<
    SelectionList<ValidValueTypes>[]
  >([]);

  useEffect$(() => {
    return getSelectionListFromStore({
      selectionListsToGet,
      selectionListParentMap: DEFAULT_SELECTION_LIST_PARENT_MAP,
      selectionListCallback: (selectionLists) => {
        setSelectionLists(selectionLists);
      },
      additionalOperatorsBefore,
      additionalOperatorsAfter,
      untilDestroyed,
    });
  });

  return selectionLists;
}

export function useViewFilterDefaults(
  viewFilterDefaultsNeeded: GetViewFilterDefaults
) {
  const {
    view,
    viewType,
    viewFilters,
    onlyConsiderTheseFiltersToTriggerDefaults,
    considerPresetFiltersToTriggerDefaults,
    presetView,
    limit,
    deepLinkLimit,
    syncPrimaryEntities = false,
    dateKey = SelectionCategories.DATE_RANGE_FULL,
    primaryFilters,
  } = viewFilterDefaultsNeeded;

  const viewIdForDefault = `${view}${viewType ? `_${viewType}` : ''}`;

  const [searchParams] = useSearchParams();

  const { loggedInUser } = useGetLoggedInUser();

  const navigate = useNavigate();

  const allFiltersSelectionLists = useSelectionLists([
    ...ALL_SUPPORTED_FILTERS.filter(
      (id) => id !== LocalSelectionCategories.PROVIDER
    ),
  ]);

  useEffect(() => {
    setSearchParamFiltersPure({
      searchParamFilters: searchParams,
      view,
      // we pass all selection lists because deeplink might include filters from other pages/selectionLists
      selectionLists: allFiltersSelectionLists,
      loggedInUser,
      limits: {
        [LocalSelectionCategories.PRIMARY_ENTITIES]:
          deepLinkLimit || limit || 6,
      },
      primaryFilters,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFiltersSelectionLists, navigate, loggedInUser]);

  useEffect$(() => {
    if (searchParams.toString() !== '') {
      return of([]);
    }

    return viewFiltersDefaultsDataSource.data$({ key: viewIdForDefault }).pipe(
      tap(({ viewFilterDefault }) => {
        const meta = viewFilterDefault?.meta;
        const filterIdsRequiringDefaults =
          onlyConsiderTheseFiltersToTriggerDefaults || viewFilters;
        const currentFilterState = filterStore.query(getAllEntities());
        const alreadySetFiltersRequiringDefaults = currentFilterState.filter(
          (fS) => filterIdsRequiringDefaults.includes(fS.id)
        );

        const defaultFiltersAlreadySet =
          alreadySetFiltersRequiringDefaults.length ===
          filterIdsRequiringDefaults.length;

        if (viewFilterDefault?.value && !defaultFiltersAlreadySet) {
          const filterIdsRequiringDefaultsNotSet =
            filterIdsRequiringDefaults.filter(
              (filterId) =>
                !alreadySetFiltersRequiringDefaults.find(
                  (fS) => fS.id === filterId
                )
            );
          const filtersToSetAsDefault = pick(
            viewFilterDefault.value,
            filterIdsRequiringDefaultsNotSet
          );

          const toInsertFiltersArray = buildFilters(
            filtersToSetAsDefault,
            meta
          );

          const baseViewDefaultsMap = getViewDefaultFilters({
            view,
            user: authStore.getValue().user as User,
          });

          const toInsertObject = (toInsertFiltersArray as Filter[]).reduce(
            (
              object: { [key in FilterName]?: Partial<Filter> },
              filter: Filter
            ) => {
              object[filter.id] = filter;

              if (syncPrimaryEntities) {
                if (
                  has(object, LocalSelectionCategories.PRIMARY_ENTITIES) &&
                  Array.isArray(
                    object[LocalSelectionCategories.PRIMARY_ENTITIES]?.value
                  ) &&
                  Array.isArray(filter.value)
                ) {
                  set(
                    object,
                    `${LocalSelectionCategories.PRIMARY_ENTITIES}.value`,
                    [
                      ...(object[LocalSelectionCategories.PRIMARY_ENTITIES]
                        ?.value || []),
                      ...filter.value,
                    ]
                  );
                } else {
                  set(object, `${LocalSelectionCategories.PRIMARY_ENTITIES}`, {
                    id: LocalSelectionCategories.PRIMARY_ENTITIES,
                    value: filter.value,
                  });
                }
              }

              return object;
            },
            // set all the plot sub filters depending on the view's defaults
            {
              ...baseViewDefaultsMap,
            }
          );

          const primaryViewFilters: any = {};

          if (
            onlyConsiderTheseFiltersToTriggerDefaults?.includes(
              LocalSelectionCategories.PRIMARY_ENTITIES
            )
          ) {
            if (limit) {
              const primaryEntities =
                toInsertObject[LocalSelectionCategories.PRIMARY_ENTITIES];

              const primaryEntityValues = get(primaryEntities, 'value', []);

              if (Array.isArray(primaryEntityValues)) {
                primaryEntityValues.some((ent, index: number) => {
                  if (index == limit) return true;

                  const selectionListId: SelectableCategories = get(
                    ent,
                    'selectionListId',
                    ''
                  );

                  if (has(primaryViewFilters, selectionListId)) {
                    primaryViewFilters[selectionListId].push(ent);
                  } else {
                    primaryViewFilters[selectionListId] = [ent];
                  }

                  return false;
                });
              }

              const builtFilters = buildFilters(primaryViewFilters);

              if (builtFilters?.length > 0) {
                const reducedFilters = builtFilters.reduce((acc, cur) => {
                  return { ...acc, [cur.id]: cur };
                }, {});

                _merge(toInsertObject, reducedFilters);
              }
            }
          }

          // TODO: uncomment for GQL setting default date filter
          const dateRangeFilter = currentFilterState.filter((fS) => {
            return (
              fS.id === SelectionCategories.DATE_RANGE ||
              fS.id === SelectionCategories.DATE_RANGE_FULL
            );
          }) as [RangeFilter] | [];
          const isDateFilterAlreadySet =
            dateRangeFilter.length &&
            (dateRangeFilter[0].value.startDate ||
              dateRangeFilter[0].value.endDate);
          const viewStartDate = getStartDateConst(view);
          const useViewsMaxDateRangeConditions = [
            view === Views.POSTING &&
              (!isDateFilterAlreadySet ||
                isDateFilterAlreadySet == viewStartDate),
            view === Views.COMPENSATION &&
              (!isDateFilterAlreadySet ||
                isDateFilterAlreadySet == viewStartDate),
            view === Views.SENTIMENT && !isDateFilterAlreadySet,
          ];

          if (some(useViewsMaxDateRangeConditions, Boolean)) {
            const endDate =
              dateKey == SelectionCategories.DATE_RANGE
                ? filterStore.query(getDefaultLastMonth)
                : filterStore.query(getLastStartDate);

            // only set date range for postings when endDate is present
            if (view !== Views.POSTING || endDate) {
              toInsertObject[dateKey] = {
                type: FilterTypes.DATE_RANGE,
                isMaximumRange: true,
                value: {
                  startDate: viewStartDate,
                  endDate,
                },
              } as RangeFilter;
            }
          }

          // TODO: Temp for Company Skill
          const companyFilter = get(
            toInsertObject,
            SelectionCategories.COMPANY
          );
          if (
            view == Views.SKILL &&
            viewType == ViewTypes.COMPANY &&
            companyFilter
          ) {
            const value = Array.isArray(companyFilter?.value)
              ? companyFilter?.value?.map((ent: any) => {
                  return {
                    ...ent,
                    selectionListId: PYTHON_COMPANY_SELECTION_ID,
                  };
                })
              : {};

            toInsertObject[SelectionCategories.COMPANY] = {
              ...companyFilter,
              selectionListId: PYTHON_COMPANY_SELECTION_ID,
              value,
            } as any;
          }

          defer(() => {
            upsertFiltersWithProvidedValue(toInsertObject);
          });
        }
      })
    );
  });

  useMemo(() => {
    getViewDefault(viewFilterDefaultsNeeded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    view,
    viewType,
    viewFilters,
    onlyConsiderTheseFiltersToTriggerDefaults,
    considerPresetFiltersToTriggerDefaults,
    presetView,
  ]);
}

export function useDefaultLastMonth(params: GetViewDefaultMonth) {
  const defaultDateKey =
    params.dateType == DefaultDates.LAST_START_DATE
      ? SelectionCategories.DATE_RANGE_FULL
      : SelectionCategories.DATE_RANGE;

  const { dateKey = defaultDateKey } = params;

  const currentLastMonth = filterStore.query(getDefaultLastMonth);
  const currentLastStartDate = filterStore.query(getLastStartDate);
  if (!currentLastMonth || !currentLastStartDate) {
    getViewDefaultMonth(params);
  }

  const selectSelectedLastMonth =
    params.dateType == DefaultDates.DEFAULT_LAST_MONTH
      ? selectDefaultLastMonth
      : selectLastStartDate;

  useEffect$(
    () =>
      filterStore.pipe(
        /** TODO: This is needed to prevent stale emission of dates (even though it feels so dirty to do this)
         * https://ngneat.github.io/elf/docs/troubleshooting/stale-emission/ */
        auditTime(0),
        selectSelectedLastMonth(),
        distinctUntilChanged(),
        tap((lastMonth) => {
          const missingSnapshotOnOverview =
            (params.view === Views.OVERVIEW ||
              params.view === Views.SENTIMENT) &&
            !doesFilterHaveState(SelectionCategories.SNAPSHOT_DATE);

          const dateRangeFullMissingStartOrEnd = (() => {
            if (dateKey !== SelectionCategories.DATE_RANGE_FULL) return false;

            const dateRangeFull = filterStore.query(getEntity(dateKey)) as
              | RangeFilter
              | undefined;
            const startDate = dateRangeFull?.value?.startDate;
            const endDate = dateRangeFull?.value?.endDate;

            return !startDate || !endDate;
          })();

          if (
            lastMonth &&
            (!doesFilterHaveState(dateKey) || // this might be set already from deep link filter query params
              missingSnapshotOnOverview ||
              dateRangeFullMissingStartOrEnd) // date range might already be set from a different page but we also need snapshot date for composition gql query
          ) {
            const toInsert = MaxDateRangeToDefault(
              params.view,
              params.viewType,
              lastMonth,
              dateKey
            );

            upsertFiltersWithProvidedValue(toInsert, false);
          }
        })
      ),
    [selectSelectedLastMonth]
  );
}

export function useFilterLimits(limits: IFilterLimit[], useLimit = true) {
  useLifecycles(() => {
    if (!useLimit) return;

    limits.forEach((limitDef) => {
      const primaryEntities = queryFilterEngine(
        LocalSelectionCategories.PRIMARY_ENTITIES
      );

      const primaryEntityValues: FilterItem[] = get(
        primaryEntities,
        'value',
        []
      );

      const primaryFilterNames = limitDef.filterNames;

      const partitionedFilters: any = primaryFilterNames.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: { value: [] },
        };
      }, {});

      let limitRemaining = limitDef.limit;

      primaryEntityValues?.forEach((entity) => {
        if (limitRemaining <= 0) {
          return;
        }

        const selectionListId = get(entity, 'selectionListId');

        if (selectionListId) {
          partitionedFilters[selectionListId]?.value.push({
            ...entity,
            isActive: true,
          });
        }
        limitRemaining--;
      });

      Object.entries(partitionedFilters).forEach(([key, { value }]: any) => {
        if (value.length == 0) {
          deleteFilter(key);
          return;
        }

        upsertFilter(key, {
          id: key,
          label: key,
          type: 'SELECT',
          isMulti: true,
          selectionListId: key,
          value,
        });
      });
    });
  });
}

export function queryFilterEngine<
  T =
    | (SelectionCategories | OtherFilterNames)
    | (SelectionCategories | OtherFilterNames)[],
  R = Filter | Filter[],
>(filterNameOrNames?: T) {
  const isMany = isArray(filterNameOrNames);
  const queryFn = isMany ? getAllEntities() : getEntity(filterNameOrNames);
  const filterState = filterStore.query(queryFn);
  if (isMany && filterNameOrNames) {
    return (filterState as Filter[]).filter((fS) =>
      filterNameOrNames.includes(fS.id)
    );
  }

  return filterState as R;
}

export function useGlobalLoader(initialState = true, maxWaitTime = 6000) {
  const [isLoading, setIsLoading] = useState<boolean>(initialState);
  const backupReset = useRef<number>();
  useEffect$(() => {
    _globalPageLoadingStatus.next({});
    return globalPageLoadingStatus.pipe(
      // skip(1),
      filter((loadingLookup) => {
        if (loadingLookup.skip) {
          setIsLoading(false);
          return false;
        }
        return true;
      }),
      tap((loadingLookup: { [key: string]: boolean }) => {
        const number = Object.keys(loadingLookup).length;
        // non-zero signals to show the global loader, zero value or the max wait time has been reached means remove it as all http calls are settled
        const areWeLoading = number > 0 && !loadingLookup.maxWaitTimeReached;
        clearTimeout(backupReset.current);
        if (areWeLoading) {
          backupReset.current = setTimeout(() => {
            _globalPageLoadingStatus.next({
              areWeLoading: false,
              maxWaitTimeReached: true,
            });
          }, maxWaitTime) as unknown as number;
        }

        _delay(setIsLoading, areWeLoading ? 0 : 1000, areWeLoading);
      })
    );
  }, []);

  return isLoading;
}

export function useGlobalLoaderV2(initialState = true, maxWaitTime = 12000) {
  const [isLoading, setIsLoading] = useState<boolean>(initialState);
  const backupReset = useRef<number>();
  useEffect$(() => {
    return merge(
      addLoadingStatus$,
      removeLoadingStatus$,
      overrideLoadingStatus$
    ).pipe(
      mergeScan(
        (acc, next) => {
          let result = acc;
          if (next.replace) {
            return of(next.replace);
          }

          if (next.add) {
            result = { ...result, [next.add]: true };
          }
          if (next.remove) {
            const paths = isString(next.remove) ? [next.remove] : next.remove;
            paths.forEach((p) => unset(result, p));
            result = { ...result };
          }

          return of(result);
        },
        initialState ? { tabChange: true } : ({} as { [key: string]: boolean })
      ),
      filter((loadingLookup) => {
        const disableIt = turnOffGlobalLoader.getValue();
        if (disableIt && isLoading) {
          setIsLoading(false);
        }
        return !disableIt;
      }),
      tap((loadingLookup: { [key: string]: boolean }) => {
        const keys = Object.keys(loadingLookup);
        const number = keys.length;
        // non-zero signals to show the global loader, zero value means remove it as all http calls are settled
        const areWeLoading = number > 0;
        clearTimeout(backupReset.current);
        if (areWeLoading && globalMaxWaitEnabled.getValue()) {
          backupReset.current = setTimeout(() => {
            overrideLoadingState({});
          }, maxWaitTime) as unknown as number;
        }

        _globalPageLoadingStatus.next({ areWeLoading });

        setIsLoading(areWeLoading);
      })
    );
  }, [initialState]);

  return isLoading;
}

const _manualPageLoadingStatus = new BehaviorSubject<boolean>(false);

export const setManualLoadingStatus = (status: boolean) => {
  _manualPageLoadingStatus.next(status);
};

export function useManualLoader(initialState = false) {
  const [isLoading, setIsLoading] = useState<boolean>(initialState);

  useEffect$(() => {
    return _manualPageLoadingStatus.pipe(
      tap((status) => {
        setIsLoading(status);
      })
    );
  }, []);

  return isLoading;
}

/**
 * Only needs to be called once and will emit a stream of updated query data from the api
 *
 * @returns Observable that will emit on any filter store changes and query the api for new data
 */
export function getDataBasedOnActiveFilters({
  endpoint,
  brokenOutFilterIds = [],
  additionalNonActiveFilters = [],
  additionalOperatorsBeforeQuery = (o) => o,
  requestMethod = RequestMethod.GET,
  includeInGlobalLoader = true,
  preFetchQuery = false,
  requiredParams = [],
  customGetRequest = false,
  isGqlQuery = false,
  isGoRequest = false,
  gqlClient,
  view = Views.NONE,
  dataFetcher, // do not add a default here
  kibanaLogger,
}: SetOptional<InternalBuildDataRequest, 'preFetchQuery'>) {
  return iif(
    () => !isUndefined(endpoint),
    combineLatest({
      ...(dataFetcher ? { dataFetcher: of(dataFetcher) } : {}),
      endpoint: isObservable(endpoint)
        ? endpoint
        : of(endpoint && getPlotEndpointConfig(endpoint)),
      filters: getActiveFiltersState(),
      additionalFilters: getManyFiltersState(
        isObservable(additionalNonActiveFilters)
          ? additionalNonActiveFilters
          : of(
              additionalNonActiveFilters.length
                ? additionalNonActiveFilters
                : []
            )
      ),
      brokenOutFilterIds$: isObservable(brokenOutFilterIds)
        ? brokenOutFilterIds
        : of(brokenOutFilterIds),
      includeInGlobalLoader: of(includeInGlobalLoader),
      preFetchQuery: of(preFetchQuery),
    }).pipe(
      distinct(),
      debounceTime(50),
      filter(({ endpoint, filters, additionalFilters }) => {
        const _filters = [...filters, ...additionalFilters].map((f) => f.id);
        const isAllRequiredParamsProvided = requiredParams.every((param) => {
          return _filters.includes(param);
        });
        return isAllRequiredParamsProvided && !!endpoint;
      }),
      map((source) => {
        const endpoint = source.endpoint as CalculatedEndpoint;
        const hash = objectHash(
          {
            filters: source.filters,
            additionalFilters: source.additionalFilters,
            endpoint: endpoint.url
              ? source.endpoint // rest endpoints will be unique (e.g. pay model endpoints)
              : getDataView({
                  plotName: endpoint.name || '',
                  endpointPath: endpoint.endpointPath,
                }), // need unique identifier for gql when multiple gql queries on single page (e.g. postings)
          },
          { unorderedArrays: true }
        );
        return {
          ...source,
          plotRequestHash: hash,
        };
      }),
      (source) =>
        combineLatest({
          result: additionalOperatorsBeforeQuery(source),
          source,
        }).pipe(
          map(({ result, source }) => {
            return {
              ...result,
              requestHash: source.plotRequestHash, // requestHash is needed later in this function but the additionalOperatorsBeforeQuery might remove the requestHash
            };
          })
        ),
      distinctUntilChanged((prev, current) => {
        return prev.plotRequestHash === current.plotRequestHash;
      }), // don't fire off requests if nothing has changed for the endpoint
      map(
        ({
          dataFetcher,
          endpoint,
          filters,
          additionalFilters,
          brokenOutFilterIds$,
          includeInGlobalLoader,
          preFetchQuery,
        }) => {
          return {
            dataFetcher,
            endpoint,
            filters: serializeFiltersForQuery(
              [...filters, ...additionalFilters],
              brokenOutFilterIds$,
              isGoRequest,
              view
            ),
            includeInGlobalLoader,
            preFetchQuery,
          };
        }
      ),
      map(
        ({
          dataFetcher,
          endpoint,
          filters,
          includeInGlobalLoader,
          preFetchQuery,
        }) => {
          const {
            url,
            name: graph,
            endpointPath,
            error,
          } = endpoint as CalculatedEndpoint;
          const result: {
            dataFetcher?: typeof dataFetcher;
            url?: URL;
            endpoint: any;
            requestHash: string;
            search?: string;
            body?: any;
            error: any;
            filters: SerializedFiltersForQuery;
            graph: EndpointSegment;
            includeInGlobalLoader: boolean;
            preFetchQuery: boolean;
            abortController?: AbortController;
          } = {
            dataFetcher,
            url,
            endpoint,
            requestHash: '',
            error,
            filters,
            graph: graph as EndpointSegment,
            includeInGlobalLoader,
            preFetchQuery,
          };
          result.url =
            result.url ||
            new URL(`${isGoRequest ? GO_API_ROOT : API_ROOT}${endpointPath}`);

          if (requestMethod == RequestMethod.GET) {
            const isOldBackend = !isGoRequest;
            let _filters = filters as Partial<DecodedValueMap<any>>;
            if (isOldBackend) {
              _filters = mapValues(filters, (val, key) => {
                if (key == 'subfilter' && isArray(val)) {
                  return val.map((v) => Number.parseInt(v as string));
                }
                if (key == 'filters') {
                  return mapValues(
                    val as EnumeratedFilters<
                      ValidValueTypes | ValidValueTypes[]
                    >,
                    (fVal) =>
                      isArray(fVal)
                        ? fVal.map((v) => Number.parseInt(v as string))
                        : val
                  );
                }
                return val;
              });
            }

            let searchParams = encodeQueryParams(
              {
                filters: JsonParam,
                subfilter: JsonParam,
                grouped: StringParam,
                graph: StringParam,
                start_time: StringParam,
                end_time: StringParam,
                inflow: StringParam,
                primary_filter: StringParam,
                posting_metric: StringParam,
              },
              {
                ..._filters,
                graph,
              }
            );

            if (isGoRequest) {
              searchParams = encodeQueryParams(
                {
                  filters: JsonParam,
                },
                {
                  filters: filters.filters,
                }
              );
            }

            result.url.search =
              [API_ROOT, GO_API_ROOT].includes(result.url.origin) &&
              !customGetRequest
                ? stringify(searchParams)
                : (url as URL).search;
            result.search = result.url.search;
            result.requestHash = result.url.toString();
          }

          if (requestMethod == RequestMethod.POST) {
            result.requestHash = objectHash(
              {
                url: result.url.toString(),
                ...filters,
              },
              { unorderedArrays: true }
            );
            result.body = isEmpty(filters)
              ? undefined
              : JSON.stringify(filters);
          }

          return result;
        }
      ),
      removeCancelledFromLoader({ gqlClient }),
      mergeMap(
        ({
          dataFetcher,
          url,
          endpoint,
          requestHash,
          search,
          body,
          filters,
          graph,
          error,
          abortController = new AbortController(),
          prevGqlRequest,
          includeInGlobalLoader,
          preFetchQuery,
        }) => {
          if (error) {
            return of({ error });
          }

          if (dataFetcher) {
            const argsForDataFetcher = {
              gqlClient: gqlClient as Client,
              url,
              endpoint,
              includeInGlobalLoader,
              requestMethod,
              requestHash,
              filters,
              isGoRequest,
              abortController,
            };

            return dataFetcher(argsForDataFetcher);
          }

          if (!url) {
            return of({
              error: `How'd that happen? There's no URL to query, yikes!!`,
            });
          }

          if (
            isEmpty(filters.filters) &&
            requestMethod == RequestMethod.GET &&
            [API_ROOT].includes(url.origin)
          ) {
            return of({ error: new Error('No filters set!') });
          }

          // NOTE: this is when the pipeline is initially kicked off but the user hasn't set anything yet. Might should prevent it earlier, but this works for now.
          if (requestMethod == RequestMethod.POST && !body) {
            return of('');
          }

          if (isGqlQuery && gqlClient) {
            return getGqlData({
              gqlClient,
              endpoint: of(endpoint),
              includeInGlobalLoader,
              requestHash,
              filters,
              prevGqlRequest,
            });
          }

          return queryEndpoint({
            url,
            requestHash,
            requestMethod,
            search,
            body,
            includeInGlobalLoader,
            abortController,
            preFetchQuery,
            catchErrorBool: true,
            isGoRequest,
            kibanaLogger,
          });
        }
      )
    ),
    of({})
  );
}

export function useDataBasedOnActiveFilters({
  endpoint,
  brokenOutFilterIds,
  additionalNonActiveFilters = [],
  requestMethod = RequestMethod.GET,
  additionalOperatorsBeforeQuery = (o) => o,
  additionalOperators = (o) => o,
  includeInGlobalLoader = true,
  preFetchQuery = false,
  requiredParams,
  removeQueryFromLoaderOnResponse = false,
  toggleLoadingStatusBeforeQuery = false,
  isGqlQuery = false,
  isGoRequest = false,
  view = Views.NONE,
  dataFetcher, // do not add a default here
  kibanaLogger,
  skip,
}: BuildDataRequest) {
  const [data, setData] = useState<
    Partial<{ requestHash: string; loading: boolean; data: any }>
  >({
    requestHash: undefined,
    loading: false,
    data: undefined,
  });

  const gqlClient = useClient();

  const [error, seterror] = useState(undefined as any);

  const operatorsBeforeQuery = pipe(
    tap((value) => {
      if (toggleLoadingStatusBeforeQuery) {
        setData({ loading: true });
      }
    }),
    additionalOperatorsBeforeQuery
  );

  useEffect$(() => {
    if (skip) {
      return EMPTY;
    }

    return iif(
      () => !isUndefined(endpoint),
      getDataBasedOnActiveFilters({
        dataFetcher,
        endpoint,
        brokenOutFilterIds,
        additionalNonActiveFilters,
        requestMethod,
        additionalOperatorsBeforeQuery: operatorsBeforeQuery,
        includeInGlobalLoader,
        preFetchQuery,
        requiredParams,
        isGqlQuery,
        isGoRequest,
        gqlClient,
        view,
        kibanaLogger,
      }),
      of({}) // for case when no prefetch config is available
    ).pipe(
      additionalOperators,
      tap((newData: any) => {
        const requestHash = get(newData, 'requestHash');
        unset(newData, 'requestHash');
        const isLoading = get(newData, 'loading', false);
        const datafinal = has(newData, 'loading') ? undefined : newData;

        if (newData.error) {
          seterror(newData.error);
          setData({ loading: false, requestHash });
        } else {
          setData({ loading: isLoading, requestHash, data: datafinal });
          seterror(null);
        }

        if (removeQueryFromLoaderOnResponse) {
          removeLoadingStatus(requestHash);
        }
      })
    );
  }, []);
  return [data, error];
}

interface RemoveCancelledFromLoaderProps {
  gqlClient?: Client;
}
export function removeCancelledFromLoader({
  gqlClient,
}: RemoveCancelledFromLoaderProps): MonoTypeOperatorFunction<{
  dataFetcher?: DataFetcher<unknown>;
  url?: URL | undefined;
  endpoint: any;
  requestHash: string;
  search?: string | undefined;
  body?: any;
  error: any;
  filters: SerializedFiltersForQuery;
  graph: EndpointSegment;
  includeInGlobalLoader: boolean;
  preFetchQuery: boolean;
  abortController?: AbortController;
  prevGqlRequest?: { request: GraphQLRequest | null };
}> {
  const prevGqlRequest: { request: GraphQLRequest | null } = { request: null };

  let prevController: AbortController;
  let prevHash: string;

  return pipe(
    map((result) => {
      const { requestHash, includeInGlobalLoader, preFetchQuery } = result;

      const controller = new AbortController();

      if (
        prevController &&
        prevHash &&
        !preFetchQuery &&
        prevHash !== requestHash
      ) {
        prevController.abort();

        const prevRequest = prevGqlRequest.request;
        if (gqlClient && prevRequest) {
          gqlClient.reexecuteOperation(
            gqlClient.createRequestOperation('teardown', prevRequest)
          );
        }

        if (includeInGlobalLoader) removeLoadingStatus([prevHash]);
      }

      if (!preFetchQuery) {
        prevController = controller;
        prevHash = requestHash;
      }

      return { ...result, abortController: controller, prevGqlRequest };
    })
  );
}

type RCIDMappedItem = {
  id: number;
  label: string;
  data: {
    shortName: string;
    rcid: number;
  };
  companyId: string;
};

export function useRCIDMappingState() {
  useEffect$(() =>
    combineLatest([
      getSingleFilterState(SelectionCategories.COMPANY),
      selectionListDataSource.data$({
        key: SelectionCategories.COMPANY,
      }),
    ]).pipe(
      withLatestFrom(
        getSingleFilterState(SelectionCategories.COMPANY_RCID_MAPPINGS)
      ),
      tap((fil) => {
        const [[activeCompanyState, lists], rcidMapping] = fil;

        const allLists = get(lists, 'selectionLists', []);

        const companyDashboardList = allLists[0];

        if (companyDashboardList) {
          const dashboardValues = get(companyDashboardList, 'value', []);

          const activeCompValues = get(
            activeCompanyState as SelectFilter<FilterItem[]>,
            'value',
            []
          );

          const rcidMapped = activeCompValues.map((val) => {
            const allMatchedRCIDs = get(
              rcidMapping as SelectFilter<RCIDMappedItem[]>,
              'value',
              []
            );

            const matchedValue = allMatchedRCIDs.find((matchedVal) => {
              return matchedVal.id === val.id;
            });

            if (isUndefined(matchedValue)) {
              const matchedCompany = dashboardValues.find((dashVal) => {
                const rcidToMap = get(val, 'data.rcid');
                return Number(dashVal.rcid) === Number(rcidToMap);
              });

              if (matchedCompany) {
                return {
                  ...val,
                  companyId: matchedCompany.id,
                };
              }
            }

            return matchedValue || val;
          });

          upsertFilter(SelectionCategories.COMPANY_RCID_MAPPINGS, {
            id: SelectionCategories.COMPANY_RCID_MAPPINGS,
            value: rcidMapped,
          });
        }
      })
    )
  );
}

export {
  getSingleFilterState,
  getActiveFiltersState,
  getManyFiltersState,
  addActiveFiltersIds,
  removeActiveFiltersIds,
  upsertFilter,
  deleteFilter,
  deleteFilters,
  removeSelectFilterValue,
  requireAtLeastOneFilterValueOf,
  upsertFiltersWithProvidedValue,
  doesFilterHaveState,
  globalPageLoadingStatus,
  toggleStatusOnGlobalLoaderOrSkipOne,
  setActiveFiltersIds,
  usePrimaryFilter,
  filterStore,
};
