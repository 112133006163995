import { mapKeys } from 'lodash';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useSelectionListsValidated } from '../../engine/filters.engine';
import { SelectionCategories, TreeItem } from '../../engine/filters.model';
import { getNestedId, nestSelectionLists } from '../tree/utils';
import { BreadcrumbTree, BreadcrumbTreeProps } from './BreadcrumbTree';
import { TreeApi } from '../tree';

export type BreadcrumbTreeSelectionProps = Pick<
  BreadcrumbTreeProps,
  'branches'
> & {
  selectionListIds: SelectionCategories[];
  initialSelections: Record<string, TreeItem>;
  maxSelections?: number;
  onMaxExceeded?: () => void;
  onChange?: (items: Record<string, TreeItem>) => void;
};

export const BreadcrumbTreeSelection = forwardRef<
  TreeApi,
  BreadcrumbTreeSelectionProps
>(
  (
    {
      selectionListIds,
      initialSelections = {},
      branches,
      maxSelections,
      onMaxExceeded,
      onChange,
    },
    ref
  ) => {
    const [selections, setSelections] = useState<string[]>([]);
    const selectionLists = useSelectionListsValidated(selectionListIds);

    useEffect(() => {
      /** map initial tree selections to nestedIds
       * then setSelections */
      const applicableSelections = Object.values(initialSelections).filter(
        (item) =>
          selectionLists
            .map((list) => list.id)
            .includes(item.selectionListId as SelectionCategories)
      );
      const nestedIds = applicableSelections.map((item) =>
        getNestedId({ selectionLists, item })
      );
      setSelections(nestedIds);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectionLists]);

    const handleOnChange = (value: string[]) => {
      /** if we are over the max selections, and max selections is not === 1 (single select)
       * show a warning toast that we are over the limit */

      const entityIds = value.map((id) => {
        const ids = id.split('_');
        // to avoid conflicts between different levels of granularity
        const granularity = ids.length;

        return `${granularity}_${ids.pop()}`;
      });

      const uniqueEntityIdsLength = new Set(entityIds).size;

      if (
        maxSelections &&
        maxSelections > 1 &&
        uniqueEntityIdsLength > maxSelections
      ) {
        onMaxExceeded?.();
        return;
      }

      // otherwise, set the selections
      setSelections(value);

      onChange?.(
        ((selections: string[]) => {
          const selectedTreeItems: TreeItem[] = selections
            .map((id): TreeItem | null => {
              const splitIds = id.split('_');
              const splitIdsLength = splitIds.length;
              const lastId = splitIds[splitIdsLength - 1];

              if (!lastId) return null;

              const selectionList = selectionLists[splitIdsLength - 1];
              const selectedItem = selectionList.value.find(
                (item) => item.id === lastId
              );

              if (!selectedItem) return null;

              return {
                id: selectedItem.id,
                item: selectedItem,
                selectionListId: selectionList.id,
                children: [],
              };
            })
            .filter((entity) => entity !== null);

          return mapKeys(
            selectedTreeItems,
            (value) => `${value.selectionListId}.${value.id}`
          );
        })(value)
      );
    };

    const data = useMemo(() => {
      return nestSelectionLists(selectionLists);
    }, [selectionLists]);

    return (
      <BreadcrumbTree
        ref={ref}
        data={data}
        value={selections}
        branches={branches}
        onChange={handleOnChange}
        height={320}
        singleSelect={maxSelections === 1}
      />
    );
  }
);
