import { Tooltip, VStack, HStack, Spacer } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import styles from './stacked-bar-tooltip.module.css';
import classNames from 'classnames';

type StackedBarTooltipProps = PropsWithChildren & {
  title: string;
  entries: {
    label: string;
    active: boolean;
    mainValue: string;
    secondaryValue: string;
  }[];
};

export const StackedBarTooltip = ({
  title,
  entries,
  children,
}: StackedBarTooltipProps) => {
  return (
    <Tooltip
      hasArrow
      padding="12px"
      placement="top"
      bg="#2d426a"
      borderRadius="4px"
      label={
        <VStack spacing={0} align="stretch" width="216px" px="7px">
          <div className={styles.barTooltipLabel}>{title}</div>
          {entries.map((entry, index) => {
            return (
              <HStack key={index} spacing={4} height="19px">
                <div
                  className={classNames(styles.barTooltipValue, {
                    [styles.highlight]: entry.active,
                  })}
                >
                  {entry.label}
                </div>
                <Spacer />
                <div
                  className={classNames(styles.barTooltipValue, {
                    [styles.highlight]: entry.active,
                  })}
                >
                  {entry.mainValue} / {entry.secondaryValue}
                </div>
              </HStack>
            );
          })}
        </VStack>
      }
    >
      {children}
    </Tooltip>
  );
};
