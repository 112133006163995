import { useMemo } from 'react';
import { useQuery } from 'urql';

import { StackedBarChartHorizontalData } from '@revelio/d3';
import { TransitionDimension } from '@revelio/data-access';
import {
  TRANSITIONS_DATA,
  useActiveFiltersState,
  FilterName,
  useAllFiltersState,
  LocalSelectionCategories,
} from '@revelio/filtering';
import { PrimaryView } from '@revelio/core';

import { transformFiltersToVariables } from '../../../utils';
import { useIsTransitionsQueryReady } from './use-is-transitions-query-ready';
import { transformSankeyData } from './transform-sankey-data';
import { transformStackedBarData } from './transform-stacked-bar-data';

interface UseTransitionsDataFetchProps {
  view: PrimaryView;
  primaryFilters: FilterName[];
}
export const useTransitionsDataFetch = ({
  view,
  primaryFilters,
}: UseTransitionsDataFetchProps) => {
  const activeFilters = useActiveFiltersState();
  const allFilters = useAllFiltersState();

  const isQueryReady = useIsTransitionsQueryReady({
    activeFilters,
    primaryFilters,
    view,
  });

  const queryFilters = useMemo(
    () =>
      transformFiltersToVariables({
        view,
        filters: [...activeFilters],
        isCustomRoleTaxonomyEnabled: true,
      }),
    [activeFilters, view]
  );

  const [{ data, fetching, error }] = useQuery({
    query: TRANSITIONS_DATA,
    variables: {
      ...queryFilters,
      dim2: TransitionDimension.All,
    },
    pause: !isQueryReady,
  });

  const inflow_or_outflow = (
    allFilters.find(
      (filter) => filter.label === LocalSelectionCategories.INFLOW_OR_OUTFLOW
    )?.value as { id: string }
  )?.id as 'inflow' | 'outflow';

  const transformedSankeyData = transformSankeyData(data, inflow_or_outflow);

  const isInflow = inflow_or_outflow === 'inflow';
  const barPlotMetricsToTransform = Object.keys(
    data?.transitions2D?.[isInflow ? 0 : 1]?.metrics ?? {}
  ).filter((metric) => metric !== 'count');

  const transformStackedBarChartData = barPlotMetricsToTransform.reduce(
    (acc, metric) => {
      acc[metric] = transformStackedBarData(data, inflow_or_outflow, metric);
      return acc;
    },
    {} as Record<string, StackedBarChartHorizontalData[]>
  );

  return {
    data: {
      sankeyData: transformedSankeyData,
      barChartData: transformStackedBarChartData,
    },
    fetching,
    error,
    isQueryReady,
  };
};
