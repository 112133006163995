import mixpanel from 'mixpanel-browser';
import React from 'react';
import Iframe from 'react-iframe';
import { Box } from '@chakra-ui/layout';
import { UserTrackingEvents } from '@revelio/core';
import { useLifecycles } from 'react-use';

const TaxonomyCatalog = () => {
  useLifecycles(
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_VISIT, {
        page: 'Taxonomy Catalog',
      });
      mixpanel.time_event(UserTrackingEvents.PAGE_LEAVE);
    },
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_LEAVE, {
        page: 'Taxonomy Catalog',
      });
    }
  );

  return (
    <Box height="100vh">
      <Iframe
        url="https://taxonomy.reveliolabs.com"
        width="100%"
        height="100%"
        position="relative"
        styles={{ border: 'none' }}
      />
    </Box>
  );
};

export default TaxonomyCatalog;
