import { Text } from '@chakra-ui/layout';

import { FormatType, LineChart, LineData } from '@revelio/replots';

import { PlotCard, convertLineToSheet } from '../../../shared/components';

interface TimeToFillLineChartCardProps {
  data: LineData[];
  colorMap: Record<string, string>;
  title: string;
  loading?: boolean;
}

export const TimeToFillLineChartCard = ({
  data,
  colorMap,
  title,
  loading,
}: TimeToFillLineChartCardProps) => {
  const dataWithColors = data.map((line) => ({
    ...line,
    color: colorMap[line.label],
  }));

  return (
    <PlotCard
      title={title}
      info={
        <Text variant="tooltip">
          The average number of days that job postings are active before being
          removed.
        </Text>
      }
      menu={['expand', 'download-png', 'download-data']}
      exportData={convertLineToSheet({ data })}
    >
      <LineChart
        data={dataWithColors}
        showGridLines={true}
        format={FormatType.INTEGER}
        loading={loading}
      />
    </PlotCard>
  );
};
