import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export const useInitSentimentTabs = () => {
  const [searchParams] = useSearchParams();

  const snapshotOrOvertime =
    searchParams.get('snapshot_or_over_time') || 'snapshot';

  const initialTab = snapshotOrOvertime === 'overtime' ? 1 : 0;

  const [tabIndex, setTabIndex] = useState(initialTab);

  const viewType: 'snapshot' | 'overtime' =
    tabIndex === 0 ? 'snapshot' : 'overtime';

  return { tabIndex, setTabIndex, viewType };
};
