import { Views } from '@revelio/core';
import { ViewsForDefaultsOnly, ViewTypes } from '../data-api/data-api.model';
import {
  LocalSelectionCategories,
  SelectionCategories,
  SubFilterNames,
} from './filters.model';
import {
  GEOGRAPHY_GRANULARITY_FILTERS,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
} from './filters.constants';

export enum SerializedDateRangeFilterIds {
  DATE_RANGE_START_DATE = 'startDate',
  DATE_RANGE_END_DATE = 'endDate',
  DATE_RANGE_FULL_START_DATE = 'startDateFull',
  DATE_RANGE_FULL_END_DATE = 'endDateFull',
}

export const ALL_DATE_FILTER_IDS = [
  ...Object.values(SerializedDateRangeFilterIds),
  SelectionCategories.SNAPSHOT_DATE,
];

const BASE_COMPOSITION_FILTERS = [
  SelectionCategories.INDUSTRY,

  ...GEOGRAPHY_GRANULARITY_FILTERS,
  ...ROLE_GRANULARITY_FILTERS,
  ...SKILL_GRANULARITY_FILTERS,

  SelectionCategories.SENIORITY,
  SelectionCategories.HIGHEST_DEGREE,
  SelectionCategories.GENDER,
  SelectionCategories.ETHNICITY,

  LocalSelectionCategories.DATA_METRIC,
  LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME,

  SubFilterNames.SUB_ROLE,
  SubFilterNames.SUB_REGION,
  SubFilterNames.SUB_SENIORITY,
  SubFilterNames.SUB_GENDER,
  SubFilterNames.SUB_ETHNICITY,
  SubFilterNames.SUB_EDUCATION,
  SubFilterNames.SUB_INDUSTRY,
  SubFilterNames.SUB_SKILL_OVERTIME,
];

const BASE_TRANSITION_FILTERS = [
  ...SKILL_GRANULARITY_FILTERS,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.SENIORITY,
  SelectionCategories.HIGHEST_DEGREE,
  SelectionCategories.GENDER,
  SelectionCategories.ETHNICITY,
  SelectionCategories.REGION,
  SelectionCategories.INDUSTRY,
  LocalSelectionCategories.INFLOW_OR_OUTFLOW,
  LocalSelectionCategories.N_ITEMS_SANKEY,
];

const BASE_POSTINGS_FILTERS = [
  ...ROLE_GRANULARITY_FILTERS,
  ...GEOGRAPHY_GRANULARITY_FILTERS,
  ...SKILL_GRANULARITY_FILTERS,
  SelectionCategories.SENIORITY,
  SelectionCategories.POSTING_METRIC,
  LocalSelectionCategories.PROVIDER,
  LocalSelectionCategories.METRIC_MODE,
];

const BASE_COMPENSATION_FILTERS = [
  LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP,
  ...RICS_AND_COMPANY_FILTERS,
  ...GEOGRAPHY_GRANULARITY_FILTERS,
  ...ROLE_GRANULARITY_FILTERS,
];

const BASE_SKILLS_FILTERS = [
  SelectionCategories.INDUSTRY,
  SelectionCategories.SENIORITY,
  SelectionCategories.REGION,
  SelectionCategories.JOB_CATEGORY,
  SubFilterNames.SUB_SKILL,
  SubFilterNames.SUB_KEYWORD,
];

const BASE_SENTIMENT_RATINGS_FILTERS = [
  SelectionCategories.INDUSTRY,
  SelectionCategories.SENIORITY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K50,
  LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME,
];

const BASE_SENTIMENT_FILTERS = [
  SelectionCategories.SENIORITY,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K50,
  LocalSelectionCategories.OVERALL_RATING,
];

const BASE_TEXT_FILTERS = [
  SelectionCategories.KEYWORD,
  SelectionCategories.RAW_TITLE,
];

// remove duplicate filters across the various pages
export const ALL_SUPPORTED_FILTERS = Array.from(
  new Set([
    ...BASE_COMPOSITION_FILTERS,
    ...BASE_TRANSITION_FILTERS,
    ...BASE_POSTINGS_FILTERS,
    ...BASE_COMPENSATION_FILTERS,
    ...BASE_SKILLS_FILTERS,
    ...BASE_SENTIMENT_RATINGS_FILTERS,
    ...BASE_SENTIMENT_FILTERS,
    ...BASE_TEXT_FILTERS,
  ])
);

export type DEEP_LINK_SUPPORTED_VIEWS =
  | Views.OVERVIEW
  | Views.TRANSITION
  | Views.POSTING
  | Views.COMPENSATION
  | Views.SKILL
  | Views.SENTIMENT // SENTIMENT_RATINGS
  | ViewsForDefaultsOnly.SENTIMENT_REVIEWS
  | ViewsForDefaultsOnly.SENTIMENT_EFFECTS;

export const SUPPORTED_DEEP_LINK_FILTERS_BY_PAGE: {
  [key in DEEP_LINK_SUPPORTED_VIEWS]: any;
} = {
  [Views.OVERVIEW]: {
    [ViewTypes.COMPANY]: [
      ...BASE_COMPOSITION_FILTERS,
      SelectionCategories.COMPANY,
      SubFilterNames.SUB_REGION,
      SubFilterNames.SUB_ROLE,
    ],
    [ViewTypes.GEO]: [
      ...BASE_COMPOSITION_FILTERS,
      SelectionCategories.COMPANY,
      SubFilterNames.SUB_INDUSTRY,
      SubFilterNames.SUB_ROLE,
    ],
    [ViewTypes.ROLE]: [
      ...BASE_COMPOSITION_FILTERS,
      SubFilterNames.SUB_INDUSTRY,
      SubFilterNames.SUB_REGION,
    ],
  },
  [Views.TRANSITION]: {
    [ViewTypes.COMPANY]: [
      ...BASE_TRANSITION_FILTERS,
      SelectionCategories.COMPANY,
      LocalSelectionCategories.IS_UNIVERSITY,
    ],
    [ViewTypes.GEO]: [...BASE_TRANSITION_FILTERS, SelectionCategories.COUNTRY],
    [ViewTypes.ROLE]: [
      ...BASE_TRANSITION_FILTERS,
      SelectionCategories.ROLE_K50,
      SelectionCategories.COUNTRY,
    ],
  },
  [Views.POSTING]: {
    [ViewTypes.COMPANY]: [
      ...BASE_POSTINGS_FILTERS,
      SelectionCategories.COMPANY,
    ],
    [ViewTypes.GEO]: [
      ...BASE_POSTINGS_FILTERS,
      SelectionCategories.MSA, // old backend value
      SelectionCategories.INDUSTRY,
      SelectionCategories.ROLE_K50, // old backend value
    ],
    [ViewTypes.ROLE]: [
      ...BASE_POSTINGS_FILTERS,
      SelectionCategories.MSA, // old backend value
      SelectionCategories.INDUSTRY,
    ],
    [ViewTypes.SKILLS]: [
      ...BASE_POSTINGS_FILTERS,
      SelectionCategories.KEYWORD,
      SelectionCategories.INDUSTRY,
      SelectionCategories.MSA, // old backend value
    ],
  },
  [Views.COMPENSATION]: {
    [ViewTypes.ROLE]: BASE_COMPENSATION_FILTERS,
    [ViewTypes.GEO]: BASE_COMPENSATION_FILTERS,
    [ViewTypes.COMPANY]: BASE_COMPENSATION_FILTERS,
  },
  [Views.SKILL]: {
    [ViewTypes.COMPANY]: [...BASE_SKILLS_FILTERS, SelectionCategories.COMPANY],
    [ViewTypes.GEO]: [...BASE_SKILLS_FILTERS],
    [ViewTypes.ROLE]: [...BASE_SKILLS_FILTERS],
  },
  [Views.SENTIMENT]: {
    [ViewTypes.COMPANY]: [
      SelectionCategories.COMPANY,
      SelectionCategories.INDUSTRY,
      ...BASE_SENTIMENT_FILTERS,
      ...GEOGRAPHY_GRANULARITY_FILTERS,
    ],
    [ViewTypes.COMPANY_RATINGS]: [
      ...BASE_SENTIMENT_RATINGS_FILTERS,
      SelectionCategories.COMPANY,
    ],
    [ViewTypes.GEO]: [
      ...BASE_SENTIMENT_RATINGS_FILTERS,
      SelectionCategories.REGION,
      SelectionCategories.COUNTRY,
    ],
    [ViewTypes.ROLE]: [...BASE_SENTIMENT_RATINGS_FILTERS],
  },
  [ViewsForDefaultsOnly.SENTIMENT_REVIEWS]: {
    [ViewTypes.COMPANY_REVIEWS]: [
      ...BASE_SENTIMENT_RATINGS_FILTERS,
      SelectionCategories.COMPANY,
    ],
    [ViewTypes.GEO]: [...BASE_SENTIMENT_RATINGS_FILTERS],
    [ViewTypes.ROLE]: [...BASE_SENTIMENT_RATINGS_FILTERS],
  },
  [ViewsForDefaultsOnly.SENTIMENT_EFFECTS]: {
    [ViewTypes.COMPANY_EFFECTS]: [
      ...BASE_SENTIMENT_RATINGS_FILTERS,
      SelectionCategories.COMPANY,
    ],
    [ViewTypes.GEO]: [...BASE_SENTIMENT_RATINGS_FILTERS],
    [ViewTypes.ROLE]: [...BASE_SENTIMENT_RATINGS_FILTERS],
  },
};
