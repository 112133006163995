import { Link, Text } from '@chakra-ui/react';

import { FormatType, KdeChart, KdeData } from '@revelio/replots';

import { PlotCard, convertKdeToSheet } from '../../../shared/components';

interface DistributionKdeChartCardProps {
  title: string;
  colorMap: Record<string, string>;
  data: KdeData[];
  dimensionName: string;
  compViewLabel: string;
  loading: boolean;
}

export const DistributionKdeChartCard = ({
  title,
  colorMap,
  data,
  dimensionName,
  compViewLabel,
  loading,
}: DistributionKdeChartCardProps) => {
  const dataWithColors = data.map((d) => ({
    ...d,
    color: colorMap[d.label],
  }));

  return (
    <PlotCard
      title={title}
      info={
        <Text variant="tooltip">
          The distribution of {compViewLabel}. More information on the
          compensation model can be found{' '}
          <Link
            href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
            isExternal
            variant="tooltip"
          >
            here
          </Link>
          .
        </Text>
      }
      infoPlacement="top-end"
      menu={['expand', 'download-png', 'download-data']}
      exportData={convertKdeToSheet({
        data,
        dimensionName,
        metricName: 'salaries',
      })}
    >
      <KdeChart
        data={dataWithColors}
        format={FormatType.CURRENCY}
        loading={loading}
      />
    </PlotCard>
  );
};
