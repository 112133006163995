export enum FormatType {
  PERCENTAGE = '%',
  CURRENCY = '$',
  CURRENCY_INTEGER = '$.0f',
  INTEGER = ',',
  DECIMAL = '.',
  SCIENTIFIC = 'e',
  SI = 's',
}

export type Formatter = (value: number) => string;
export type Format = FormatType | Formatter;
