import React from 'react';
import PasteSelect from './paste-select';
import {
  SelectableCategories,
  SelectionCategories,
} from '../../../engine/filters.model';

export const TextFilters: SelectionCategories[] = [
  SelectionCategories.KEYWORD,
  SelectionCategories.RAW_TITLE,
];

interface KeywordFiltersProps {
  filterName?: SelectableCategories;
  state: any;
  setState: any;
}

export const KeywordFilters: React.FC<KeywordFiltersProps> = ({
  filterName,
  state,
  setState,
}) => {
  const shouldRenderKeywordFilter = () => {
    return (
      filterName && TextFilters.includes(filterName as SelectionCategories)
    );
  };

  return (
    shouldRenderKeywordFilter() && (
      <PasteSelect
        filterName={filterName}
        state={state}
        setState={setState}
        placeholder={
          filterName === SelectionCategories.RAW_TITLE
            ? 'e.g. software engineer, data scientist...'
            : 'e.g. linux, hardware, firewall, LAN...'
        }
      />
    )
  );
};

export default KeywordFilters;
