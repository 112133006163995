import { BoxGroup } from '@revelio/replots';

import { CompensationMetricValue } from '../../../data-fetch';

export const getTopBoxData = ({
  data,
  companyLookup,
}: {
  data: CompensationMetricValue[];
  companyLookup: { [key: string | number]: string } | undefined;
}): BoxGroup[] => {
  return data
    .flatMap((entity): BoxGroup[] | null => {
      if (!entity || !entity.metadata || !entity.value) return null;

      const { metadata, value } = entity;
      const { id, longName } = metadata;
      if (!id || !longName || !value) return null;

      const secondaryDimension = companyLookup?.[id] || longName;

      return value
        .map((v): BoxGroup | null => {
          if (!v || typeof v === 'number') return null;

          const { metadata } = v;
          if (!metadata || !metadata.longName || !metadata.id) return null;

          const dimension = companyLookup?.[metadata.id] || metadata.longName;

          const percentiles = v.value;
          if (!percentiles) return null;

          const [percentile10, percentile50, percentile90] = percentiles;
          if (
            typeof percentile10 !== 'number' ||
            typeof percentile50 !== 'number' ||
            typeof percentile90 !== 'number'
          ) {
            return null;
          }

          return {
            dimension,
            secondaryDimension,
            boxData: { percentile10, percentile50, percentile90 },
          };
        })
        .filter((v) => v !== null);
    })
    .filter((v) => v !== null);
};
