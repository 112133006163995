import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import html2canvas from 'html2canvas';
import { RefObject, useCallback, useEffect, useState } from 'react';

import { RevelioWatermark } from '../../../assets';

const HEADER_HEIGHT = 44;
const FOOTER_HEIGHT = 13;

type DownloadPlotPNGProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  printRef: RefObject<HTMLDivElement>;
};

export const DownloadPlotPNG = ({
  isOpen,
  onClose,
  title,
  printRef,
}: DownloadPlotPNGProps) => {
  const [transparentBackground, setTransparentBackground] = useState(false);
  const [fileName, setFileName] = useState('');
  const [plotTitle, setPlotTitle] = useState('');

  const resetForm = useCallback(() => {
    setFileName(title.toLowerCase().replace(/\s+/g, '_'));
    setPlotTitle(title);
    setTransparentBackground(false);
  }, [title]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleDownload = async () => {
    const cardBody = printRef.current;
    const chartContainer = cardBody?.firstElementChild;

    if (!chartContainer) throw new Error('Chart container not found');

    if (chartContainer) {
      const { width, height } = chartContainer.getBoundingClientRect();
      const chartNode = chartContainer?.cloneNode(true);

      const imageEl = document.createElement('div');
      imageEl.style.width = `${width + 20}px`;
      imageEl.style.height = `${height + HEADER_HEIGHT + FOOTER_HEIGHT + 4}px`;
      imageEl.style.padding = '0 10px';

      const headerEl = document.createElement('h1');
      headerEl.style.fontSize = '1.5rem';
      headerEl.style.color = '#2d426a';
      headerEl.style.fontWeight = '600';
      headerEl.style.marginBottom = '0.5rem';
      headerEl.style.textAlign = 'center';
      headerEl.innerHTML = plotTitle;
      imageEl.appendChild(headerEl);

      const chartEl = document.createElement('div');
      chartEl.style.width = '100%';
      chartEl.style.height = `${height}px`;
      chartEl.style.margin = 'auto';
      chartEl.appendChild(chartNode);

      imageEl.appendChild(chartEl);

      const watermarkEl = document.createElement('img');
      watermarkEl.src = RevelioWatermark;
      watermarkEl.style.width = '85px';
      watermarkEl.style.height = `${FOOTER_HEIGHT}px`;
      watermarkEl.style.float = 'right';
      imageEl.appendChild(watermarkEl);

      document.body.append(imageEl);

      const canvas = await html2canvas(imageEl, {
        backgroundColor: transparentBackground ? null : '#fff',
        onclone: (document: Document) => {
          Array.from(document?.querySelectorAll('*') || []).forEach((e) => {
            const existingStyle = e.getAttribute('style') || '';
            e.setAttribute(
              'style',
              existingStyle + '; font-size: 10 !important'
            );
          });
        },
      });
      const data = canvas.toDataURL();
      const link = document.createElement('a');

      if (typeof link.download === 'string') {
        link.href = data;
        link.download = `${fileName || 'plot'}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
      document.body.removeChild(imageEl);
    }

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title} Plot Download</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired marginBottom="1rem">
            <FormLabel fontSize="sm">File Name</FormLabel>
            <Input
              type="text"
              size="sm"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
          </FormControl>
          <FormControl marginBottom="1rem">
            <FormLabel fontSize="sm">Title</FormLabel>
            <Input
              type="text"
              size="sm"
              value={plotTitle}
              onChange={(e) => setPlotTitle(e.target.value)}
            />
          </FormControl>
          <FormControl marginBottom="1rem">
            <Checkbox
              colorScheme="green"
              isChecked={transparentBackground}
              onChange={() =>
                setTransparentBackground((prevState) => !prevState)
              }
            >
              <Text fontSize="sm">Transparent Background</Text>
            </Checkbox>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="space-between">
            <Button
              size="sm"
              variant="ghost"
              colorScheme="red"
              onClick={resetForm}
            >
              Reset
            </Button>
            <div>
              <Button
                size="sm"
                variant="link"
                colorScheme="gray"
                marginRight="1rem"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button colorScheme="green" size="sm" onClick={handleDownload}>
                Download
              </Button>
            </div>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
