import { clamp } from 'lodash';

const X_TICK_SPACING = 100;
const Y_TICK_SPACING = 120;

const MIN_TICK_COUNT = 2;
const MAX_TICK_COUNT = 6;

export const xAxisTickCount = (availableSpace: number): number => {
  return tickCount(availableSpace, X_TICK_SPACING);
};

export const yAxisTickCount = (availableSpace: number): number => {
  return tickCount(availableSpace, Y_TICK_SPACING);
};

export const tickCount = (availableSpace: number, spacing: number): number => {
  const tickCount = Math.round(availableSpace / spacing);
  return clamp(tickCount, MIN_TICK_COUNT, MAX_TICK_COUNT);
};
