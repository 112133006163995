import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './date-select.css';

import { isEmpty, isEqual } from 'lodash';
import { FormControl, Input, Text, VStack } from '@chakra-ui/react';
import { parse } from 'date-fns';
import { Select } from 'chakra-react-select';

import {
  filterStore,
  useSingleOrMoreFilterState,
} from '../../engine/filters.engine';
import {
  SelectionCategories,
  OtherFilterNames,
  SelectFilter,
} from '../../engine/filters.model';
import { getStartDateConst } from '../date-range/helpers';
import {
  createDateOptions,
  createFilteredPredefinedDateDictionary,
  CUSTOM_DATE_SELECT_OPTION,
  initialisePredefinedDateOptionValue,
  PredefinedDateOption,
} from '../dateHelpers';
import { getSelectDateDictionary, SelectDateDictionary } from './helpers';
import { STANDARD_DATE_FORMAT, Views } from '@revelio/core';
import { getDefaultLastMonth } from '../../engine/filters.core';

export interface DateSelectProps {
  view?: Views | 'default';
  dateDefaultFilterName?: OtherFilterNames;
  selectedDate?: Date;
  setSelectedDate: (o: Date | undefined) => void;
}

export function DateSelect({
  view = 'default',
  selectedDate,
  setSelectedDate,
}: DateSelectProps) {
  const dateFormat = STANDARD_DATE_FORMAT;
  const dateFilterName = SelectionCategories.SNAPSHOT_DATE;
  const [predefinedDateDict, setPredefinedDateDict] =
    useState<SelectDateDictionary>();

  const minimumDate = parse(getStartDateConst(view), dateFormat, new Date());
  const [predefinedDateSelectValue, setPredefinedDateSelectValue] =
    useState<PredefinedDateOption | null>(null);

  const lastMonth = filterStore.query(getDefaultLastMonth);
  const endMonth = lastMonth ? parse(lastMonth, dateFormat, new Date()) : null;
  const dateOptions = createDateOptions(predefinedDateDict);

  // when a new filterMenu is opened (e.g. in filterChips)
  // pre-populate it with filter value set in the global filterStore
  const [snapshotDate] =
    useSingleOrMoreFilterState<SelectFilter<string>>(dateFilterName);
  useEffect(() => {
    if (snapshotDate && !selectedDate) {
      setSelectedDate(parse(snapshotDate.value, dateFormat, new Date()));
    }
  }, [snapshotDate, selectedDate, dateFormat, setSelectedDate]);

  useEffect(() => {
    if (!endMonth) {
      return;
    }

    const newPredefinedDateDict = createFilteredPredefinedDateDictionary({
      dateDictionary: getSelectDateDictionary(endMonth),
      minimumMonth: minimumDate,
    });
    if (!isEqual(newPredefinedDateDict, predefinedDateDict)) {
      setPredefinedDateDict(newPredefinedDateDict as SelectDateDictionary);
    }
  }, [endMonth, minimumDate, predefinedDateDict]);

  useEffect(() => {
    if (!predefinedDateDict) {
      return;
    }

    initialisePredefinedDateOptionValue({
      dateValue: selectedDate,
      predefinedDateDict,
      preDefinedSelectOptionsFinder: {
        dateMonth: selectedDate,
      },
      predefinedDateSelectValue,
      setPredefinedDateSelectValue,
    });
  }, [
    selectedDate,
    predefinedDateDict,
    predefinedDateSelectValue,
    setPredefinedDateSelectValue,
  ]);

  const handleProvidedOptionsSelect = useCallback(
    (option: NonNullable<PredefinedDateOption>) => {
      if (isEmpty(predefinedDateDict)) {
        return;
      }

      setPredefinedDateSelectValue(option);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { dateMonth } = predefinedDateDict![option.value];
      if (option.value === 'custom') {
        setSelectedDate(endMonth as Date);
      } else if (option.value !== 'custom') {
        setSelectedDate(dateMonth);
      }
    },
    [predefinedDateDict, endMonth, setSelectedDate]
  );
  return (
    <VStack>
      <FormControl minWidth={'250px'}>
        <Select
          id={`filter-range-${dateFilterName}`}
          isMulti={false}
          name={dateFilterName}
          options={dateOptions}
          value={predefinedDateSelectValue}
          onChange={(newValue) =>
            handleProvidedOptionsSelect(
              newValue as NonNullable<PredefinedDateOption>
            )
          }
          size="sm"
          chakraStyles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 2,
            }),
          }}
        />
      </FormControl>

      {isEqual(predefinedDateSelectValue, CUSTOM_DATE_SELECT_OPTION) && (
        <FormControl>
          <Text fontSize="sm" pb="1">
            Date
          </Text>
          <DatePicker
            selected={selectedDate}
            onChange={(newDate) => {
              setSelectedDate(newDate as Date);
            }}
            dateFormat={dateFormat}
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            showMonthYearPicker={true}
            showYearDropdown={false}
            dropdownMode="select"
            customInput={<Input data-testid="custom-date" size="sm" />}
            minDate={minimumDate}
            maxDate={endMonth}
            onBlur={(e) => {
              e.preventDefault();
            }}
            open={true}
            popperClassName="permanent-inline-date-select-popper"
          />
        </FormControl>
      )}
    </VStack>
  );
}

export default DateSelect;
