import { Text } from '@chakra-ui/layout';
import {
  SelectionCategories,
  LocalSelectionCategories,
} from '../../engine/filters.model';
import { Views } from '@revelio/core';

const getMsg = (text?: string) => {
  return (
    <Text variant="tooltip">
      {text || 'This filter is unavailable on this page.'}
    </Text>
  );
};

export const allFilters = [
  {
    filter: [SelectionCategories.HIGHEST_DEGREE, SelectionCategories.EDUCATION],
    disabledMsg: getMsg(),
    label: 'Education',
    value: SelectionCategories.HIGHEST_DEGREE,
    isDisabled: true,
  },
  {
    filter: [SelectionCategories.ETHNICITY],
    disabledMsg: getMsg(),
    label: 'Ethnicity',
    value: SelectionCategories.ETHNICITY,
    isDisabled: true,
  },
  {
    filter: [SelectionCategories.KEYWORD],
    disabledMsg: getMsg(),
    label: 'Keywords',
    value: SelectionCategories.KEYWORD,
    isDisabled: true,
  },
  {
    filter: [SelectionCategories.RAW_TITLE],
    disabledMsg: getMsg(),
    label: 'Title',
    value: SelectionCategories.RAW_TITLE,
    isDisabled: true,
  },
  {
    filter: [SelectionCategories.GENDER],
    disabledMsg: getMsg(),
    label: 'Gender',
    value: SelectionCategories.GENDER,
    isDisabled: true,
  },
  {
    filter: [SelectionCategories.JOB_CATEGORY],
    disabledMsg: getMsg(),
    label: 'Role',
    value: SelectionCategories.JOB_CATEGORY,
    isDisabled: true,
  },
  {
    filter: [
      SelectionCategories.REGION,
      SelectionCategories.COUNTRY,
      SelectionCategories.METRO_AREA,
    ],
    disabledMsg: getMsg(),
    label: 'Geography',
    value: SelectionCategories.REGION,
    isDisabled: true,
  },
  {
    filter: [SelectionCategories.SENIORITY],
    disabledMsg: getMsg(),
    label: 'Seniority',
    value: SelectionCategories.SENIORITY,
    isDisabled: true,
  },
  {
    filter: [
      SelectionCategories.SNAPSHOT_DATE,
      SelectionCategories.DATE_RANGE,
      SelectionCategories.DATE_RANGE_FULL,
    ],
    disabledMsg: getMsg(),
    label: 'Date',
    value: null,
    unsupportedViews: [Views.SKILL, Views.ENTITY_SUMMARY],
    isDisabled: true,
  },
  {
    filter: [
      LocalSelectionCategories.DATA_METRIC as unknown as SelectionCategories,
    ],
    disabledMsg: getMsg(),
    label: 'Data Adjustments',
    value: LocalSelectionCategories.DATA_METRIC,
    isDisabled: true,
  },
  {
    filter: [SelectionCategories.INDUSTRY, SelectionCategories.COMPANY],
    disabledMsg: getMsg(),
    label: 'Company & Industry',
    value: SelectionCategories.INDUSTRY,
    isDisabled: true,
    isSingleSelect: true,
  },
  {
    filter: [
      SelectionCategories.SKILL_K75,
      SelectionCategories.SKILL_K700,
      SelectionCategories.SKILL_K3000,
    ],
    disabledMsg: getMsg(),
    label: 'Skill',
    value: [
      SelectionCategories.SKILL_K75,
      SelectionCategories.SKILL_K700,
      SelectionCategories.SKILL_K3000,
    ],
    isDisabled: true,
  },
  {
    filter: [LocalSelectionCategories.OVERALL_RATING],
    disabledMsg: getMsg(),
    label: 'Overall Rating',
    value: LocalSelectionCategories.OVERALL_RATING,
    isDisabled: true,
  },
];
