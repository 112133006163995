import { useMemo } from 'react';
import { stringify } from 'query-string';
import { JsonParam } from 'serialize-query-params';
import { encodeQueryParams } from 'serialize-query-params';

import { PrimaryView } from '@revelio/core';
import {
  filterStore,
  useRoleTaxonomySetting,
  getActiveTabMeta,
  useActiveFiltersState,
} from '@revelio/filtering';
import { transformFiltersToVariables } from '../../utils';
import { convertViewTypeToDownloadView } from '../../shared/hooks/useDownloadPlotData';

export const useSentimentEffectsDownloadPath = () => {
  const activeFilters = useActiveFiltersState();
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();
  const tabMeta = filterStore.query(getActiveTabMeta);
  const queryFilters = useMemo(
    () =>
      transformFiltersToVariables({
        view: tabMeta.viewType as unknown as PrimaryView,
        filters: activeFilters,
        isCustomRoleTaxonomyEnabled,
      }),
    [activeFilters, tabMeta.viewType, isCustomRoleTaxonomyEnabled]
  );

  return `downloads/sentiment/${convertViewTypeToDownloadView(tabMeta.viewType)}/snapshot/score?${stringify(
    encodeQueryParams(
      {
        filters: JsonParam,
      },
      { filters: queryFilters.filters }
    )
  )}`;
};
