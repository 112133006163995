import {
  D3ChartNames,
  ID3ChartProps,
  StackedBarChartHorizontalData,
} from '@revelio/d3';
import { OTHER_COLOR, StackedBarChartHorizontal } from '@revelio/replots';

import { convertStackedBarToSheet } from '../../../shared/components/plot-card/convert-to-sheet';
import { PlotCard } from '../../../shared/components/plot-card/plot-card';
import { getReplotsStackedBarData } from '../utils/get-chart-data';

interface TransitionsPlotCardProps {
  title: string;
  data: StackedBarChartHorizontalData[] | undefined;
  loading?: boolean;
  info?: React.ReactElement | null;
  slim?: boolean;
}

export const TransitionsPlotCard = ({
  title,
  data,
  loading,
  info,
  slim,
}: TransitionsPlotCardProps) => {
  const stackedBarData = getReplotsStackedBarData({ data });

  return (
    <PlotCard
      title={title}
      info={info}
      slim={slim}
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      legacyChartProps={{
        type: D3ChartNames.StackedBarChartHorizontal,
        props: { hideLegend: true } as ID3ChartProps,
        data: data ?? [],
      }}
      exportData={convertStackedBarToSheet({
        data: stackedBarData,
        dimensionName: title,
        metricName: 'Headcount',
      })}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <StackedBarChartHorizontal
          data={stackedBarData}
          loading={loading}
          hideLegend={true}
          labelWidth={38}
          colorOverride={{ Other: OTHER_COLOR }}
        />
      </div>
    </PlotCard>
  );
};
