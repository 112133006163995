import { truncateText } from '../../utilities';
import styles from './bar-label.module.css';

type BarLabelProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  fontSize?: number;
  label: string;
};

export const BarLabel = ({
  x,
  y,
  width,
  height,
  fontSize = 11,
  label,
}: BarLabelProps) => {
  const truncatedLabel = truncateText({
    text: label,
    maxWidth: width,
    fontSize,
  });

  return (
    <text
      x={x}
      y={y + height / 2}
      textAnchor="start"
      dy="0.25em"
      fontSize={`${fontSize}px`}
      className={styles.barLabel}
    >
      <title>{label}</title>
      {truncatedLabel}
    </text>
  );
};
