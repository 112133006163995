import { PostingsDataQuery } from '@revelio/data-access';
import { LineData, LineDatum } from '@revelio/replots';
import { mean, rollups } from 'd3-array';

export const getTimeToFillData = (
  data: PostingsDataQuery['posting']
): LineData[] => {
  if (!data) return [];

  const timeToFillData = data
    .map((entity): LineData | null => {
      if (!entity) return null;
      const metadata = entity.metadata;
      const category = entity.category;

      if (!metadata || !category) return null;

      const label = metadata.longName;
      if (!label) return null;

      return {
        label,
        values: category
          .map((c) => {
            if (!c || !c.metadata || !c.metrics) return null;
            const { metadata, metrics } = c;

            const date = metadata.shortName;
            if (!date) return null;

            return { date, value: metrics?.time_to_fill || null };
          })
          .filter((v) => v !== null),
      };
    })
    .filter((d) => d !== null);

  const dataGroupedByMonth: LineData[] = timeToFillData
    .map((entity) => ({
      ...entity,
      values: rollups(
        entity.values,
        (v) => mean(v.map((v) => v.value)),
        (v) => {
          const [year, month] = v.date.split('-');
          return `${year}-${month}`;
        }
      ),
    }))
    .map((entity) => ({
      ...entity,
      values: entity.values.map(
        ([date, value]): LineDatum => ({
          date,
          value: value || null,
        })
      ),
    }));

  return dataGroupedByMonth;
};
