import { PageTitles } from '@revelio/core';
import {
  ViewTypes,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SelectionCategories,
  RICS_AND_COMPANY_FILTERS,
} from '@revelio/filtering';
import { Sentiment } from '../../../sentiment/Sentiment';
import { Sentiment as SentimentV2 } from '../../../sentiment/sentimentV2/Sentiment';
import { useFlag } from '@unleash/proxy-client-react';
import { useLocation } from 'react-router-dom';

export function RoleSentiment() {
  const title = [PageTitles.ROLE, PageTitles.SENTIMENT];
  const { pathname } = useLocation();
  const isSentimentV2Enabled = useFlag('sentiment-v2');
  const shouldUseOldSentiment = pathname.includes('sentiment-reviews');

  const primaryViewFilters = [
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 1,
    },
  ];

  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    ...(!isSentimentV2Enabled || shouldUseOldSentiment
      ? [SelectionCategories.DATE_RANGE]
      : []),
  ];

  const SentimentComponent =
    shouldUseOldSentiment || !isSentimentV2Enabled ? Sentiment : SentimentV2;

  return (
    <SentimentComponent
      title={title}
      viewGroup={ViewTypes.ROLE}
      primaryViewFilters={primaryViewFilters}
      selectableFilters={selectableFilters}
    />
  );
}

export default RoleSentiment;
