import { StackedBarChartHorizontalData } from '@revelio/d3';
import { StackedBar } from '@revelio/replots';

export const getReplotsStackedBarData = ({
  data,
}: {
  data?: StackedBarChartHorizontalData[];
}): StackedBar[] => {
  if (!data?.[0]?.value) return [];

  return data.map((dataPoint) => {
    const segments = dataPoint.value.reduce(
      (acc, item) => {
        if (item.metadata?.shortName) {
          acc[item.metadata.shortName] = item.metadata.count || 0;
        }
        return acc;
      },
      {} as Record<string, number>
    );

    return {
      label: String(dataPoint.id) || '',
      segments,
    };
  });
};
