import classNames from 'classnames';
import { extent, scaleBand, scaleLinear } from 'd3';
import { useRef } from 'react';

import { useResizeObserver } from '@revelio/core';

import { CommonPlotProps, FormatType } from '../../types';
import { PRIMARY_COLOR, getFormatter } from '../../utilities';
import { AxisLabel } from '../axis/axis-label';
import { PlotLoadWrapper } from '../plot-loader/plot-loader';
import styles from './bar-chart.module.css';
import { BarLabel } from './bar-label';
import { BarTooltip } from './bar-tooltip';

const MAX_Y_AXIS_WIDTH = 70;
const MAX_Y_AXIS_WIDTH_SMALL = 50;
const BAR_LEFT_PADDING = 10;

const MAX_BAR_LABEL_SIZE = 40;
const MIN_BAR_LABEL_SIZE = 35;
const BAR_LABEL_PADDING = 4;

const DIMENSIONS_STANDARD = {
  fontSize: 11,
  paddingOuter: 0.4,
  paddingInner: 0.4,
};

const DIMENSIONS_SMALL = {
  fontSize: 10,
  paddingOuter: 0.4,
  paddingInner: 0.4,
};

export type BarData<TData extends object = object> = {
  label: string;
  value: number | null;
  color?: string;
  className?: string;
} & TData;

type AxisOptions = {
  min: number;
  max: number;
};

export type BarChartProps<TData extends object = object> = {
  className?: string;
  data: BarData<TData>[];
  showBarLabels?: boolean;
  xAxis?: AxisOptions;
  tooltipContent?: (data: BarData<TData>) => React.ReactNode;
  onBarHover?: (d: BarData<TData> | null) => void;
} & CommonPlotProps;

export const BarChart = <TData extends object = object>({
  className,
  data,
  showBarLabels = true,
  format = FormatType.SI,
  loading = false,
  xAxis,
  tooltipContent,
  onBarHover,
}: BarChartProps<TData>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { width, height } = useResizeObserver(containerRef);

  const isSmallPlot = width < 250;
  const dimensions = isSmallPlot ? DIMENSIONS_SMALL : DIMENSIONS_STANDARD;
  const { fontSize, paddingOuter, paddingInner } = dimensions;

  const yAxisWidth = Math.min(
    isSmallPlot ? MAX_Y_AXIS_WIDTH_SMALL : MAX_Y_AXIS_WIDTH,
    Math.floor((0.4 * width) / 10) * 10
  );
  const barLabelSize =
    Math.min(
      MAX_BAR_LABEL_SIZE,
      Math.max(MIN_BAR_LABEL_SIZE, Math.floor((0.15 * width) / 10) * 10)
    ) + BAR_LABEL_PADDING;
  const plotWidth =
    width -
    yAxisWidth -
    BAR_LEFT_PADDING -
    (showBarLabels ? barLabelSize + BAR_LABEL_PADDING : 0);
  const plotHeight = height;

  const isPlotSizeValid = plotWidth > 0 && plotHeight > 0;

  const yScale = scaleBand<string>(
    data.map((d) => d.label),
    [0, plotHeight]
  )
    .paddingOuter(paddingOuter)
    .paddingInner(paddingInner);

  const allValues = data.map((d) => d.value).filter((v) => v !== null);
  const everyValueNegative = allValues.every((v) => v <= 0);
  const [minValue, maxValue] = xAxis
    ? [xAxis.min, xAxis.max]
    : extent(allValues);

  const xScale = (() => {
    if (minValue === undefined || maxValue === undefined) return null;

    const zeroedMinValue = maxValue > 0 ? Math.min(minValue, 0) : minValue;
    const zeroedMaxValue = maxValue < 0 ? 0 : maxValue;

    return scaleLinear([zeroedMinValue, zeroedMaxValue], [0, plotWidth]);
  })();

  const formatValue = getFormatter(format, {
    values: allValues,
  });
  const tooltipFormatValue = getFormatter(
    format === FormatType.SI ? FormatType.INTEGER : format,
    { values: allValues, increasePrecision: 1 }
  );

  return (
    <div
      ref={containerRef}
      className={classNames(styles.container, className)}
      data-testid="bar-chart"
    >
      <PlotLoadWrapper loading={loading} noData={data.length === 0}>
        <svg width="100%" height="100%">
          <defs>
            <pattern
              id="diagonal-stripe-4"
              patternUnits="userSpaceOnUse"
              width="10"
              height="10"
            >
              <image
                xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPScjQ0JENUUwJy8+CiAgPHBhdGggZD0nTS0xLDEgbDIsLTIKICAgICAgICAgICBNMCwxMCBsMTAsLTEwCiAgICAgICAgICAgTTksMTEgbDIsLTInIHN0cm9rZT0nI2ZmZicgc3Ryb2tlLXdpZHRoPSczJy8+Cjwvc3ZnPg=="
                x="0"
                y="0"
                width="10"
                height="10"
              ></image>
            </pattern>
          </defs>
          <g id="y-axis">
            {isPlotSizeValid &&
              yScale.domain().map((domain) => {
                const y = yScale(domain);
                return (
                  y && (
                    <AxisLabel
                      key={domain}
                      y={y + yScale.bandwidth() / 2}
                      width={yAxisWidth}
                      fontSize={fontSize}
                      label={domain}
                    />
                  )
                );
              })}
          </g>
          <g
            id="chart"
            transform={`translate(${yAxisWidth + BAR_LEFT_PADDING}, 0)`}
          >
            {isPlotSizeValid &&
              xScale &&
              data.map((d, i) => {
                const { label, value, color } = d;
                const x0 = xScale(0);
                const x1 = value === null ? xScale.range()[1] : xScale(value);
                const x = x1 < x0 ? x1 : x0;
                const barWidth = x1 < x0 ? x0 - x1 : x1 - x0;

                const y = yScale(label);
                const maxBarLabelWidth = everyValueNegative
                  ? xScale.range()[1] - plotWidth + barLabelSize
                  : xScale.range()[1] - barWidth + barLabelSize;

                return (
                  barWidth >= 0 &&
                  maxBarLabelWidth >= 0 && (
                    <g key={`${label}_${i}`} id={'bar-group'}>
                      <BarTooltip
                        label={
                          tooltipContent ? (
                            tooltipContent(d)
                          ) : (
                            <div>
                              <div className={styles.barTooltipLabel}>
                                {label}
                              </div>
                              <div className={styles.barTooltipValue}>
                                {value === null
                                  ? '-'
                                  : tooltipFormatValue(value)}
                              </div>
                            </div>
                          )
                        }
                      >
                        <rect
                          x={x}
                          y={y}
                          width={barWidth}
                          height={yScale.bandwidth()}
                          rx={2}
                          fill={
                            value === null
                              ? `url(#diagonal-stripe-4)`
                              : color || PRIMARY_COLOR
                          }
                          onMouseEnter={() => {
                            onBarHover?.(d);
                          }}
                          onMouseLeave={() => {
                            onBarHover?.(null);
                          }}
                          className={classNames(styles.bar, d?.className)}
                          data-testid={`bar-chart__bar`}
                        />
                      </BarTooltip>
                      {showBarLabels && y && (
                        <BarLabel
                          x={Math.max(x1, x0) + BAR_LABEL_PADDING}
                          y={y}
                          width={maxBarLabelWidth}
                          height={yScale.bandwidth()}
                          fontSize={fontSize}
                          label={value === null ? '' : formatValue(value)}
                        />
                      )}
                    </g>
                  )
                );
              })}
          </g>
        </svg>
      </PlotLoadWrapper>
    </div>
  );
};
