import {
  AnyFilter,
  FilterMenuItemOrConfig,
  LocalSelectionCategories,
  NestedMenuItemConfig,
  RangeFilter,
  SelectableCategories,
  SelectionCategories,
} from '../../engine/filters.model';
import { get, has, isPlainObject, isString, isUndefined } from 'lodash';
import { GetFilterParams } from './types';
import { GraphFilterLabelLookup } from '../filter-menu/helpers.config';
import { UseToastOptions } from '@chakra-ui/react';

export const getMaxLimitToast = (limit: number): UseToastOptions => ({
  id: 'max-limit-toast',
  position: 'top-right',
  status: 'warning',
  title: `Active Entities Limit`,
  description: `Please deselect an entity before selecting another.`,
  isClosable: true,
  variant: 'subtle',
});

export const getMinLimitToast = (limit: number): UseToastOptions => ({
  id: 'min-limit-toast',
  position: 'top-right',
  status: 'error',
  title: ` Error`,
  description: `At least ${limit} entity must be active`,
  isClosable: true,
  variant: 'subtle',
});

/**
 * takes a list of filter where some may be in a subarray and others
 * might be in the form of a config object.
 *
 * @param filterItems - list of filter items to partition
 *
 * @returns a array where the first element is an array of all the unnested (single level)
 * filter names. The second is an array of all the nested (multi level) filter names without
 * additonal config info.The third has all of the nested (multi level) items including config
 * objects.
 *
 */
export const partitionFilterNames = (
  filterItems: FilterMenuItemOrConfig[]
): [
  SelectableCategories[],
  SelectableCategories[][],
  (SelectableCategories[] | NestedMenuItemConfig)[],
] => {
  const unnested: SelectableCategories[] = [];
  const nested: SelectableCategories[][] = [];
  const nestedConfigs: (SelectableCategories[] | NestedMenuItemConfig)[] = [];

  filterItems.forEach((item) => {
    if (Array.isArray(item)) {
      nested.push(item);
      nestedConfigs.push(item);
    } else if (isString(item)) {
      unnested.push(item);
    } else if (isPlainObject(item)) {
      if (!isUndefined(item.filters)) {
        nested.push(item.filters);
        nestedConfigs.push(item);
      }
    }
  });

  return [unnested, nested, nestedConfigs];
};

/**
 * Takes an array of nested filter names and an array of filter items.
 * Replaces each filter name with it's corresponding filter item if it
 * exists. Filter name is replaced with undefined otherwise.
 *
 * @param nestedFilters - 2D array of nested filter names
 * @param graphFilters - array of filter items
 *
 * @returns a 2D array of filter items.
 */
export const findAndReplacedGraphFilterItems = (
  nestedFilters: (SelectableCategories[] | NestedMenuItemConfig)[],
  graphFilters: AnyFilter[]
) => {
  const items = nestedFilters.map((item): (AnyFilter | undefined)[] => {
    let filtersArray: SelectableCategories[] = [];

    const propertyOverrides = {} as any;

    if (Array.isArray(item)) {
      filtersArray = item;
    } else if (isPlainObject(item)) {
      if (!isUndefined(item.filters)) {
        filtersArray = item.filters;
      }
    }

    return filtersArray.map((fil) => {
      const matchedFilter = graphFilters?.find(
        (filterItem) => filterItem.id === fil
      );

      if (matchedFilter) {
        return { ...matchedFilter, ...propertyOverrides };
      }

      return matchedFilter;
    });
  });

  return items;
};

/**
 * Takes an array of nested filter name tuples and an array of filter items.
 * Finds the corresponding filter items for each nested filter name in the tuple
 * and replaces it with a single filter item where the value field is an
 * amalgamation
 *
 * @param nestedFilters - array of filter name tuples
 * @param graphFilters - array of filter items
 *
 * @returns array of filter items with merged value fields
 */
export const mergeGraphFilterItems = (
  nestedFilters: (SelectableCategories[] | NestedMenuItemConfig)[],
  graphFilters: AnyFilter[]
) => {
  const mergedFilters: AnyFilter[] = [];

  const nestedGraphFilterItems = findAndReplacedGraphFilterItems(
    nestedFilters,
    graphFilters
  );

  nestedGraphFilterItems?.forEach((nestedItem, i) => {
    const filterBase = nestedItem.find((item: any) => !isUndefined(item));

    if (isUndefined(filterBase)) {
      return;
    }

    const isAggregateChip = get(nestedFilters[i], 'isAggregateChip', false);

    const combinedValues = nestedItem.reduce(
      (acc: any, cur: AnyFilter | undefined) => {
        const currentValues = cur?.value;

        if (isUndefined(currentValues) || isUndefined(cur)) {
          return acc;
        }

        const newValues = Array.isArray(currentValues)
          ? currentValues
          : [currentValues];

        if (isAggregateChip) {
          return { ...acc, [cur.id]: newValues };
        }

        return [...acc, ...newValues];
      },
      []
    );

    const mergedFilter = {
      ...filterBase,
      value: combinedValues,
      label: filterBase ? GraphFilterLabelLookup[filterBase.id] : '',
      linkedFilters: nestedFilters[i],
    };

    mergedFilters.push(mergedFilter as AnyFilter);
  });

  return mergedFilters;
};

/**
 * filters the active filters to keep only those that match the
 * item ids in the filterNames prop. The following special rules
 * are applied.
 *
 * Special Rules:
 *  1. Non-user submitted provider filters are removed.
 *  2. filter out the date range if maximum values set.
 *
 * @param filters - array of active filter items
 * @param filterNames - array of filter ids of the relevant filter items
 * @param rangeSelectState - currently selected date range state
 * @param filtersToIgnore - array of selection categories to not generate a chip for
 *
 * @returns array of filtered filter items
 */

export const getFilterItems = ({
  filters,
  filterNames,
  filtersToIgnore,
}: GetFilterParams) => {
  // get all active filter items
  // exclude:
  // 1.provider if the filter was not user submitted (i.e. not default)
  // 2.if item id is a selection category in the array given by filtersToIgnore
  // 3. filter out the date range if maximum values set.
  const filtered = filters?.filter((item) => {
    if (filtersToIgnore?.includes(item.id as SelectionCategories)) {
      return false;
    }

    if (
      [
        SelectionCategories.DATE_RANGE,
        SelectionCategories.DATE_RANGE_FULL,
      ].includes(item.id as SelectionCategories)
    ) {
      return !(item as RangeFilter).isMaximumRange;
    }

    return [
      LocalSelectionCategories.PROVIDER,
      LocalSelectionCategories.METRIC_MODE,
      LocalSelectionCategories.DATA_METRIC,
    ].includes(item.id as LocalSelectionCategories)
      ? has(item, 'isUserSubmitted') && item.isUserSubmitted
      : filterNames?.includes(item.id as SelectionCategories);
  });

  return filtered;
};

/**
 * filters the active filters to keep only those that match the
 * item ids in the filterNames prop.
 *
 * @param filters - array of active filter items
 * @param filterNames - array of filter ids of the relevant filter items
 *
 * @returns array of filtered filter items
 */
export const getScreenerFilterItems = ({
  filters,
  filterNames,
}: GetFilterParams) => {
  const filtered = filters?.filter((item) =>
    filterNames?.includes(item.id as SelectionCategories)
  );

  return filtered;
};

export const getSortableId = (ent: any) => {
  return `${ent.selectionListId}_${ent.id}`;
};
