import {
  AISearchFilter,
  LocationMappingItem,
  TalentDiscoveryAiFilterSearchResponse,
  RoleMappingItem,
} from '@revelio/data-access';
import {
  TreeItem,
  findSelectionListItemByLabel,
  SelectionList,
  SelectionCategories,
  findSelectionListItemByItemId,
  ValidValueTypes,
} from '@revelio/filtering';
import { TDResponseFilterToLabel } from './ai-filter-response-mappings';
import { getUnknownFilterExplanation } from './get-unknown-filter-explanation';
import { locationMapperApi, roleMapperApi } from './mapping-apis';

interface ProcessFilterGroupArgs {
  group: AISearchFilter[];
  relevantSelectionLists: SelectionList[];
  treeFilterKey: keyof TalentDiscoveryAiFilterSearchResponse['filters'];
}

interface SkipSelectionListConfig {
  selectionListId: SelectionCategories | string;
}

const findMatchingTreeItems = (
  aiFilterSearchFilterLabel: AISearchFilter,
  relevantSelectionLists: SelectionList[],
  skipSelectionList?: SkipSelectionListConfig
) => {
  return relevantSelectionLists
    .map((selectionList) => {
      if (skipSelectionList?.selectionListId === selectionList.id) {
        return null;
      }

      return findSelectionListItemByLabel({
        labelToFind: aiFilterSearchFilterLabel.name,
        selectionList: selectionList,
      });
    })
    .filter((item): item is NonNullable<typeof item> => item !== null);
};

const getClosestTreeItem = (
  matchedTreeItems: ReturnType<typeof findMatchingTreeItems>
) => {
  return matchedTreeItems.sort(
    (a, b) =>
      //lower closeness score is better, defaulting to 100 so values with missing scores are not picked
      (a.closeness_score || 100) - (b.closeness_score || 100)
  )[0];
};

export const processFilterGroup = ({
  group,
  relevantSelectionLists,
  treeFilterKey,
  skipSelectionList,
}: ProcessFilterGroupArgs & {
  skipSelectionList?: SkipSelectionListConfig;
}): {
  processedFilters: Record<string, TreeItem>;
  unknownFilters: TalentDiscoveryAiFilterSearchResponse['unknownFilters'];
} => {
  const unknownFilters: TalentDiscoveryAiFilterSearchResponse['unknownFilters'] =
    [];
  const processedFilters = group?.reduce((acc, aiFilterSearchFilterLabel) => {
    const matchedTreeItems = findMatchingTreeItems(
      aiFilterSearchFilterLabel,
      relevantSelectionLists,
      skipSelectionList
    );

    if (matchedTreeItems.length > 0) {
      const closestTreeItem = getClosestTreeItem(matchedTreeItems);
      return {
        ...acc,
        [closestTreeItem.id]: closestTreeItem,
      };
    }

    unknownFilters.push({
      relevantPromptText: aiFilterSearchFilterLabel.relevantPromptText,
      explanation: getUnknownFilterExplanation(
        aiFilterSearchFilterLabel.name,
        TDResponseFilterToLabel[treeFilterKey]
      ),
      name: aiFilterSearchFilterLabel.name,
    });

    return acc;
  }, {});

  return { processedFilters, unknownFilters };
};

interface ProcessFilterGroupWithMapperArgs
  extends Omit<ProcessFilterGroupArgs, 'treeFilterKey'> {
  treeFilterKey: SupportedTreeFilterKey;
}
export const processFilterGroupWithMapperApiFallback = async (
  params: ProcessFilterGroupWithMapperArgs
) => {
  const { processedFilters, unknownFilters } = processFilterGroup({
    ...params,
    skipSelectionList: { selectionListId: SelectionCategories.METRO_AREA },
  });

  const fallbackMapperFiltersFromUnknown = unknownFilters.map(
    (unknownFilter) => unknownFilter.name
  );

  if (fallbackMapperFiltersFromUnknown.length === 0) {
    return { processedFilters, unknownFilters };
  }

  await handleMapperApiFallback({
    treeFilterKey: params.treeFilterKey as SupportedTreeFilterKey,
    fallbackMapperFiltersFromUnknown,
    relevantSelectionLists: params.relevantSelectionLists,
    unknownFilters,
    processedFilters,
  });

  return { processedFilters, unknownFilters };
};

type SupportedTreeFilterKey = 'geography' | 'role';

interface MapperConfig<T extends LocationMappingItem | RoleMappingItem> {
  categoryId: SelectionCategories;
  getMapperId: (item: T) => string;
  getOriginalName: (item: T) => string;
}

const MAPPER_CONFIGS: {
  geography: MapperConfig<LocationMappingItem>;
  role: MapperConfig<RoleMappingItem>;
} = {
  geography: {
    categoryId: SelectionCategories.METRO_AREA,
    getMapperId: (item: LocationMappingItem) => item.metro_area_id,
    getOriginalName: (item: LocationMappingItem) => item.location,
  },
  role: {
    categoryId: SelectionCategories.ROLE_K1500,
    getMapperId: (item: RoleMappingItem) => item.mapped_role_id,
    getOriginalName: (item: RoleMappingItem) => item.title,
  },
};

const processMapperResponse = <T extends LocationMappingItem | RoleMappingItem>(
  mapperItems: T[],
  config: MapperConfig<T>,
  relevantSelectionLists: SelectionList[],
  unknownFilters: TalentDiscoveryAiFilterSearchResponse['unknownFilters'],
  processedFilters: Record<string, TreeItem>
) => {
  mapperItems.forEach((mappingItem) => {
    const selectionListItem = findSelectionListItemByItemId({
      itemId: `${config.categoryId}.${config.getMapperId(mappingItem)}`,
      selectionLists: [
        relevantSelectionLists.find(
          (selectionList) => selectionList.id === config.categoryId
        ) as SelectionList<ValidValueTypes>,
      ],
    });

    if (selectionListItem) {
      const unknownFilterIndex = unknownFilters.findIndex(
        (filter) => filter.name === config.getOriginalName(mappingItem)
      );
      if (unknownFilterIndex !== -1) {
        unknownFilters.splice(unknownFilterIndex, 1);
      }

      if (selectionListItem?.id) {
        processedFilters[selectionListItem.id] = selectionListItem;
      }
    }
  });
};

const handleMapperApiFallback = async ({
  treeFilterKey,
  fallbackMapperFiltersFromUnknown,
  relevantSelectionLists,
  unknownFilters,
  processedFilters,
}: {
  treeFilterKey: SupportedTreeFilterKey;
  fallbackMapperFiltersFromUnknown: string[];
  relevantSelectionLists: SelectionList[];
  unknownFilters: TalentDiscoveryAiFilterSearchResponse['unknownFilters'];
  processedFilters: Record<string, TreeItem>;
}) => {
  const config = MAPPER_CONFIGS[treeFilterKey];
  if (!config) return;

  if (treeFilterKey === 'geography') {
    const apiResponse = await locationMapperApi({
      locationsToMap: fallbackMapperFiltersFromUnknown,
    });
    processMapperResponse<LocationMappingItem>(
      Object.values(apiResponse),
      config as MapperConfig<LocationMappingItem>,
      relevantSelectionLists,
      unknownFilters,
      processedFilters
    );
  } else if (treeFilterKey === 'role') {
    const apiResponse = await roleMapperApi({
      rolesToMap: fallbackMapperFiltersFromUnknown,
    });
    processMapperResponse<RoleMappingItem>(
      Object.values(apiResponse),
      config as MapperConfig<RoleMappingItem>,
      relevantSelectionLists,
      unknownFilters,
      processedFilters
    );
  }
};
