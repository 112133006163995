import { SentimentScoreKeys } from '../types';

export type Topic = { id: number; value: SentimentScoreKeys; label: string };

export const defaultTopics: Topic[] = [
  { id: 1, value: 'overall', label: 'Overall' },
  { id: 2, value: 'business_outlook', label: 'Business Outlook' },
  { id: 3, value: 'career_growth', label: 'Career Growth' },
  { id: 4, value: 'compensation', label: 'Compensation' },
  { id: 5, value: 'benefits', label: 'Benefits' },
  { id: 14, value: 'culture', label: 'Culture' },
  { id: 11, value: 'work_life_balance', label: 'Work-Life Balance' },
  { id: 12, value: 'diversity_and_inclusion', label: 'Diversity & Inclusion' },
  { id: 32, value: 'job_security', label: 'Job Security' },
];

export const topics: Topic[] = [
  { id: 1, value: 'overall', label: 'Overall' },
  { id: 2, value: 'business_outlook', label: 'Business Outlook' },
  { id: 3, value: 'career_growth', label: 'Career Growth' },
  { id: 4, value: 'compensation', label: 'Compensation' },
  { id: 5, value: 'benefits', label: 'Benefits' },
  { id: 6, value: 'healthcare', label: 'Healthcare' },
  { id: 7, value: 'retirement_pension', label: 'Retirement & Pension' },
  { id: 8, value: 'childcare', label: 'Childcare' },
  { id: 9, value: 'education_assistance', label: 'Education Assistance' },
  { id: 10, value: 'wellness_programs', label: 'Wellness Programs' },
  { id: 11, value: 'work_life_balance', label: 'Work-Life Balance' },
  { id: 12, value: 'diversity_and_inclusion', label: 'Diversity & Inclusion' },
  { id: 13, value: 'leadership', label: 'Leadership' },
  { id: 14, value: 'culture', label: 'Culture' },
  { id: 15, value: 'remote_work', label: 'Remote Work' },
  { id: 16, value: 'onboarding', label: 'Onboarding' },
  { id: 17, value: 'mentorship', label: 'Mentorship' },
  { id: 18, value: 'technology', label: 'Technology' },
  {
    id: 19,
    value: 'promotion_opportunities',
    label: 'Promotion Opportunities',
  },
  { id: 20, value: 'paid_time_off', label: 'Paid Time Off' },
  { id: 21, value: 'transparency', label: 'Transparency' },
  {
    id: 22,
    value: 'efficiency_and_bureaucracy',
    label: 'Efficiency & Bureaucracy',
  },
  {
    id: 23,
    value: 'product_and_service_quality',
    label: 'Product & Service Quality',
  },
  { id: 24, value: 'prestige', label: 'Prestige' },
  { id: 25, value: 'coworkers', label: 'Coworkers' },
  { id: 26, value: 'mission_and_vision', label: 'Mission & Vision' },
  { id: 27, value: 'workplace_facilities', label: 'Workplace Facilities' },
  { id: 28, value: 'training', label: 'Training' },
  { id: 29, value: 'clients_and_customers', label: 'Clients & Customers' },
  { id: 30, value: 'company_growth', label: 'Company Growth' },
  { id: 31, value: 'schedule_flexibility', label: 'Schedule Flexibility' },
  { id: 32, value: 'job_security', label: 'Job Security' },
  { id: 33, value: 'overtime', label: 'Overtime' },
  { id: 34, value: 'family_support', label: 'Family Support' },
  { id: 35, value: 'commission', label: 'Commission' },
  { id: 36, value: 'independence', label: 'Independence' },
  { id: 37, value: 'commute', label: 'Commute' },
  { id: 38, value: 'safety', label: 'Safety' },
  { id: 39, value: 'internal_communication', label: 'Internal Communication' },
  { id: 40, value: 'office_politics', label: 'Office Politics' },
  { id: 41, value: 'harassment', label: 'Harassment' },
  { id: 42, value: 'honesty', label: 'Honesty' },
  { id: 43, value: 'ethics', label: 'Ethics' },
  { id: 44, value: 'commitment', label: 'Commitment' },
  { id: 45, value: 'compliance', label: 'Compliance' },
  { id: 46, value: 'integrity', label: 'Integrity' },
];

export type GroupedOption = {
  label: string;
  options: Topic[];
};

export const groupedTopics: GroupedOption[] = [
  {
    label: 'Overall',
    options: [{ id: 1, value: 'overall', label: 'Overall' }],
  },
  {
    label: 'Compensation & Benefits',
    options: [
      { id: 4, value: 'compensation', label: 'Compensation' },
      { id: 5, value: 'benefits', label: 'Benefits' },
      { id: 6, value: 'healthcare', label: 'Healthcare' },
      { id: 7, value: 'retirement_pension', label: 'Retirement & Pension' },
      { id: 8, value: 'childcare', label: 'Childcare' },
      { id: 9, value: 'education_assistance', label: 'Education Assistance' },
      { id: 10, value: 'wellness_programs', label: 'Wellness Programs' },
      { id: 35, value: 'commission', label: 'Commission' },
    ],
  },
  {
    label: 'Career Growth & Job Security',
    options: [
      { id: 3, value: 'career_growth', label: 'Career Growth' },
      { id: 16, value: 'onboarding', label: 'Onboarding' },
      { id: 17, value: 'mentorship', label: 'Mentorship' },
      {
        id: 19,
        value: 'promotion_opportunities',
        label: 'Promotion Opportunities',
      },
      { id: 28, value: 'training', label: 'Training' },
      { id: 32, value: 'job_security', label: 'Job Security' },
      { id: 24, value: 'prestige', label: 'Prestige' },
    ],
  },
  {
    label: 'Business & Outlook',
    options: [
      { id: 2, value: 'business_outlook', label: 'Business Outlook' },
      {
        id: 23,
        value: 'product_and_service_quality',
        label: 'Product & Service Quality',
      },
      { id: 26, value: 'mission_and_vision', label: 'Mission & Vision' },
      { id: 29, value: 'clients_and_customers', label: 'Clients & Customers' },
      { id: 30, value: 'company_growth', label: 'Company Growth' },
    ],
  },
  {
    label: 'Environment & Culture',
    options: [
      { id: 14, value: 'culture', label: 'Culture' },
      { id: 25, value: 'coworkers', label: 'Coworkers' },
      { id: 27, value: 'workplace_facilities', label: 'Workplace Facilities' },
      { id: 38, value: 'safety', label: 'Safety' },
      { id: 40, value: 'office_politics', label: 'Office Politics' },
      { id: 41, value: 'harassment', label: 'Harassment' },
      { id: 13, value: 'leadership', label: 'Leadership' },
      { id: 36, value: 'independence', label: 'Independence' },
    ],
  },
  {
    label: 'Ethics & Values',
    options: [
      {
        id: 12,
        value: 'diversity_and_inclusion',
        label: 'Diversity & Inclusion',
      },
      { id: 42, value: 'honesty', label: 'Honesty' },
      { id: 43, value: 'ethics', label: 'Ethics' },
      { id: 44, value: 'commitment', label: 'Commitment' },
      { id: 46, value: 'integrity', label: 'Integrity' },
    ],
  },
  {
    label: 'Flexibility & Logistics',
    options: [
      { id: 11, value: 'work_life_balance', label: 'Work-Life Balance' },
      { id: 15, value: 'remote_work', label: 'Remote Work' },
      { id: 20, value: 'paid_time_off', label: 'Paid Time Off' },
      { id: 31, value: 'schedule_flexibility', label: 'Schedule Flexibility' },
      { id: 37, value: 'commute', label: 'Commute' },
      { id: 33, value: 'overtime', label: 'Overtime' },
      { id: 34, value: 'family_support', label: 'Family Support' },
    ],
  },
  {
    label: 'Operations & Efficiency',
    options: [
      { id: 18, value: 'technology', label: 'Technology' },
      { id: 21, value: 'transparency', label: 'Transparency' },
      {
        id: 22,
        value: 'efficiency_and_bureaucracy',
        label: 'Efficiency & Bureaucracy',
      },
      {
        id: 39,
        value: 'internal_communication',
        label: 'Internal Communication',
      },
      { id: 45, value: 'compliance', label: 'Compliance' },
    ],
  },
];
